import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { toast } from "react-toastify";
import * as actions from "../components/Actions/uploadedFilesReportActions";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UploadedFilesReportsState {
  isLoading: boolean;
  responseMessage: string;
  regionsList: any;
  depotsList: any;
  generatedReportsList: any;
  selectedDepotsList: any;
  selectedRegion: any;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface ReceiveRegionsAndSalesReports {
  type: "RECEIVE_REGIONS_AND_SALES_REPORTS";
  regionsList: any;
  generatedReportsList: any;
}

interface ReceiveDepots {
  type: "RECEIVE_DEPOTS";
  depotsList: any;
  selectedRegion: any;
}

interface GenerateSalesReportForDepots {
  type: "GENERATE_SALES_REPORT_FOR_DEPOTS";
  generatedReportsList: any;
  selectedDepotsList: any;
}

interface DownloadReceivedSalesFilesReport {
  type: "DOWNLOAD_RECEIVED_SALES_FILE_REPORT";
}

interface SetSelectedDepotsList {
  type: "SET_SELECTED_DEPOTS";
  selectedDepotsList: any;
}

interface ClearValues {
  type: "CLEAR_VALUES";
}

interface AttemptAction {
  type: "ATTEMPT_ACTION";
}

interface FinishAction {
  type: "FINISH_ACTION";
}

interface ErrorAction {
  type: "ERROR_ACTION";
  responseMessage: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
  | ReceiveRegionsAndSalesReports
  | ReceiveDepots
  | GenerateSalesReportForDepots
  | DownloadReceivedSalesFilesReport
  | SetSelectedDepotsList
  | ClearValues
  | AttemptAction
  | FinishAction
  | ErrorAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  getRegionsAndSalesReports:
    (): AppThunkAction<KnownAction> => (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState) {
        let callback = (data) => {
            dispatch({
              type: "RECEIVE_REGIONS_AND_SALES_REPORTS",
              regionsList: data.regionsList,
              generatedReportsList: data.generatedReportsList,
            });
          },
          error = (error) => {
            dispatch({ type: "ERROR_ACTION", responseMessage: error });
            toast.error(error || "Error");
          };
        actions.getRegionsAndSalesReports(callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  setRegion:
    (selectedRegion): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState && selectedRegion) {
        let callback = (depotsList) => {
            dispatch({
              type: "RECEIVE_DEPOTS",
              selectedRegion: selectedRegion,
              depotsList: depotsList,
            });
          },
          error = (error) => {
            dispatch({
              type: "ERROR_ACTION",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response.statusText : "Error");
          };
        actions.getDepotsForRegion(selectedRegion, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  setSelectedDepots:
    (selectedDepotsList): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState && selectedDepotsList) {
        dispatch({
          type: "SET_SELECTED_DEPOTS",
          selectedDepotsList: selectedDepotsList,
        });
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  generateSalesReportForDepots:
    (depotData): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState && depotData) {
        let callback = (generatedReportsList) => {
            dispatch({
              type: "GENERATE_SALES_REPORT_FOR_DEPOTS",
              generatedReportsList: generatedReportsList,
              selectedDepotsList: depotData.depotIds,
            });
          },
          error = (error) => {
            dispatch({
              type: "ERROR_ACTION",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response?.statusText : "Error");
          };
        actions.generateSalesReportForDepots(depotData, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  downloadReceivedSalesFileReport:
    (receivedSalesReportId): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState && receivedSalesReportId) {
        let callback = () => {
            dispatch({ type: "DOWNLOAD_RECEIVED_SALES_FILE_REPORT" });
          },
          error = (error) => {
            dispatch({
              type: "ERROR_ACTION",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response.statusText : "Error");
          };
        actions.downloadReceivedSalesFileReport(
          receivedSalesReportId,
          callback,
          error,
        );
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  clearValues: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    dispatch({ type: "ATTEMPT_ACTION" });
    const appState = getState();
    if (appState) {
      dispatch({ type: "CLEAR_VALUES" });
    } else {
      dispatch({ type: "FINISH_ACTION" });
    }
  },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: UploadedFilesReportsState = {
  isLoading: false,
  responseMessage: "",
  regionsList: [],
  depotsList: [],
  generatedReportsList: [],
  selectedDepotsList: [],
  selectedRegion: "-1",
};

export const reducer: Reducer<UploadedFilesReportsState> = (
  state: UploadedFilesReportsState | undefined,
  incomingAction: Action,
): UploadedFilesReportsState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "ATTEMPT_ACTION":
      return {
        ...state,
        isLoading: true,
        responseMessage: "",
      };
    case "FINISH_ACTION":
      return {
        ...state,
        isLoading: false,
      };
    case "RECEIVE_REGIONS_AND_SALES_REPORTS":
      return {
        ...state,
        regionsList: action.regionsList,
        generatedReportsList: action.generatedReportsList,
        isLoading: false,
      };
    case "RECEIVE_DEPOTS":
      return {
        ...state,
        selectedRegion: action.selectedRegion,
        depotsList: action.depotsList,
        isLoading: false,
      };
    case "SET_SELECTED_DEPOTS":
      return {
        ...state,
        selectedDepotsList: action.selectedDepotsList,
        isLoading: false,
      };
    case "GENERATE_SALES_REPORT_FOR_DEPOTS":
      return {
        ...state,
        generatedReportsList: action.generatedReportsList,
        selectedDepotsList: action.selectedDepotsList,
        isLoading: false,
      };
    case "DOWNLOAD_RECEIVED_SALES_FILE_REPORT":
      return {
        ...state,
        isLoading: false,
      };
    case "CLEAR_VALUES":
      return {
        ...state,
        responseMessage: "",
        regionsList: [],
        depotsList: [],
        generatedReportsList: [],
        selectedDepotsList: [],
        selectedRegion: "-1",
        isLoading: false,
      };
    case "ERROR_ACTION":
      return {
        ...state,
        responseMessage: action.responseMessage,
        isLoading: false,
      };
  }
  return state;
};
