import { createGlobalStyle } from "styled-components";
import resetCSS from "styled-reset";
import { setConfiguration } from "react-grid-system";
import "react-toastify/dist/ReactToastify.css";

const breakpoints = {
  sm: 768,
  md: 1024,
  lg: 1440,
  xl: 1920,
};

const mediaQueries = {
  // XS
  xsOnly: `(max-width: ${breakpoints.sm - 1}px)`,

  // SM
  smOnly: `(min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.md - 1}px)`,
  smDown: `(max-width: ${breakpoints.md - 1}px)`,
  smUp: `(min-width: ${breakpoints.sm}px)`,

  // MD
  mdOnly: `(min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg - 1}px)`,
  mdDown: `(max-width: ${breakpoints.lg - 1}px)`,
  mdUp: `(min-width: ${breakpoints.md}px)`,

  // LG
  lgOnly: `(min-width: ${breakpoints.lg}px) and (max-width: ${breakpoints.xl - 1}px)`,
  lgDown: `(max-width: ${breakpoints.xl - 1}px)`,
  lgUp: `(min-width: ${breakpoints.lg}px)`,

  // XL
  xlUp: `(min-width: ${breakpoints.xl}px)`,
};

export const theme = {
  colors: {
    default: "#c2aa73",
    dark: "#6d6d6d",
    light: "#fff",
    semiLight: "#f9f9f9",
    border: "#ebedf2",
    danger: "#e80606",
    primary: "#5d78ff",
    secondary: "#cb2f36",
  },
  transition: { default: "0.3s ease-in-out" },
  fonts: { default: "'Montserrat', Arial, sans-serif" },
  borderRadius: "30px",
  mediaQueries,
};

//Set grid custom breakpoints
setConfiguration({
  breakpoints: Object.keys(breakpoints).map((key) => breakpoints[key]),
  containerWidths: ["100%"],
  gutterWidth: 20,
});

export default createGlobalStyle`
  ${resetCSS}

  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
  }

  a:hover,
  a:focus {
      text-decoration: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active
  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  -webkit-tap-highlight-color: red !important;
  caret-color: red;

  body {
    font-family: ${({ theme }) => theme.fonts.default};
    background: ${({ theme }) => theme.colors.semiLight};
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
  }

  .Toastify__toast-container--top-right {
    top: 50px;
    right: 15px;

    ${({ theme }) => `
      @media ${theme.mediaQueries.xsOnly} {
        top: auto;
        bottom: 20px;
        left: 20px;
        width: calc(100% - 40px);
      }
    `}
  }

  .Toastify__toast--success {
    background: ${({ theme }) => theme.colors.default};
  }

  .Toastify__toast--error {
    background: ${({ theme }) => theme.colors.danger};
  }

  .Toastify__progress-bar {
    background: #f5f5f5;
  }

  .Toastify__toast-body {
    padding: 0 10px;
    font-size: 15px;
  }

  .Toastify__toast {
    border-radius: 6px;
  }

  .Toastify__close-button {
    opacity: 1;
  }

  .popup-overlay {
    margin: 0 !important;
  }

  th.sortable {
    position: sticky !important;
    top: 0px !important;
  }

  .popup-content {
    width: auto !important;
    padding: 0 !important;
    border: 0 !important;
    border-radius: 3px;
  }

  &.selected-tr {
      background-color: ${({ theme }) => theme.colors.primary} !important;

      td {
        color: #fff !important;
      }
  }

   div.text-red {
      color: red;
   }

   .custom-height-select {
       height: 240px !important;

       option {
          border-bottom: 1px solid #f3f3f3;
          font-size: 11px;
          padding: 8px 15px;
       }
    }

    .raw-data-report-green {
       background-color: green !important;
       height: 100% !important;
       width: 100% !important;
    }

    .raw-data-report-red {
       background-color: red !important;
       height: 100% !important;
       width: 100% !important;
    }

    .raw-data-report-depot {
       height: 30px !important;
       display: block;
    }

input[type="date"] {
    position: relative;
    width: 150px; height: 20px;
    color: white;
}

input[type="date"]:before {
    position: absolute;
    top: 3px; left: 3px;
    content: attr(data-date);
    display: inline-block;
    color: black;
}

input[type="date"]::-webkit-datetime-edit, input[type="date"]::-webkit-inner-spin-button, input[type="date"]::-webkit-clear-button {
    display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 3px;
    right: 0;
    color: black;
    opacity: 1;
}

thead th {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background: white;
    z-index: 10;
}

.whs-data-report td {
				height: 30px !important;
				width: 30px !important;
				padding: 0 !important;
				border: 1px solid #ebedf2 !important;
overflow: hidden !important;
}

.tr-constant-color td{
			color: #6d6d6d !important;
}

.margin-right-10 {
    margin-right: 10px;
}

.depotLine {
display: flex;
}

.depots-container {
font-size: 14px;
    color: #6d6d6d;
    display: inline-block;
    margin-bottom: 8px;
}

.depots-container label {
display:block;
margin-left:8px
}

.depots-container li label {
cursor: pointer !important;
}

.depots-container li {
cursor: pointer !important;
}

.depots-container button {
margin-right: 3px;
margin-bottom: 8px;
cursor: pointer !important;
}

.depots-container .active {
      background-color: #666 !important;
      color: white;
}

`;
