import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { toast } from 'react-toastify'
import * as actions from '../components/Actions/skuActions';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SKUState
{
    isLoading: boolean;
    createdSkuMapping: any;
    distributorProducts: any;
    kamenitzaProducts: any;
    skuMappings: any;
    responseMessage: string;
    mappingHistory: any;
    unmappedItems: any;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface GetSKUMappingsAction {
    type: 'GET_SKU_MAPPINGS_ACTION';
    skuMappings: any;
}

interface GetDistributorProductsAction
{
    type: 'GET_DISTRIBUTOR_PRODUCTS_ACTION';
    distributorProducts: any;
}

interface GetUnmappedItemsAction
{
    type: 'GET_UNMAPPED_ITEMS';
    unmappedItems: any;
}

interface GetKamenitzaProductsAction {
    type: 'GET_KAMENITZA_PRODUCTS_ACTION';
    kamenitzaProducts: any;
}

interface GetProductMappingHistoryAction {
    type: 'GET_PRODUCT_MAPPING_HISTORY_ACTION';
    mappingHistory: any;
}

interface CreateSKUMappingAction
{
    type: 'CREATE_SKU_MAPPING_ACTION';
    createdSkuMapping: any;
    responseMessage: string;
}

interface SetDistributorData
{
    type: 'SET_DISTRIBUTOR_DATA';
    distributorProducts: any;
}

interface AttemptAction
{
    type: 'ATTEMPT_ACTION';
}

interface FinishAction {
    type: 'FINISH_ACTION';
}

interface ErrorAction {
    type: 'ERROR_ACTION';
    responseMessage: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = GetSKUMappingsAction | GetDistributorProductsAction
    | GetKamenitzaProductsAction | CreateSKUMappingAction | AttemptAction | FinishAction | ErrorAction | SetDistributorData
    | GetProductMappingHistoryAction | GetUnmappedItemsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    getUnmappedItems: (cb?): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState) {
            let callback = (unmappedItems) => { dispatch({ type: 'GET_UNMAPPED_ITEMS', unmappedItems: unmappedItems }); cb && cb(unmappedItems); },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
                    toast.error(error.response ? error.response.statusText : 'Error')
                };
            actions.getCountUnmappedItems(callback, error);
        }
    },
    getDistributorProducts: (cb): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState) {
            let callback = (distributorProducts) => { dispatch({ type: 'GET_DISTRIBUTOR_PRODUCTS_ACTION', distributorProducts: distributorProducts }); cb(distributorProducts); },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
                    toast.error(error.response ? error.response.statusText : 'Error')
                };
            actions.getProducts('distributors', callback, error);
        }
    },
    getKamenitzaProducts: (cb): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState) {
            let callback = (kamenitzaProducts) => { dispatch({ type: 'GET_KAMENITZA_PRODUCTS_ACTION', kamenitzaProducts: kamenitzaProducts }); cb(kamenitzaProducts); },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
                    toast.error(error.response ? error.response.statusText : 'Error')
                };
            actions.getProducts('hermes', callback, error);
        }
    },
    getSKUMappings: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        //TODO URL
        const method = 'get';
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState) {
            let callback = (skuMappings) => { dispatch({ type: 'GET_SKU_MAPPINGS_ACTION', skuMappings: skuMappings }) },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
                    toast.error(error.response ? error.response.statusText : 'Error')
                };
            actions.skuAction(method, callback, error);
        }
    },
    getProductMappingHistory: (productId, cb): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState && productId && cb) {
            let callback = (mappingHistory) => { dispatch({ type: 'GET_PRODUCT_MAPPING_HISTORY_ACTION', mappingHistory: mappingHistory }); cb(mappingHistory);  },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
                };
            actions.getProductMappingHistory(productId, callback, error);
        }
    },
    //TODO update?
    skuMappingAction: (skuMapping, action, cb): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        var method = '';
        switch (action) {
            case "create":
                method = "post";
                break;
            case "update":
                method = "put";
                break;
            case "delete":
                method = "delete";
                break;
        }
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState && skuMapping) {
            let callback = (skuMapping) => {
                dispatch({ type: 'CREATE_SKU_MAPPING_ACTION', createdSkuMapping: skuMapping, responseMessage: 'Success' }); if (cb) { cb(skuMapping.id); }
                toast.success('Success')
                // @ts-ignore
                dispatch(actionCreators.getUnmappedItems());
            },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
                    toast.error(error.response ? error.response.statusText : 'Error')
                };
            actions.skuAction(method, callback, error, skuMapping);
        }
    },
    setDistributorData: (distributorProducts: any[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        dispatch({ type: 'SET_DISTRIBUTOR_DATA', distributorProducts: distributorProducts });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: SKUState = {
    isLoading: false,
    createdSkuMapping: {},
    distributorProducts: [],
    kamenitzaProducts: [],
    mappingHistory: [],
    skuMappings: [],
    responseMessage: '',
    unmappedItems: '',
};

export const reducer: Reducer<SKUState> = (state: SKUState | undefined, incomingAction: Action): SKUState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'GET_UNMAPPED_ITEMS':
            return {
                ...state,
                unmappedItems: action.unmappedItems,
                isLoading: false,
            };
            break;
        case 'ATTEMPT_ACTION':
            return {
                ...state,
                isLoading: true,
                responseMessage: ''
            };
            break;
        case 'FINISH_ACTION':
            return {
                ...state,
                isLoading: false
            };
            break;
        case 'GET_SKU_MAPPINGS_ACTION':
            return {
                ...state,
                skuMappings: action.skuMappings,
                isLoading: false
            };
            break;
        case 'GET_DISTRIBUTOR_PRODUCTS_ACTION':
                return {
                    ...state,
                    distributorProducts: action.distributorProducts,
                    isLoading: false
                };
            break;
        case 'GET_PRODUCT_MAPPING_HISTORY_ACTION':
            return {
                ...state,
                mappingHistory: action.mappingHistory,
                isLoading: false
            };
            break;
        case 'GET_KAMENITZA_PRODUCTS_ACTION':
            return {
                ...state,
                kamenitzaProducts: action.kamenitzaProducts,
                isLoading: false
            };
            break;
        case 'CREATE_SKU_MAPPING_ACTION':
            return {
                ...state,
                createdSkuMapping: action.createdSkuMapping,
                responseMessage: action.responseMessage,
                isLoading: false
            };
            break;
        case 'SET_DISTRIBUTOR_DATA':
            return {
                ...state,
                distributorProducts: action.distributorProducts
            };
            break;
        case 'ERROR_ACTION':
            return {
                ...state,
                responseMessage: action.responseMessage,
                isLoading: false
            }
    }

    return state;
};
