import * as React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "store";
import * as ReceivedSalesFilesStore from "../../store/ReceivedSalesFilesReducer";
import { useHistory } from "react-router-dom";
import regions from "../../regions.json";
import $ from "jquery";
import Select from "../Select";
import Button from "../Button";
import Cookies from "universal-cookie";
import DataTable from "../DataTable";
import i18next from "i18next";
import * as paginationEndpoints from "../constants/paginationEndpoints/paginationEndpoints";
import * as actions from "components/Utils/actions";
import { useEffect } from "react";

const cookies = new Cookies();
const TableHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * {
    margin: 0 8px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

TableHead.Field = styled.div`
  width: ${({ size }) => (size === "large" ? "200px" : "120px")};
`;

TableHead.Button = styled.div`
  position: relative;
  padding-top: 20px;
`;
const ReceivedSalesFiles: React.FC = () => {
  const receivedSalesFilesState = useSelector(
    (state: ApplicationState) => state.receivedSalesFiles,
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const actionCreatorStore = ReceivedSalesFilesStore.actionCreators;
  useEffect(() => {
    dispatch(actionCreatorStore.clearValues());
  }, [dispatch]);

  const onChange = (event) => {
    event.preventDefault();
    if (event.target.name === "selectedRegion") {
      const region = event.target.value;
      dispatch(actionCreatorStore.setRegion(region));
    }
    if (event.target.name === "selectedDepot") {
      const depotId = event.target.value;
      let callback = () => {
        $('[aria-label="refresh"]')[0].click();
      };
      dispatch(actionCreatorStore.setDepot(depotId, callback));
    }
  };
  const downloadReport = (reportId) => {
    dispatch(actionCreatorStore.downloadReceivedSalesFileReport(reportId));
  };
  const dateRender = (cell) => {
    return cell ? actions.formatDate(cell) : cell;
  };
  const actionRender = (cell, row, index) => {
    const report = row,
      detailsLinkTo = "/received-sales-files-report-details/" + report.id;

    return (
      <div className="align-center">
        <Button onClick={() => downloadReport(report.id)}>Download</Button>
        <Button onClick={() => history.push(detailsLinkTo)}>Details</Button>
      </div>
    );
  };

  const getReceivedSalesFilesTableProps = {
    tableID: "ReceivedSalesFiles",
    keyField: "depotName",
    wrapperType: "ReceivedSalesFiles",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json-patch+json",
      Authorization: `Bearer ${cookies.get("rawtoken")}`,
    },
    tableColumns: [
      {
        key: "depotName",
        title: i18next.t("depot"),
        inputFilterable: true,
        sortable: true,
      },
      {
        key: "extractDate",
        title: i18next.t("date"),
        inputFilterable: true,
        sortable: true,
        dataFormat: dateRender,
      },
      {
        key: "dateCreated",
        title: i18next.t("date_received"),
        inputFilterable: true,
        exactFilterable: true,
        sortable: true,
        dataFormat: dateRender,
      },

      {
        key: "status",
        title: i18next.t("file_status"),
        inputFilterable: true,
        exactFilterable: true,
        sortable: true,
      },
      { key: "totalEntries", title: i18next.t("count_rows") },
      { key: "datesCount", title: i18next.t("count_dates") },
      { key: "datesList", title: i18next.t("list_dates") },
      { key: "fileName", title: i18next.t("file_name") },
      {
        key: "action",
        title: i18next.t("actions"),
        dataFormat: actionRender,
      },
    ],
  };
  const regs = regions
    ? regions.map((region) => ({ value: region.name, label: region.name }))
    : [];
  const depots = receivedSalesFilesState.depotsList
    ? receivedSalesFilesState.depotsList.map((depot) => ({
        value: depot.id,
        label: depot.depotName,
      }))
    : [];
  return (
    <div>
      <TableHead>
        <TableHead.Field size="large">
          <Select
            label={i18next.t("region")}
            name="selectedRegion"
            value={receivedSalesFilesState.selectedRegion}
            defaultValue={"-1"}
            onChange={onChange}
            options={regs}
          />
        </TableHead.Field>
        <TableHead.Field size="large">
          <Select
            label={i18next.t("depot")}
            name="selectedDepot"
            value={receivedSalesFilesState.selectedDepot}
            defaultValue={"-1"}
            onChange={onChange}
            options={depots}
          />
        </TableHead.Field>
      </TableHead>

      <DataTable
        isLoading={receivedSalesFilesState.isLoading}
        title={i18next.t("received_sales_files")}
        tableSettings={getReceivedSalesFilesTableProps}
        apiLocation={
          paginationEndpoints.RECEIVED_SALES_FILES_PAGINATION_ENDPOINT +
          "?fileType=SALES&depotId=" +
          receivedSalesFilesState.selectedDepot
        }
      />
    </div>
  );
};

export default ReceivedSalesFiles;
