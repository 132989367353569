import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as OrdersStore from "../../store/OrdersReducer";
import { RouteComponentProps } from "react-router-dom";
import * as actions from "../Actions/ordersActions";
import { Row, Col } from "react-grid-system";
import Loader from "../Loader";
import Box from "../Box";
import Button from "../Button";
import { BackIcon, DownloadIcon } from "../../svg";
import * as utils from "components/Utils/actions";
import i18next from "i18next";

// At runtime, Redux will merge together...
type OrdersProps = OrdersStore.OrdersState & // ... state we've requested from the Redux store
  typeof OrdersStore.actionCreators & // ... plus action creators we've requested
  RouteComponentProps<{ location: any; orderId: any }>; // ... plus incoming routing parameters

class OrderDetails extends React.Component<OrdersProps> {
  //multiple select?
  //POC action
  state = {
    orderDetails: { id: {} },
    closeModal: undefined,
    selectedRow: undefined,
    selectedRowIdx: {},
  };

  constructor(props) {
    super(props);
  }

  downloadOrder = (orderId) => {
    this.props.downloadOrder(orderId);
    var selectedRow = this.state.selectedRow;
    if (selectedRow) {
      selectedRow["isDownloaded"] = true;
    }
    this.setState({
      selectedRow: selectedRow,
      shouldRender: false,
    });
  };

  componentDidMount() {
    let ref = this;
    const selectedOrderId = this.props.match.params.orderId;
    let callback = (response) => {
      ref.setState({
        selectedRow: response,
        shouldRender: false,
      });
    };
    let error = () => {
      ref.setState({
        selectedRow: {},
        shouldRender: false,
      });
    };
    actions.getOrderDetails(selectedOrderId, callback, error);
  }

  resetBtnState() {
    let buttons = document.getElementsByTagName("button");
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].disabled = false;
    }
  }

  toggleMapBtnState = (state) => {
    var createDisabled = this.state.selectedRow ? false : true,
      createBtn = document.getElementById("create-map-btn");
    if (createBtn) {
      (createBtn as HTMLInputElement).disabled = state ? state : createDisabled;
    }
  };

  navigateBack = (event) => {
    this.props.history.push("/orders");
  };

  public render() {
    const Detail = styled.div`
      position: relative;
    `;

    const Buttons = styled.div`
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
    `;

    Detail.Row = styled.div`
      border-bottom: 1px solid ${({ theme }) => theme.colors.border};
      padding: 10px 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    `;

    const Label = styled.h6`
      font-size: 15px;
      font-weight: 500;
      width: 40%;
      line-height: 1.3;
      color: ${({ theme }) => theme.colors.dark};
    `;

    const Value = styled.div`
      font-size: 14px;
      font-weight: 400;
      width: 60%;
      text-align: right;
    `;

    const NoResults = styled.div`
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 30px;
    `;

    const Alert = styled.div`
      display: inline-block;
      background: ${({ theme }) => theme.colors.danger};
      color: #fff;
      border-radius: 3px;
      padding: 5px 10px;
    `;

    const Table = styled.table`
      text-align: left;
      width: 100%;

      thead th {
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.dark};
      }

      &:not(.table-bordered) thead td,
      &:not(.table-bordered) thead th {
        border-top: 0;
      }

      td,
      th {
        padding: 0.75rem;
        vertical-align: top;
        border-top: 1px solid #ebedf2;
      }

      tbody tr:nth-of-type(odd) {
        background-color: #f7f8fa;
      }
    `;

    const { isLoading } = this.props;

    let selectedRow = this.state.selectedRow,
      hermesOrder = selectedRow ? selectedRow : "",
      orderExists = hermesOrder ? true : false,
      rowExists = selectedRow ? true : false,
      hermesOrderDetails = hermesOrder["orderDetailsInfo"]
        ? hermesOrder["orderDetailsInfo"]
        : "",
      missingSkuMapping = hermesOrder["missingSkuMappings"],
      missingPocMapping = hermesOrder["missingPocMapping"],
      hermesOrderId = orderExists ? hermesOrder["orderId"] : "",
      hermesOrderIsSeen = orderExists
        ? hermesOrder["isSeen"]
          ? "True"
          : "False"
        : "",
      hermesOrderIsDownloaded = orderExists
        ? hermesOrder["isDownloaded"]
          ? "True"
          : "False"
        : "",
      distributorCustomer = rowExists ? selectedRow["distributorCustomer"] : "",
      depotName = orderExists ? hermesOrder["depotName"] : "",
      orderDate = orderExists ? utils.formatDate(hermesOrder["orderDate"]) : "",
      deliveryDate = orderExists
        ? utils.formatDate(hermesOrder["deliveryDate"])
        : "",
      hermesPoc = orderExists ? hermesOrder["hermesPoc"] : "";

    const orderId = this.state.selectedRow
      ? this.state.selectedRow.orderDetailsInfo[0].orderDetailId
      : "";

    console.log(selectedRow);
    console.log(orderId);

    // temp fix for loading bug
    let isLoadingg = false;
    console.log(selectedRow);
    return isLoadingg ? (
      <Loader isLoading={true} />
    ) : (
      <div>
        <Buttons>
          <Button
            icon={BackIcon}
            size="large"
            variant="outlined"
            onClick={this.navigateBack}
          >
            {i18next.t("back")}
          </Button>
          <Button
            icon={DownloadIcon}
            size="large"
            onClick={() => this.downloadOrder(selectedRow ? orderId : "")}
          >
            {i18next.t("xml")}
          </Button>
        </Buttons>

        <Box
          hasMargin
          hasContentGutter
          title={i18next.t("order_history_details")}
        >
          <Detail>
            <Detail.Row>
              <Label>{i18next.t("order_history_number")}</Label>
              <Value>{orderExists ? hermesOrderId : ""}</Value>
            </Detail.Row>

            <Detail.Row>
              <Label>{i18next.t("is_seen")}</Label>
              <Value>{orderExists ? hermesOrderIsSeen : ""}</Value>
            </Detail.Row>

            <Detail.Row>
              <Label>{i18next.t("is_downloaded")}</Label>
              <Value>{orderExists ? hermesOrderIsDownloaded : ""}</Value>
            </Detail.Row>

            <Detail.Row>
              <Label>{i18next.t("distributor")}</Label>
              <Value>{rowExists ? hermesOrder["distributorName"] : ""}</Value>
            </Detail.Row>

            <Detail.Row>
              <Label>{i18next.t("has_missing_mapping")}</Label>
              <Value>
                {orderExists
                  ? missingSkuMapping || missingPocMapping
                    ? "True"
                    : "False"
                  : ""}
              </Value>
            </Detail.Row>

            <Detail.Row>
              <Label>{i18next.t("depot")}</Label>
              <Value>{orderExists ? depotName : ""}</Value>
            </Detail.Row>

            <Detail.Row>
              <Label>{i18next.t("order_history_date")}</Label>
              <Value>{orderExists ? orderDate : ""}</Value>
            </Detail.Row>
            <Detail.Row>
              <Label>{i18next.t("delivery_date")}</Label>
              <Value>{orderExists ? deliveryDate : ""}</Value>
            </Detail.Row>
          </Detail>
        </Box>

        <Row>
          <Col xs={12} sm={6}>
            <Box hasMargin hasContentGutter title={i18next.t(" poc_hermes")}>
              <Detail>
                <Detail.Row>
                  <Label>{i18next.t("name")}</Label>
                  <Value>{hermesPoc ? hermesPoc["name"] : ""}</Value>
                </Detail.Row>

                <Detail.Row>
                  <Label>{i18next.t("eik")}</Label>
                  <Value>
                    {hermesPoc["customer"]
                      ? hermesPoc["customer"]["fiscalCode"]
                      : ""}
                  </Value>
                </Detail.Row>

                <Detail.Row>
                  <Label>{i18next.t("address")}</Label>
                  <Value>{hermesPoc ? hermesPoc["address"] : ""}</Value>
                </Detail.Row>
              </Detail>
              {missingPocMapping ? (
                <NoResults>
                  <Alert>{i18next.t("missing_poc_mapping")}!</Alert>
                </NoResults>
              ) : (
                ""
              )}
            </Box>
          </Col>

          <Col xs={12} sm={6}>
            <Box
              hasMargin
              hasContentGutter
              title={i18next.t("poc_distributor")}
            >
              <Detail>
                <Detail.Row>
                  <Label>{i18next.t("name")}</Label>
                  <Value>
                    {distributorCustomer ? distributorCustomer["name"] : ""}
                  </Value>
                </Detail.Row>

                <Detail.Row>
                  <Label>{i18next.t("eik")}</Label>
                  <Value>
                    {distributorCustomer ? distributorCustomer["vat"] : ""}
                  </Value>
                </Detail.Row>

                <Detail.Row>
                  <Label>{i18next.t("address")}</Label>
                  <Value>
                    {distributorCustomer
                      ? distributorCustomer["addressOfDispatching"]
                      : ""}
                  </Value>
                </Detail.Row>
              </Detail>

              {missingPocMapping ? (
                <NoResults>
                  <Alert>{i18next.t("missing_poc_mapping")}!</Alert>
                </NoResults>
              ) : (
                ""
              )}
            </Box>
          </Col>
        </Row>

        <Box
          hasMargin
          hasContentGutter
          title={i18next.t("order_history_items")}
        >
          <Table>
            <thead>
              <tr>
                <th>{i18next.t("hermes_sku_code")}</th>
                <th>{i18next.t("hermes_sku_name")}</th>
                <th>{i18next.t("distributor_sku_code")}</th>
                <th>{i18next.t("distributor_sku_name")}</th>
                <th>{i18next.t("qty")}</th>
                <th>{i18next.t("is_free")}</th>
              </tr>
            </thead>

            <tbody>
              {hermesOrderDetails
                ? hermesOrderDetails.map((orderItem, id) => {
                    return (
                      <tr>
                        <td>{orderItem["skuCode"]}</td>
                        <td>{orderItem["skuName"]}</td>
                        <td>{orderItem["productCode"]}</td>
                        <td>{orderItem["productName"]}</td>
                        <td>{orderItem["quantity"]}</td>
                        <td>{orderItem["isFree"] ? "True" : "False"}</td>
                      </tr>
                    );
                  })
                : ""}
            </tbody>
          </Table>
          {missingSkuMapping ? (
            <NoResults>
              <Alert>{i18next.t("missing_sku_mapping")}</Alert>
            </NoResults>
          ) : (
            ""
          )}
        </Box>
      </div>
    );
  }
}
export default connect(
  (state: ApplicationState) => state.orders,
  OrdersStore.actionCreators,
)(OrderDetails);
