import React from 'react'

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 63 63"
      version="1.1"
      viewBox="0 0 63 63"
      xmlSpace="preserve"
    >
      <g>
        <path d="M42.573 48.047h-28a1.5 1.5 0 010-3.002h28a1.5 1.5 0 010 3.002z"></path>
        <path d="M57.073 48.047h-2.5a1.5 1.5 0 010-3.002h2.5c1.379 0 2.927-1.123 2.927-2.502V30.86c0-1.016-.812-1.984-1.776-2.526l-3.191-1.657c-1.312-.737-2.293-1.804-2.759-3.097l-3.311-8.944c-.524-1.457-2.14-1.614-2.799-1.614H5.073C3.694 13.022 3 14.145 3 15.524V41.97c0 1.551 1.262 2.794 2.64 2.983.821.113 1.289.87 1.177 1.692-.113.821-.723 1.395-1.53 1.283C2.283 47.516 0 44.844 0 41.97V15.524c0-3.035 2.041-5.504 5.073-5.504h41.091c2.692 0 4.783 1.342 5.592 3.59l3.284 8.943c.224.622.671 1.122 1.331 1.493l3.297 1.657C61.601 26.789 63 28.76 63 30.86v11.683c0 3.035-2.895 5.504-5.927 5.504z"></path>
        <path d="M48.638 52.98c-3.361 0-6.094-2.736-6.094-6.099a6.103 6.103 0 016.094-6.098c3.36 0 6.094 2.736 6.094 6.098 0 3.363-2.734 6.099-6.094 6.099zm0-9.195a3.099 3.099 0 00-3.094 3.096c0 1.708 1.388 3.097 3.094 3.097s3.094-1.389 3.094-3.097a3.099 3.099 0 00-3.094-3.096z"></path>
        <path d="M9.749 52.98c-3.36 0-6.094-2.736-6.094-6.099 0-3.362 2.734-6.098 6.094-6.098s6.095 2.736 6.095 6.098a6.104 6.104 0 01-6.095 6.099zm0-9.195a3.099 3.099 0 00-3.094 3.096c0 1.708 1.388 3.097 3.094 3.097s3.095-1.389 3.095-3.097a3.1 3.1 0 00-3.095-3.096z"></path>
        <path d="M40.5 38.54a1.5 1.5 0 01-1.5-1.501V12.021a1.5 1.5 0 113 0v25.018a1.5 1.5 0 01-1.5 1.501z"></path>
        <path d="M60.573 39.041h-59a1.5 1.5 0 010-3.003h59a1.501 1.501 0 010 3.003z"></path>
        <path d="M55.573 27.032h-15a1.5 1.5 0 010-3.002h15a1.5 1.5 0 010 3.002z"></path>
      </g>
    </svg>
  )
