import * as React from 'react';

// ErrorBoundary component
class ErrorBoundary extends React.PureComponent {

    state = { hasError: false };

    constructor(props) {
        super(props);
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                // error message
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary