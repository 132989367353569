import React, { useMemo, useState } from "react";

import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { ColDef, GetRowIdParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button, DatePicker, Flex, Typography, Upload, UploadFile } from "antd";
import dayjs, { Dayjs } from "dayjs";
import i18next from "i18next";
import { toast } from "react-toastify";

import {
  useGetImportHistory,
  useImportMultiPack,
  useImportOnInvoice,
  useImportProductAvailability,
} from "api/reactQuery/queryComponents";
import { BSCoreServicesDtoMarketplaceImportHistoryDto } from "api/reactQuery/querySchemas";
import ErrorModal from "components/UI/Modals/ErrorModal";
import * as actions from "components/Utils/actions";
import { USER_ROLES } from "components/Utils/actions";
import { dateFormat, formatDateForBE } from "components/Utils/dayjsUtils";
import {
  COLUMN_TYPE_NAMES,
  getGridDefaultProps,
} from "constants/agGridConstants";

const UploadProductsData: React.FC = () => {
  const [productsAvailabilityFile, setProductsAvailabilityFile] =
    useState<UploadFile>();
  const [multipackFile, setMultipackFile] = useState<UploadFile>();
  const [onInvoiceProductFile, setOnInvoiceProductFile] =
    useState<UploadFile>();
  const [startDate, setStartDate] = useState<Dayjs>(dayjs);
  const [endDate, setEndDate] = useState<Dayjs>(dayjs);
  const [errorModalState, setErrorModalState] = useState<{
    isOpen: boolean;
    message?: string;
  }>();
  const userRole = actions.getUserRole();
  const {
    mutate: importProductAvailabilityFile,
    isLoading: productAvailabilityLoading,
  } = useImportProductAvailability({
    onError: (error) => {
      //@ts-ignore
      if (error?.stack?.Message) {
        //@ts-ignore
        setErrorModalState({ isOpen: true, message: error?.stack?.Message });
      } else toast.error("Something went wrong!");
    },
    onSuccess: async () => {
      toast.success("Product availability updated successfully");
      setProductsAvailabilityFile(undefined);
      await refetchImportHistory();
    },
  });
  const { mutate: importOnInvoiceFile, isLoading: onInvoiceLoading } =
    useImportOnInvoice({
      onError: (error) => {
        //@ts-ignore
        if (error?.stack?.Message) {
          //@ts-ignore
          setErrorModalState({ isOpen: true, message: error?.stack?.Message });
        } else toast.error("Something went wrong!");
      },
      onSuccess: async () => {
        toast.success("On Invoice updated successfully");
        setOnInvoiceProductFile(undefined);
        await refetchImportHistory();
      },
    });
  const { mutate: importMultiPackFile, isLoading: multipackLoading } =
    useImportMultiPack({
      onError: (error) => {
        //@ts-ignore
        if (error?.stack?.Message) {
          //@ts-ignore
          setErrorModalState({ isOpen: true, message: error?.stack?.Message });
        } else toast.error("Something went wrong!");
      },
      onSuccess: async () => {
        toast.success("Multipack updated successfully");
        setMultipackFile(undefined);
        await refetchImportHistory();
      },
    });
  const { data: importHistoryData, refetch: refetchImportHistory } =
    useGetImportHistory(
      {
        queryParams: {
          endDate: formatDateForBE(endDate),
          startDate: formatDateForBE(startDate),
        },
      },
      { enabled: !!startDate || !!endDate },
    );

  const columnDefs = useMemo<
    ColDef<BSCoreServicesDtoMarketplaceImportHistoryDto>[]
  >(
    () => [
      { field: "fileName", flex: 2, headerName: i18next.t("file_name") },
      {
        field: "uploadDate",
        headerName: i18next.t("upload_date"),
        type: COLUMN_TYPE_NAMES.DATE_COLUMN,
      },
      {
        field: "uploadedByUserName",
        headerName: i18next.t("uploaded_by_user_name"),
      },
      {
        field: "numberOfImportedRows",
        headerName: i18next.t("number_of_imported_rows"),
      },
      { field: "id", hide: true, sort: "desc" },
    ],
    [],
  );

  const onUploadOnInvoiceHandler = () => {
    if (!onInvoiceProductFile) return;
    const formData: any = new FormData();
    formData.append("file", onInvoiceProductFile);

    importOnInvoiceFile({
      body: formData,
      headers: { "Content-Type": "multipart/form-data;" },
    });
  };

  const onUploadMultipackHandler = () => {
    if (!multipackFile) return;
    const formData: any = new FormData();
    formData.append("file", multipackFile);

    importMultiPackFile({
      body: formData,
      headers: { "Content-Type": "multipart/form-data;" },
    });
  };

  const onUploadProductAvailabilityHandler = () => {
    if (!productsAvailabilityFile) return;
    const formData: any = new FormData();
    formData.append("file", productsAvailabilityFile);

    importProductAvailabilityFile({
      body: formData,
      headers: { "Content-Type": "multipart/form-data;" },
    });
  };
  const onDateChangeHandler = async (dates: Dayjs[]) => {
    if (dates) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    }
  };
  const onModalClose = () => {
    setErrorModalState(undefined);
    setOnInvoiceProductFile(undefined);
    setProductsAvailabilityFile(undefined);
  };

  return (
    <>
      {errorModalState?.message && (
        <ErrorModal
          isOpen={errorModalState?.isOpen}
          text={errorModalState?.message}
          title={i18next.t("upload_failed")}
          icon={<FileExcelOutlined />}
          onClose={onModalClose}
        />
      )}
      <Flex>
        {userRole !== USER_ROLES.TENANT_SUPERVISOR && (
          <Flex style={{ marginRight: 50 }}>
            <Upload
              beforeUpload={() => false}
              maxCount={1}
              fileList={productsAvailabilityFile && [productsAvailabilityFile]}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(info) => {
                setProductsAvailabilityFile(
                  info.file.status === "removed" ? undefined : info.file,
                );
              }}
              onPreview={() => null}
            >
              <Button style={{ alignItems: "center", display: "flex" }}>
                <PlusOutlined /> {i18next.t("choose_product_availability_file")}
              </Button>
            </Upload>
            <Button
              type="primary"
              onClick={onUploadProductAvailabilityHandler}
              disabled={!productsAvailabilityFile}
              loading={productAvailabilityLoading}
              style={{ marginLeft: 20 }}
            >
              {i18next.t("upload")}
            </Button>
          </Flex>
        )}
        <Flex style={{ marginRight: 50 }}>
          <Upload
            beforeUpload={() => false}
            maxCount={1}
            fileList={onInvoiceProductFile && [onInvoiceProductFile]}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={(info) =>
              setOnInvoiceProductFile(
                info.file.status === "removed" ? undefined : info.file,
              )
            }
            onPreview={() => null}
          >
            <Button style={{ alignItems: "center", display: "flex" }}>
              <PlusOutlined /> {i18next.t("choose_on_invoice_product_files")}
            </Button>
          </Upload>
          <Button
            onClick={onUploadOnInvoiceHandler}
            type="primary"
            loading={onInvoiceLoading}
            disabled={!onInvoiceProductFile}
            style={{ marginLeft: 20 }}
          >
            {i18next.t("upload")}
          </Button>
        </Flex>
        <Flex>
          <Upload
            beforeUpload={() => false}
            maxCount={1}
            fileList={multipackFile && [multipackFile]}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={(info) =>
              setMultipackFile(
                info.file.status === "removed" ? undefined : info.file,
              )
            }
            onPreview={() => null}
          >
            <Button style={{ alignItems: "center", display: "flex" }}>
              <PlusOutlined /> {i18next.t("choose_multipack_file")}
            </Button>
          </Upload>
          <Button
            onClick={onUploadMultipackHandler}
            type="primary"
            loading={multipackLoading}
            disabled={!multipackFile}
            style={{ marginLeft: 20 }}
          >
            {i18next.t("upload")}
          </Button>
        </Flex>
      </Flex>
      <div style={{ marginTop: 25 }}>
        <Typography.Text style={{ marginRight: 10 }}>
          {i18next.t("select_period")}
        </Typography.Text>
        <DatePicker.RangePicker
          format={dateFormat}
          maxDate={dayjs()}
          defaultValue={[startDate, endDate]}
          onChange={onDateChangeHandler}
        />
      </div>
      <div style={{ height: 300 }}>
        <AgGridReact
          getRowId={(
            params: GetRowIdParams<BSCoreServicesDtoMarketplaceImportHistoryDto>,
          ) => String(params.data.id)}
          rowData={importHistoryData}
          domLayout="normal"
          singleClickEdit
          columnDefs={columnDefs}
          {...getGridDefaultProps()}
        />
      </div>
    </>
  );
};
export default UploadProductsData;
