import * as React from "react";
import styled from "styled-components";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import * as AuthenticationStore from "../store/AuthenticationReducer";
import * as SKUStore from "../store/SKUReducer";
import * as POCStore from "../store/POCReducer";
import { ApplicationState } from "store";
import { connect } from "react-redux";
import * as actions from "components/Utils/actions";
import Dropdown from "./Dropdown";
import DropdownLanguage from "./DropdownLanguage";
import {
  FileIcon,
  UsersIcon,
  BackIcon,
  BusIcon,
  LogoutIcon,
  ReportsIcon,
  SkuIcon,
  DeleteIcon,
} from "../svg";
import LogoIMG from "../images/logo.png";
import moment from "moment";
import { bindActionCreators } from "redux";

import * as roles from "components/Utils/actions";
import { USER_ROLES } from "components/Utils/actions";
import { BarcodeOutlined } from "@ant-design/icons";
import ShoppingBag from "svg/ShoppingBag";
import i18next from "i18next";

type AuthenticationProps = AuthenticationStore.AuthenticationState &
  typeof AuthenticationStore.actionCreators &
  RouteComponentProps<{}>;

class NavMenu extends React.PureComponent<any> {
  constructor(props) {
    super(props);
    var userRole = actions.getUserRole();
    if (userRole) {
      this.props.authenticationActions.getCurrentLoggedUser();
      this.props.authenticationActions.changeAuthentication(true);
    }
    Date.prototype.toJSON = function () {
      return moment(this).format("YYYY-MM-DD");
    };
  }

  public state = {
    isOpen: false,
    unmappedSKUItemsCount: null,
    unmappedPOCItemsCount: null,
  };

  componentDidMount() {
    let userRole = actions.getUserRole();
    if (
      userRole === USER_ROLES.DATA_LOADER ||
      userRole === USER_ROLES.CUSTOMER_SERVICE
    ) {
      return;
    }
    this.props.pocActions.getPocUnmappedItems();
    if (userRole === USER_ROLES.TENANT_ADMIN) {
      this.props.skuActions.getUnmappedItems();
    }
  }

  public render() {
    const { isAuthenticated } = this.props.authenticationState;
    var userRole = actions.getUserRole();

    if (isAuthenticated && !userRole) {
      this.props.authenticationActions.changeAuthentication(false);
      this.props.history.push("/");
    }

    const Logo = styled.div`
      cursor: pointer;
      display: block;
      width: 110px;

      img {
        width: 100%;
        display: block;
      }
    `;

    const Header = styled.div`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0 30px;
      z-index: 1;
      box-shadow: 0 4px 30px -4px rgba(0, 51, 90, 0.1);
      background: #fff;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: space-between;
      z-index: 80;
    `;

    const Nav = styled.ul`
      position: relative;
    `;

    const Badge = styled.div`
      padding: 2%;
      position: absolute;
      top: 1%;
      left: 40%;
      border-radius: 20%;
      height: 21px;
      text-align: center;
      color: white;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    `;

    Nav.Item = styled.li`
      position: relative;
      display: inline-block;
      margin: 0 15px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      > a {
        color: ${({ theme }) => theme.colors.dark};
        display: flex;
        align-items: center;
        font-size: 12px;
        padding: 18px 0;
        font-weight: 500;
        text-transform: uppercase;

        svg {
          width: 20px;
          height: 20px;
          display: block;
          fill: ${({ theme }) => theme.colors.dark};
          margin-right: 8px;
        }

        &:hover,
        &.active {
          color: ${({ theme }) => theme.colors.default};

          svg {
            fill: ${({ theme }) => theme.colors.default};
          }
        }
      }
    `;
    const reports = {
      [USER_ROLES.TENANT_SUPPORT]: [
        {
          to: "/whs-data-report",
          label: i18next.t("whs_data_report"),
        },
      ],
      [USER_ROLES.CUSTOMER_SERVICE]: [
        {
          to: "/marketplace-reports",
          label: i18next.t("marketplace_reports"),
        },
      ],
      [USER_ROLES.TENANT_ADMIN]: [
        {
          to: "/received-sales-files-report",
          label: i18next.t("received_sales_files"),
        },
        {
          to: "/depot-raw-data-report",
          label: i18next.t("depot_raw_data"),
        },
        {
          to: "/whs-data-report",
          label: i18next.t("whs_data_report"),
        },
        {
          to: "/generating-sales-report",
          label: i18next.t("sales_report"),
        },
        {
          to: "/whs-stock-report",
          label: i18next.t("whs_stock_report"),
        },
        {
          to: "/uploaded-files-report",
          label: i18next.t("uploaded_files_report"),
        },
        {
          to: "/poc-mapping-history",
          label: i18next.t("poc_mapping_history"),
        },
        {
          to: "/sku-mapping-history",
          label: i18next.t("sku_mapping_history"),
        },
        {
          to: "/monthly-raw-sales-report",
          label: i18next.t("monthly_raw_sales_report"),
        },
        {
          to: "/marketplace-reports",
          label: i18next.t("marketplace_reports"),
        },
      ],
      [USER_ROLES.TENANT_SUPERVISOR]: [
        {
          to: "/whs-data-report",
          label: i18next.t("whs_data_report"),
        },
        {
          to: "/generating-sales-report",
          label: i18next.t("sales_report"),
        },
        {
          to: "/whs-stock-report",
          label: i18next.t("whs_stock_report"),
        },
        {
          to: "/monthly-raw-sales-report",
          label: i18next.t("monthly_raw_sales_report"),
        },
        {
          to: "/marketplace-reports",
          label: i18next.t("marketplace_reports"),
        },
      ],
    };

    const deletion = userRole === USER_ROLES.TENANT_ADMIN && [
      {
        to: "/sku-mapping-deletion",
        label: i18next.t("delete_sku_mapping"),
      },
      {
        to: "/row-from-sales-table-deletion",
        label: i18next.t("delete_rows_from_sales_table"),
      },
      {
        to: "/dtr-sales-deletion",
        label: i18next.t("delete_dtr_sales"),
      },
    ];

    const Loader = () => <div className="loader" />;

    const role = roles.getUserRole();
    let logoRedirectTo = "/dashboard";
    switch (role) {
      case USER_ROLES.ADMINISTRATOR:
        logoRedirectTo = "/manage-tenants";
        break;
      case USER_ROLES.CUSTOMER_SERVICE:
        logoRedirectTo = "/manage-distributors";
        break;
      case USER_ROLES.TENANT_SUPERVISOR:
        logoRedirectTo = "/orders";
        break;
      case USER_ROLES.DATA_LOADER:
        logoRedirectTo = "/data-load";
        break;
    }

    return isAuthenticated ? (
      <Header>
        <Logo>
          <NavLink to={logoRedirectTo}>
            <img src={LogoIMG} />
          </NavLink>
        </Logo>

        <Nav>
          {userRole === USER_ROLES.TENANT_ADMIN && (
            <Nav.Item>
              <Dropdown
                icon={DeleteIcon}
                title={i18next.t("deletion")}
                items={deletion}
              />
            </Nav.Item>
          )}
          {(userRole === USER_ROLES.TENANT_ADMIN ||
            userRole === USER_ROLES.TENANT_SUPERVISOR ||
            userRole === USER_ROLES.ADMINISTRATOR ||
            userRole === USER_ROLES.CUSTOMER_SERVICE) && (
            <>
              <Nav.Item>
                <Dropdown
                  icon={BarcodeOutlined}
                  title={i18next.t("product_master_data")}
                  items={
                    userRole !== USER_ROLES.TENANT_SUPERVISOR
                      ? [
                          { to: "/products", label: i18next.t("products") },
                          { to: "/pallets", label: i18next.t("pallets") },
                          { to: "/packaging", label: i18next.t("packaging") },
                          {
                            to: "/upload-products-data",
                            label: i18next.t("upload"),
                          },
                        ]
                      : [
                          { to: "/products", label: i18next.t("products") },
                          {
                            to: "/upload-products-data",
                            label: i18next.t("upload"),
                          },
                        ]
                  }
                />
              </Nav.Item>
              <Nav.Item>
                <Dropdown
                  icon={ShoppingBag}
                  title={i18next.t("order")}
                  items={[
                    { to: "/create-order", label: i18next.t("create") },
                    { to: "/order-history", label: i18next.t("history") },
                  ]}
                />
              </Nav.Item>
            </>
          )}

          {userRole !== USER_ROLES.ADMINISTRATOR &&
          userRole !== USER_ROLES.DATA_LOADER ? (
            <>
              {userRole !== USER_ROLES.TENANT_SUPERVISOR &&
                userRole !== USER_ROLES.CUSTOMER_SERVICE &&
                userRole !== USER_ROLES.DISTRIBUTOR && (
                  <Nav.Item>
                    <Badge>
                      {this.props.pocState.unmappedItems === undefined ||
                      this.props.pocState.unmappedItems === null ||
                      !this.props.pocState.unmappedItems ? (
                        <Loader />
                      ) : (
                        <Badge
                          style={{
                            backgroundColor: "#1976d2",
                            padding: "3px",
                          }}
                        >
                          {this.props.pocState.unmappedItems}
                        </Badge>
                      )}
                    </Badge>
                    <Dropdown
                      title={i18next.t("poc")}
                      icon={FileIcon}
                      items={[
                        {
                          to: "/create-poc-mapping",
                          label: i18next.t("create_poc_mapping"),
                        },
                        {
                          to: "/mapping-history-poc",
                          label: i18next.t("edit_poc_mapping"),
                        },
                      ]}
                    />
                  </Nav.Item>
                )}

              {userRole === USER_ROLES.TENANT_ADMIN && (
                <Nav.Item>
                  <Badge>
                    {this.props.skuState.unmappedItems
                      ?.countOfMissingSkuMappings === undefined &&
                    !this.props.skuState.unmappedItems
                      ?.countOfMissingSkuMappings?.length ? (
                      <Loader />
                    ) : (
                      <Badge
                        style={{
                          backgroundColor: "#1976d2",
                          padding: "3px",
                        }}
                      >
                        {
                          this.props.skuState.unmappedItems
                            .countOfMissingSkuMappings
                        }
                      </Badge>
                    )}
                  </Badge>
                  <NavLink to="/create-sku-mapping">
                    <SkuIcon />
                    {i18next.t("sku")}
                  </NavLink>
                </Nav.Item>
              )}

              {(userRole === USER_ROLES.TENANT_ADMIN ||
                userRole === USER_ROLES.TENANT_SUPERVISOR ||
                userRole === USER_ROLES.TENANT_SUPPORT ||
                userRole === USER_ROLES.CUSTOMER_SERVICE) && (
                <Nav.Item>
                  <Dropdown
                    icon={ReportsIcon}
                    title={i18next.t("reports")}
                    items={reports[userRole]}
                  />
                </Nav.Item>
              )}

              {userRole === USER_ROLES.TENANT_ADMIN && (
                <>
                  <Nav.Item>
                    <NavLink to="/users">
                      <UsersIcon />
                      {i18next.t("users")}
                    </NavLink>
                  </Nav.Item>
                </>
              )}
              {(userRole === USER_ROLES.TENANT_ADMIN ||
                userRole === USER_ROLES.CUSTOMER_SERVICE) && (
                <Nav.Item>
                  <NavLink to="/manage-distributors">
                    <BusIcon />
                    {i18next.t("distributors")}
                  </NavLink>
                </Nav.Item>
              )}
              <Nav.Item>
                <NavLink to="/logout" onClick={this.logout}>
                  <LogoutIcon /> {i18next.t("logout")}
                </NavLink>
              </Nav.Item>

              <Nav.Item>
                <DropdownLanguage />
              </Nav.Item>

              {this.props.authenticationState.currentUserInfo && (
                <Nav.Item>
                  <NavLink to="/">
                    <BackIcon />
                    {this.props.authenticationState.currentUserInfo}
                  </NavLink>
                </Nav.Item>
              )}
            </>
          ) : userRole === USER_ROLES.ADMINISTRATOR ? (
            <>
              <Nav.Item>
                <NavLink to="/manage-tenants">{i18next.t("tenants")}</NavLink>
              </Nav.Item>

              <Nav.Item>
                <NavLink to="/manage-admin-tenant-admin">
                  {i18next.t("users")}
                </NavLink>
              </Nav.Item>

              <Nav.Item>
                <NavLink to="/logout" onClick={this.logout}>
                  {i18next.t("logout")}
                </NavLink>
              </Nav.Item>

              {this.props.authenticationState.currentUserInfo && (
                <Nav.Item>
                  <NavLink to="/">
                    <BackIcon />
                    {this.props.authenticationState.currentUserInfo}
                  </NavLink>
                </Nav.Item>
              )}
            </>
          ) : (
            userRole === USER_ROLES.DATA_LOADER && (
              <>
                <Nav.Item>
                  <NavLink to="/data-load">
                    <FileIcon />
                    {i18next.t("load_data")}
                  </NavLink>
                </Nav.Item>

                <Nav.Item>
                  <NavLink to="/logout" onClick={this.logout}>
                    <LogoutIcon /> {i18next.t("logout")}
                  </NavLink>
                </Nav.Item>

                {this.props.authenticationState.currentUserInfo && (
                  <Nav.Item>
                    <NavLink to="/">
                      <BackIcon />
                      {this.props.authenticationState.currentUserInfo}
                    </NavLink>
                  </Nav.Item>
                )}
              </>
            )
          )}
        </Nav>
      </Header>
    ) : null;
  }

  private logout = (event) => {
    event.preventDefault();
    this.props.authenticationActions.removeAuthentication();
    this.props.authenticationActions.changeAuthentication(false);
    this.props.history.push("/");
  };

  private toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
}

function mapStateToProps(state: ApplicationState) {
  return {
    authenticationState: state.authentication,
    skuState: state.sku,
    pocState: state.poc,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authenticationActions: bindActionCreators(
      AuthenticationStore.actionCreators,
      dispatch,
    ),
    skuActions: bindActionCreators(SKUStore.actionCreators, dispatch),
    pocActions: bindActionCreators(POCStore.actionCreators, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavMenu),
);
