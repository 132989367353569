import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as POCStore from "../../store/POCReducer";

import TableWrapperProductHistory from "../TableWrapperProductHistory";
import styled from "styled-components";
import { Modal } from "reactstrap";
import i18next from "i18next";

interface ModalProps {
  selectedProductId?: any;
  closeModal?: any;
  isOpen?: any;
  isFreeCase?: any;
}

class ModalProductHistory extends React.Component<ModalProps> {
  state = {};

  _isMounted = false;

  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  closeModal = (shouldUpdate) => {
    this.props.closeModal(shouldUpdate);
  };

  getProductTableSettings = () => {
    return {
      tableID: "HermesSKU",
      keyField: "hermesId",
      tableColumns: [
        {
          key: "productName",
          title: i18next.t("product_name"),
          isFilterable: false,
          sortable: false,
        },
        {
          key: "productCode",
          title: i18next.t("product_code"),
          isFilterable: false,
          sortable: false,
        },
        {
          key: "skuName",
          title: i18next.t("sku_name"),
          isFilterable: false,
          sortable: false,
        },
        {
          key: "skuCode",
          title: i18next.t("sku_code"),
          isFilterable: false,
          sortable: false,
        },
        {
          key: "depotName",
          title: i18next.t("depot"),
          isFilterable: false,
          sortable: false,
        },
        {
          key: "depotCode",
          title: i18next.t("depo_code"),
          isFilterable: false,
          sortable: false,
        },
        {
          key: "fromDate",
          title: i18next.t("from"),
          isFilterable: false,
          sortable: false,
        },
        {
          key: "toDate",
          title: i18next.t("to"),
          isFilterable: false,
          sortable: false,
        },
        {
          key: "isFreeCase",
          title: i18next.t("has_free_case"),
          isFilterable: false,
          sortable: false,
        },
        {
          key: "isActive",
          title: i18next.t("isActive"),
          isFilterable: false,
          sortable: false,
        },
      ],
      tableCells: [
        "productName",
        "productCode",
        "skuName",
        "skuCode",
        "depotName",
        "depotCode",
        "fromDate",
        "toDate",
        "isFreeCase",
        "isActive",
      ],
    };
  };

  public render() {
    const ModalWrapper = styled.div`
      //button.disabled { opacity: 0.6; pointer-events: none;}
      .search {
        padding-top: 3px;
      }
    `;

    const Head = styled.div`
      padding: 12px 20px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    `;

    return (
      <ModalWrapper>
        <div className="modal-wrapper">
          <Modal isOpen={this.props.isOpen} scrollable={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                {i18next.t("product_mapping_history")}
              </h5>

              <button
                type="button"
                onClick={() => this.closeModal(false)}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <TableWrapperProductHistory
                title={i18next.t("product_mapping_history")}
                tableSettings={this.getProductTableSettings()}
                selectedProductId={this.props.selectedProductId}
                isFreeCase={this.props.isFreeCase}
              />
            </div>
          </Modal>
        </div>
      </ModalWrapper>
    );
  }
}
export default connect(
  (state: ApplicationState) => state.poc,
  POCStore.actionCreators,
)(ModalProductHistory);
