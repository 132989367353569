import React, { useCallback, useMemo, useRef, useState } from "react";

import {
  CellDoubleClickedEvent,
  ColDef,
  GetRowIdParams,
  RowDataTransaction,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Modal } from "antd";
import i18next from "i18next";
import { toast } from "react-toastify";

import {
  useGetAllDistributorsForProduct,
  useUpsertProductDistributor,
} from "api/reactQuery/queryComponents";
import {
  BSCoreServicesDtoMarketplaceProductDistributorDto,
  BSCoreServicesDtoMarketplaceProductDto,
  BSDALMarketplaceEnumsOrderPackingType,
} from "api/reactQuery/querySchemas";
import { MarketplaceOrderPackingTypes } from "components/Utils/enums";
import { getGridDefaultProps } from "constants/agGridConstants";

type Props = {
  isOpen: boolean;
  toggleModalOpen: () => void;
  productId: BSCoreServicesDtoMarketplaceProductDto["id"];
  isMultiPack: BSCoreServicesDtoMarketplaceProductDto["isMultiPack"];
};

const WholesalerSettingModal: React.FC<Props> = ({
  isOpen,
  toggleModalOpen,
  productId,
  isMultiPack,
}) => {
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const { data } = useGetAllDistributorsForProduct(
    {
      queryParams: { productId },
    },
    { enabled: !!productId && isOpen },
  );
  const gridRef =
    useRef<AgGridReact<BSCoreServicesDtoMarketplaceProductDistributorDto>>(
      null,
    );

  const { mutate } = useUpsertProductDistributor({
    onError: () => {
      toast.error(i18next.t("something_went_wrong"));
    },
    onSuccess: () => {
      toast.success(i18next.t("wholesalers_updated_successfully"));
      toggleModalOpen();
    },
  });
  const onCellDoubleClicked = useCallback(
    (
      event: CellDoubleClickedEvent<BSCoreServicesDtoMarketplaceProductDistributorDto>,
    ) => {
      const transaction: RowDataTransaction = {
        update: [{ ...event.data, isSelected: !event.data.isSelected }],
      };
      setIsSaveDisabled(false);
      gridRef.current.api.applyTransaction(transaction);
    },
    [gridRef],
  );
  const columnDefs = useMemo<
    ColDef<BSCoreServicesDtoMarketplaceProductDistributorDto>[]
  >(
    () => [
      {
        editable: true,
        field: "isSelected",
        headerName: "",
        maxWidth: 50,
      },
      {
        field: "distributorName",
        headerName: i18next.t("distributor_name"),
        onCellDoubleClicked,
      },
      {
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: Object.values(BSDALMarketplaceEnumsOrderPackingType),
        },
        editable: (params) => params.data.isSelected,
        field: "orderPackingType",
        filter: false,
        headerName: i18next.t("packing_type"),
        maxWidth: 150,
        valueFormatter: (params) => MarketplaceOrderPackingTypes[params.value],
        valueGetter: (params) =>
          MarketplaceOrderPackingTypes[params.data.orderPackingType] ||
          params.data.orderPackingType,
        valueParser: (params) => MarketplaceOrderPackingTypes[params.newValue],
      },
      {
        editable: (params) => params.data.isSelected,
        field: "multiPackLimit",
        headerName: i18next.t("multipack_limit"),
        hide: !isMultiPack,
        valueParser: (params) => Number(params.newValue),
      },
    ],
    [isMultiPack, onCellDoubleClicked],
  );

  const onSaveHandler = () => {
    const allRowData: BSCoreServicesDtoMarketplaceProductDistributorDto[] = [];
    gridRef?.current?.api?.forEachNode((node) => allRowData.push(node.data));

    allRowData.length &&
      mutate({ body: allRowData, queryParams: { productId } });
  };

  return (
    <Modal
      open={isOpen}
      centered
      destroyOnClose
      okText={i18next.t("save")}
      afterOpenChange={(open) => !open && setIsSaveDisabled(true)}
      okButtonProps={{ disabled: isSaveDisabled }}
      onOk={onSaveHandler}
      onCancel={toggleModalOpen}
      styles={{ body: { height: "70vh" } }}
      title={i18next.t("wholesaler_settings")}
    >
      <AgGridReact
        ref={gridRef}
        onCellValueChanged={() => setIsSaveDisabled(false)}
        getRowId={(
          params: GetRowIdParams<BSCoreServicesDtoMarketplaceProductDistributorDto>,
        ) => String(params.data.distributorId)}
        rowData={data}
        domLayout="normal"
        getRowStyle={(params) =>
          !params.data.isSelected && { cursor: "not-allowed", opacity: 0.6 }
        }
        singleClickEdit
        columnDefs={columnDefs}
        {...getGridDefaultProps()}
      />
    </Modal>
  );
};
export default WholesalerSettingModal;
