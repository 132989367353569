import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import Popup from "reactjs-popup";
import ModalContent from "../Modal/ModalContent";
import * as POCStore from "../../store/POCReducer";
import { RouteComponentProps } from "react-router-dom";
import Button from "../Button";
import { EditIcon, DeleteIcon } from "../../svg";
import Cookies from "universal-cookie";
import $ from "jquery";
import * as paginationEndpoints from "../constants/paginationEndpoints/paginationEndpoints";
import * as actions from "components/Utils/actions";
import HeadlineComponent from "../Headline";
import Table from "../Table";
import _ from "lodash";
import * as endpoints from "../constants/endpoints";
import styled from "styled-components";
import { Modal } from "reactstrap";
import ModalPocMapEdit from "../Modal/ModalPocMapEdit";
import loader from "../../images/spinner.gif";
import i18next from "i18next";
const cookies = new Cookies();

class MappingHistoryPOC extends React.Component {
  state = {
    dataPocMappings: [],
    pocMappingCollection: [],
    pocCollection: [],
    pocMappingsSize: null,
    itemsPerPage: 10,
    currentPage: 1,
    isPocMappingsLoading: true,
    pocMappingOrderBy: "depotName",
    pocMappingOrderDirection: "asc",
    selectedPocMapId: "",
    selectedDepo: {},
    pocMappingFilters: {
      depotName: "",
      customerCode: "",
      customerName: "",
      customerAddressOfDispatching: "",
      customerVat: "",
      hermesPocCode: "",
      hermesPocName: "",
      hermesPocStreet: "",
      hermesPocCustomerName: "",
      hermesPocCustomerFiscalCode: "",
      hermesPocStatus: "",
      dateCreated: "",
      searchValue: "",
    },
    isModalOpen: false,

    depots: [],
    depotsLoaded: false,
    showOvarlayLoader: false,
  };

  _isMounted = false;

  constructor(props) {
    super(props);
    localStorage.setItem("selectedCustomer", "");

    // new
    this.getPocMappings = this.getPocMappings.bind(this);
    this.getAllDepo = this.getAllDepo.bind(this);
    this.onSorterClick = this.onSorterClick.bind(this);
    this.paginationUpdated = this.paginationUpdated.bind(this);
    this.emitSelectedIds = this.emitSelectedIds.bind(this);
    this.emitFilterValue = this.emitFilterValue.bind(this);
    this.emitSelectedDepo = this.emitSelectedDepo.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.removeBodyCss = this.removeBodyCss.bind(this);
    this.onFilterPocMapping = _.debounce(this.getPocMappings, 2000);
    this.getRowDataById = this.getRowDataById.bind(this);
    this.emitExportData = this.emitExportData.bind(this);
  }

  openModal() {
    this.setState((state) => ({ ...state, isModalOpen: true }));
    this.removeBodyCss();
  }

  closeModal(shouldUpdate) {
    if (shouldUpdate) {
      this.setState(
        {
          isModalOpen: false,
          selectedPocMapId: "",
        },
        () => {
          this.getPocMappings();
        },
      );
    } else {
      this.setState((state) => ({ ...state, isModalOpen: false }));
    }
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  componentDidMount() {
    this.getAllDepo();

    this._isMounted = true;

    if (this._isMounted) {
      this.getPocMappings();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getPocMappings = () => {
    this.setState({ isPocMappingsLoading: true });
    const url = `${endpoints.POC_MAPPINGS_ENDPOINT}/Page`;

    fetch(url, {
      method: "post",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${cookies.get("rawtoken")}`,
      },
      body: JSON.stringify({
        page: this.state.currentPage - 1,
        pageSize: this.state.itemsPerPage,
        orderBy: this.state.pocMappingOrderBy, // name
        orderDirection: this.state.pocMappingOrderDirection, // asc
        filters: {
          depotName: this.state.pocMappingFilters.depotName,
          customerCode: this.state.pocMappingFilters.customerCode,
          customerName: this.state.pocMappingFilters.customerName,
          customerAddressOfDispatching:
            this.state.pocMappingFilters.customerAddressOfDispatching,
          customerVat: this.state.pocMappingFilters.customerVat,
          hermesPocCode: this.state.pocMappingFilters.hermesPocCode,
          hermesPocName: this.state.pocMappingFilters.hermesPocName,
          hermesPocStreet: this.state.pocMappingFilters.hermesPocStreet,
          hermesPocCustomerName:
            this.state.pocMappingFilters.hermesPocCustomerName,
          hermesPocCustomerFiscalCode:
            this.state.pocMappingFilters.hermesPocCustomerFiscalCode,
          hermesPocStatus: this.state.pocMappingFilters.hermesPocStatus,
          dateCreated: this.state.pocMappingFilters.dateCreated,
          searchValue: this.state.pocMappingFilters.searchValue,
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          pocMappingsSize: res.dataTotalSize,
          dataPocMappings: res.data,
          isPocMappingsLoading: false,
        });
      });
  };

  getAllDepo = () => {
    const url = `${endpoints.MANAGE_DISTRIBUTOR_DEPOTS_ENDPOINT}`;
    fetch(url, {
      method: "get",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${cookies.get("rawtoken")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ depots: res, depotsLoaded: true });
      });
  };

  getMappingsTableSettings = () => {
    return {
      tableColumns: [
        {
          key: "depotName",
          title: i18next.t("depot_name"),
          isFilterable: true,
          sortable: true,
          background: "blue",
        },
        {
          key: "customerCode",
          title: i18next.t("code"),
          isFilterable: true,
          sortable: true,
          background: "blue",
        },
        {
          key: "customerName",
          title: i18next.t("name"),
          isFilterable: true,
          sortable: true,
          background: "blue",
        },
        {
          key: "customerAddressOfDispatching",
          title: i18next.t("address"),
          isFilterable: true,
          sortable: true,
          background: "blue",
        },
        {
          key: "customerVat",
          title: i18next.t("bulstat"),
          isFilterable: true,
          sortable: true,
          background: "blue",
        },
        {
          key: "hermesPocCode",
          title: i18next.t("poc_code"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "hermesPocName",
          title: i18next.t("poc_name"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "hermesPocStreet",
          title: i18next.t("poc_address"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "hermesPocCustomerName",
          title: i18next.t("client"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "hermesPocCustomerFiscalCode",
          title: i18next.t("bulstat"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "hermesPocStatus",
          title: i18next.t("poc_status"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "dateCreated",
          title: i18next.t("date_mapped"),
          isFilterable: true,
          sortable: true,
        },
      ],
      tableCells: [
        "depotName",
        "customerCode",
        "customerName",
        "customerAddressOfDispatching",
        "customerVat",
        "hermesPocCode",
        "hermesPocName",
        "hermesPocStreet",
        "hermesPocCustomerName",
        "hermesPocCustomerFiscalCode",
        "hermesPocStatus",
        "dateCreated",
      ],
      searchSuccess: true,
      // "dataTotalSize":52093,
      dateCreated: "0001-01-01T00:00:00",
      dateUpdated: "0001-01-01T00:00:00",
    };
  };

  onSorterClick(label, direction, type) {
    this.setState(
      {
        pocMappingOrderBy: label,
        pocMappingOrderDirection: direction,
      },
      () => this.getPocMappings(),
    );
  }

  paginationUpdated(itemsPerTable, currentPage, type) {
    this.setState(
      {
        itemsPerPage: itemsPerTable,
        currentPage: currentPage,
      },
      () => this.getPocMappings(),
    );
  }

  getRowDataById = (id) => {
    const collection = this.state.dataPocMappings;
    const indexOf = collection.findIndex((item) => item.id === id);
    return collection[`${indexOf}`];
  };

  emitSelectedIds(array, type) {
    if (array.length === 0) {
      this.setState({
        selectedPocMapId: "",
        pocMappingCollection: [],
      });
    } else {
      const newCollection = [];
      array.map((item) => {
        newCollection.push(this.getRowDataById(item));
      });

      this.setState({
        selectedPocMapId: array[0],
        pocMappingCollection: [array[0]],
        pocCollection: newCollection,
      });
    }
  }

  emitFilterValue(value, key, type) {
    _.set(this.state, `pocMappingFilters.${key}`, value);
    _.set(this.state, "currentPage", 1);

    this.onFilterPocMapping();
  }

  emitSelectedDepo(obj) {
    _.set(this.state, "selectedDepo", obj);
  }

  onFilterPocMapping() {
    this.getPocMappings();
  }

  deleteMapping = () => {
    const url = `${endpoints.POC_MAPPINGS_ENDPOINT}/${this.state.selectedPocMapId}`;

    if (window.confirm(i18next.t("confirm_delete"))) {
      fetch(url, {
        method: "delete",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${cookies.get("rawtoken")}`,
        },
      }).then((res) => {
        if (res.status === 200) {
          this.getPocMappings();
        }
      });
    }
  };

  emitExportData() {
    const url = `${endpoints.POC_MAPPINGS_ENDPOINT}/exportExcel`;
    this.setState({ showOvarlayLoader: true });

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${cookies.get("rawtoken")}`,
      },
      body: JSON.stringify({
        depotName: this.state.pocMappingFilters.depotName,
        customerCode: this.state.pocMappingFilters.customerCode,
        customerName: this.state.pocMappingFilters.customerName,
        customerAddressOfDispatching:
          this.state.pocMappingFilters.customerAddressOfDispatching,
        customerVat: this.state.pocMappingFilters.customerVat,
        hermesPocCode: this.state.pocMappingFilters.hermesPocCode,
        hermesPocName: this.state.pocMappingFilters.hermesPocName,
        hermesPocStreet: this.state.pocMappingFilters.hermesPocStreet,
        hermesPocCustomerName:
          this.state.pocMappingFilters.hermesPocCustomerName,
        hermesPocCustomerFiscalCode:
          this.state.pocMappingFilters.hermesPocCustomerFiscalCode,
        hermesPocStatus: this.state.pocMappingFilters.hermesPocStatus,
        dateCreated: this.state.pocMappingFilters.dateCreated,
        searchValue: this.state.pocMappingFilters.searchValue,
      }),
    })
      .then((res) => res.blob())
      .then((blob) => {
        this.setState({ showOvarlayLoader: false });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = `poc_mappings.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  public render() {
    const PageWrapper = styled.div`
      button.disabled {
        opacity: 0.6;
        pointer-events: none;
      }
      .search {
        padding-top: 3px;
      }

      .ovarlay-loader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.15);
        z-index: 12;
      }
    `;
    return (
      <PageWrapper>
        <div id="poc-mapping-history-container">
          <HeadlineComponent disableMargin={false}>
            {i18next.t("edit_poc_mapping")}
          </HeadlineComponent>

          {this.state.depotsLoaded && (
            <Table
              type="poc_mappings"
              title={i18next.t("poc_mappings")}
              tableSettings={this.getMappingsTableSettings()}
              data={this.state.dataPocMappings}
              onSorterClick={this.onSorterClick}
              allowMultipleSelection={false}
              paginationUpdated={this.paginationUpdated}
              totalItems={
                this.state.pocMappingsSize ? this.state.pocMappingsSize : 0
              }
              currentPage={this.state.currentPage}
              itemsPerPage={this.state.itemsPerPage}
              isLoading={this.state.isPocMappingsLoading}
              emitSelectedIds={this.emitSelectedIds}
              emitFilterValue={this.emitFilterValue}
              emitSelectedDepo={this.emitSelectedDepo}
              filters={this.state.pocMappingFilters}
              selectedRows={this.state.pocMappingCollection}
              selectedDepo={this.state.selectedDepo}
              showMainAutocomplete={true}
              currentSelectedFilter={this.state.pocMappingOrderBy}
              currentSortingDirection={this.state.pocMappingOrderDirection}
              depots={this.state.depots}
              depotsLoaded={this.state.depotsLoaded}
              emitExportData={this.emitExportData}
            />
          )}

          <div className="actions d-flex justify-content-end pt-4 pb-4">
            <Button
              id="edit-map-btn"
              icon={EditIcon}
              size="large"
              variant="outlined"
              className={
                this.state.selectedPocMapId !== "" ? "active" : "disabled"
              }
              onClick={this.openModal}
            >
              {i18next.t("edit_mapping")}
            </Button>

            <Button
              id="delete-map-btn"
              icon={DeleteIcon}
              variant="outlined"
              size="large"
              color="danger"
              onClick={this.deleteMapping}
              className={`
								ml-3
								${this.state.selectedPocMapId !== "" ? "active" : "disabled"}
								`}
            >
              {i18next.t("delete_mapping")}
            </Button>
          </div>

          {this.state.selectedPocMapId && this.state.isModalOpen && (
            <ModalPocMapEdit
              selectedPocMapId={this.state.selectedPocMapId}
              collection={this.state.pocCollection}
              isOpen={this.state.isModalOpen}
              closeModal={this.closeModal}
              selectedRows={this.state.pocCollection}
            />
          )}
        </div>

        {this.state.showOvarlayLoader === true && (
          <div
            className="d-flex align-items-center justify-content-center ovarlay-loader"
            style={{ minHeight: "300px" }}
          >
            <div className="text-center">
              {i18next.t("downloading")} <br />
              <img src={loader} alt="" />
            </div>
          </div>
        )}
      </PageWrapper>
    );
  }
}
export default connect(
  (state: ApplicationState) => state.poc,
  POCStore.actionCreators,
)(MappingHistoryPOC);
