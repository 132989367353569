import React, { FunctionComponent, ReactNode, useState  } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { connect, Provider } from 'react-redux';
import styled from 'styled-components'
import { range, throttle, set } from 'lodash';

import * as endpoints from './constants/endpoints'
import Table from './Table';
import _ from 'lodash';
import DatePicker from "react-datepicker";
import i18next from "i18next";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

interface T {
    label: string
}

interface Props {
    currentDate?: any,
    dateType?: any,
    emitDate?: any
}

class TableWrapper extends React.Component<Props>{
    constructor(props) {
        super(props);
    }

    state = {
        date: null,
        currentDate: null
    }

    handleDateSelect = (value) => {
        console.log(value)
        this.setState({date: value}, () => this.props.emitDate(this.state.date, this.props.dateType))
    }
    handleDateChange = (value) => {
          console.log(value)
    }

    componentDidMount() {
        if ( this.props.currentDate ) {
            console.log(this.props.currentDate)
         //this.setState({date: this.props.currentDate})
         this.setState({currentDate: this.props.currentDate})
         // mm/dd/yyyy
              //this.setState({date: 'Wed Aug 25 2021 00:00:00 GMT+0300 (Eastern European Summer Time)'})
        }
    }

    public render() {
        return (
            <React.Fragment>
                <div className="datepickerWrap">
                    <DatePicker
                        placeholderText={i18next.t('placeholder_selectDate')}
                        selected={this.state.date}
                        onSelect={this.handleDateSelect} //when day is clicked
                        onChange={this.handleDateChange} //only when value has changed
                        dateFormat="dd/MM/yyyy"
                    />

                    {this.props.dateType === 'dateFrom' && (
                        <p>
                            { this.state.currentDate === null ? '- ∞' : new Date(this.state.currentDate).toLocaleDateString("en-GB")}

                        </p>

                    )}

                    {this.props.dateType === 'dateTo' && (
                        <p>
                            { this.state.currentDate === null ? '+ ∞' : new Date(this.state.currentDate).toLocaleDateString("en-GB") }
                        </p>
                    )}

                </div>
            </React.Fragment>
        )
    }
}
export default connect (

)(TableWrapper);
