import { checkHttpStatus, parseJSON, DEFAULT_HEADERS } from '../Utils/actions';
import Cookies from 'universal-cookie';
import * as endpoints from '../constants/endpoints'

const cookies = new Cookies();

export function getDistributorsAction(callback, error) {
    return fetch(endpoints.MANAGE_DISTRIBUTORS_ENDPOINT, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
}

export function editDistributor(editedDistributor, callback, error) {
    var url = endpoints.MANAGE_DISTRIBUTORS_ENDPOINT;
    url += editedDistributor.id;

    return fetch(url, {
        method: 'put',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`},
        body: JSON.stringify(editedDistributor)
    })
        .then(checkHttpStatus)
        .then(response => {
            try {
                callback();
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
}

export function addDistributor(newDistributor, callback, error) {
    var url = endpoints.MANAGE_DISTRIBUTORS_ENDPOINT;

    return fetch(url, {
        method: 'post',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`},
        body: JSON.stringify(newDistributor)
    })
        .then(checkHttpStatus)
        .then(response => {
            try {
                callback();
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
} 

export function deleteDistributor(distributorId, callback, error) {
    var url = endpoints.MANAGE_DISTRIBUTORS_ENDPOINT;
    url += distributorId;

    return fetch(url, {
        method: 'delete',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(response => {
            try {
                callback();
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
} 

export function deleteDepot(depotId, callback, error) {
    var url = endpoints.MANAGE_DISTRIBUTOR_DEPOTS_ENDPOINT;
    url += depotId;

    return fetch(url, {
        method: 'delete',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(response => {
            try {
                callback();
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
} 

function performGetAction(url, error, callback, data, type) {
    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                data[type] = response;
                callback(data);
            } catch (e) {
                console.log(e)
                var errorMsg = 'could not load ' + type;
                callback(data, errorMsg);
            }
        })
        .catch(e => {
            console.log(e)
            var errorMsg = 'could not load ' + type;
            callback(data, errorMsg);
        })
}
export function attemptEdit(selectedDistributorId, callback, error) {
    var data = {};
    //TODO LOAD SUPPLIERS
    //load data for editing distributor
    if (selectedDistributorId) {
        var suppliers = (data) => performGetAction(endpoints.MANAGE_HERMES_SUPPLIERS_ENDPOINT, error, callback, data, 'suppliers');
        var selectedDistributor = performGetAction(endpoints.MANAGE_DISTRIBUTORS_ENDPOINT + selectedDistributorId, error, suppliers, data, 'editedDistributor');
    }
    //load data for creating distributor
    else {
        //TODO LOAD SUPPLIERS
        var suppliers = performGetAction(endpoints.MANAGE_HERMES_SUPPLIERS_ENDPOINT, error, callback, data, 'suppliers');
    }
} 

export function attemptEditDistributor(selectedDistributorId, callback, error) {
    var url = endpoints.MANAGE_DISTRIBUTORS_ENDPOINT;
    url += selectedDistributorId;

    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                console.log(e)
                error();
            }
        })
        .catch(e => {
            console.log(e)
            error();
        })
} 