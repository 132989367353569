import React, { FunctionComponent, ReactNode, useState  } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { connect, Provider } from 'react-redux';
import styled from 'styled-components'
import { range, throttle, set } from 'lodash';

import * as endpoints from './constants/endpoints'
import Table from './Table';
import _ from 'lodash';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

interface T {
    label: string
}

interface Props {
    title?: any,
    tableSettings?: any,
    selectedRows?: any,
    emitHermesId?: any,
    distributorCustomerIds?: any

}

class TableWrapper extends React.Component<Props>{
    constructor(props) {
        super(props);

        this.getHermesData = this.getHermesData.bind(this);
        this.onSorterClick = this.onSorterClick.bind(this);
        this.paginationUpdated = this.paginationUpdated.bind(this);
        this.emitSelectedIds = this.emitSelectedIds.bind(this);
        this.emitFilterValue = this.emitFilterValue.bind(this);
        this.onFilterHermes = _.debounce(this.getHermesData , 2000);
        this.emitExportData = this.emitExportData.bind(this)
    }

    state = {
        dataHermes: [],
        hermesCollectionIds: [],
        hermesCollection: [],
        hermesTotalSize: null,
        hermesItemsPerPage: 10,
        hermesCurrentPage: 1,
        isHermesLoading: true,
        hermesOrderBy: 'rank', // rank
        hermesOrderDirection: 'desc', // desc
        selectedHermesId: '',
        hermesFilters: {
            hermesId: '',
            pocName: '',
            pocStatus: '',
            city: '',
            street: '',
            houseNumber: '',
            hermesCustomerName: '',
            customerFiscalCode: '',
            rank: '',
            searchValue: ''
        },
        hasChanged: false,
        shouldReRender: true,
        shouldRender: true,
        showTable: false
    }

    getHermesData = () => {
        this.setState({isHermesLoading: true})

        const url = `${endpoints.HERMES_POC_ENDPOINT}/Page`
        fetch(url, {
            method: 'post',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json-patch+json',
                'Authorization': `Bearer ${cookies.get('rawtoken')}`
            },
            body: JSON.stringify({
                page:this.state.hermesCurrentPage - 1,
                pageSize:this.state.hermesItemsPerPage,
                orderBy: this.state.hermesOrderBy, // pocName rank
                orderDirection: this.state.hermesOrderDirection,// asc desc
                filters: {
                    hermesId: this.state.hermesFilters.hermesId,
                    pocName: this.state.hermesFilters.pocName,
                    pocStatus: this.state.hermesFilters.pocStatus,
                    city: this.state.hermesFilters.city,
                    street: this.state.hermesFilters.street,
                    houseNumber: this.state.hermesFilters.houseNumber,
                    hermesCustomerName: this.state.hermesFilters.hermesCustomerName,
                    customerFiscalCode: this.state.hermesFilters.customerFiscalCode,
                    rank: this.state.hermesFilters.rank,
                    searchValue: this.state.hermesFilters.searchValue
                },
                selectedId: this.state.hermesCollectionIds[0],
                distributorCustomerIds: [this.props.distributorCustomerIds] //[1235965]
            })
            }).then(res => res.json())
              .then(res => {
                  console.log(res)

                  _.set(this.state, 'hermesTotalSize', res.dataTotalSize);
                  _.set(this.state, 'dataHermes', res.data);
                  _.set(this.state, 'isHermesLoading', false);

                  this.setState({
                      isHermesLoading: false,
                      showTable: true
                  })
              });
    }

    onSorterClick(label, direction, type) {
        console.log(label, direction, type );

        this.setState({
            hermesOrderBy: label,
            hermesOrderDirection: direction
        }, () => this.getHermesData())
    }

    paginationUpdated(itemsPerTable, currentPage, type) {
        console.log(itemsPerTable, currentPage, type );

        this.setState({
            hermesItemsPerPage: itemsPerTable,
            hermesCurrentPage: currentPage,
        }, () => this.getHermesData())
    }

    emitSelectedIds(array, type) {
        console.log(array)

        if ( array.length === 0 ) {
            _.set(this.state, 'selectedHermesId', '');
            _.set(this.state, 'hermesCollectionIds', []);
        } else {
            console.log(array)
            _.set(this.state, 'selectedHermesId', array[0]);
            _.set(this.state, 'hermesCollectionIds', [array[0]]);
        }

        this.props.emitHermesId(this.state.selectedHermesId)
    }

    emitFilterValue(value, key, type) {
        console.log(value, key, type)

        _.set(this.state, `hermesFilters.${key}`, value);
        _.set(this.state, 'hermesCurrentPage', 1);

        this.onFilterHermes()
    }

    onFilterPocMapping() {
        this.getHermesData()
    }

    onFilterHermes() {
        this.getHermesData()
    }

    emitExportData() {

        const url = `${endpoints.POC_MAPPINGS_ENDPOINT}/ExportUnmappedExcel`
        console.log(this.state.hermesFilters);

        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json-patch+json',
                'Authorization': `Bearer ${cookies.get('rawtoken')}`
            },
            body: JSON.stringify({
                filters: {
                    hermesId: this.state.hermesFilters.hermesId,
                    pocName: this.state.hermesFilters.pocName,
                    pocStatus: this.state.hermesFilters.pocStatus,
                    city: this.state.hermesFilters.city,
                    street: this.state.hermesFilters.street,
                    houseNumber: this.state.hermesFilters.houseNumber,
                    hermesCustomerName: this.state.hermesFilters.hermesCustomerName,
                    customerFiscalCode: this.state.hermesFilters.customerFiscalCode,
                    rank: this.state.hermesFilters.rank,
                    searchValue: this.state.hermesFilters.searchValue
                }
            })
        })
        .then((res) => res.blob())
        .then((blob) => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = `hermers_data.csv`;
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.log(error);
        })
    }

    componentDidMount() {
        if ( this.props.selectedRows && this.props.selectedRows.length > 0 ) {
              const collectionIds = []

              this.props.selectedRows.map(item => {
                  collectionIds.push(item.hermesPocId)
              })

              this.setState({
                  hermesCollectionIds: collectionIds
              }, () => {
                  console.log('should call fetch')
                  this.getHermesData()
              })
        }

        console.log(this.props.distributorCustomerIds)
    }

    public render() {
        return (
            <React.Fragment>
                {this.state.showTable && (
                    <Table
                        type="hermes"
                        title="Hermes"
                        tableSettings={this.props.tableSettings}
                        data={this.state.dataHermes}
                        onSorterClick={this.onSorterClick}
                        allowMultipleSelection={false}
                        paginationUpdated={this.paginationUpdated}
                        totalItems={this.state.hermesTotalSize ? this.state.hermesTotalSize : 0}
                        currentPage={this.state.hermesCurrentPage}
                        itemsPerPage={this.state.hermesItemsPerPage}
                        isLoading={this.state.isHermesLoading}
                        emitSelectedIds={this.emitSelectedIds}
                        emitFilterValue={this.emitFilterValue}
                        filters={this.state.hermesFilters}
                        selectedRows={this.state.hermesCollectionIds}
                        currentSelectedFilter={this.state.hermesOrderBy}
                        currentSortingDirection={this.state.hermesOrderDirection}
                        shouldReRender={this.state.shouldReRender}
                        emitExportData={this.emitExportData}
                    />
                )}
            </React.Fragment>
        )
    }
}
export default connect (

)(TableWrapper);
