import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as DepotRawDataStore from "../../store/DepotRawDataReducer";
import { RouteComponentProps } from "react-router";
import { Row, Col } from "react-grid-system";
import Box from "../Box";
import Select from "../Select";
import Button from "../Button";
import Loader from "../Loader";
import DateRangePicker from "../DateRangePicker";

import depots from "../../depots.json";
import regions from "../../regions.json";
import salesFiles from "../../salesFiles.json";
import i18next from "i18next";

type DepotRawDataReportsProps = DepotRawDataStore.DepotRawDataState &
  typeof DepotRawDataStore.actionCreators &
  RouteComponentProps<{}>;

class DepotRawData extends React.PureComponent<DepotRawDataReportsProps> {
  state = {
    shouldRender: false,
    selectedRegion: "",
    selectedDepot: "",
    date: [new Date(), new Date()],
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.clearValues();
    this.toggleMapBtnState(true);
  }

  //shouldComponentUpdate(nextProps, nextState) {
  //	if (!nextState.shouldRender) {
  //		return false;
  //	}
  //	return true;
  //}

  onDateChange = (date) => {
    this.setState({
      date: date,
    });
  };

  onChange = (event) => {
    event.preventDefault();
    if (event.target.name === "selectedRegion") {
      var region = event.target.value;
      this.props.setRegion(region);
    } else if (event.target.name === "selectedDepot") {
      var depotId = event.target.value;
      this.props.setDepot(depotId);
      if (
        this.props.selectedDepot &&
        this.props.selectedRegion &&
        this.state.date
      ) {
        this.toggleMapBtnState(false);
      }
    }
  };

  getRawSales = (event) => {
    var depotData = {
      fromDate: this.state.date[0],
      toDate: this.state.date[1],
      depotCode: this.props.selectedDepot,
      reportType: "sales",
    };
    this.props.getSalesDataForDepot(depotData);
  };

  getRawStock = (event) => {
    var depotData = {
      fromDate: this.state.date[0],
      toDate: this.state.date[1],
      depotCode: this.props.selectedDepot,
      reportType: "stock",
    };
    this.props.getStockDataForDepot(depotData);
  };

  toggleMapBtnState = (state) => {
    //edit to set editability
    var salesBtn = document.getElementById("getRawSales"),
      stockBtn = document.getElementById("getRawStock");
    if (salesBtn && stockBtn) {
      (salesBtn as HTMLInputElement).disabled = state;
      (stockBtn as HTMLInputElement).disabled = state;
    }
  };

  resetBtnState() {
    let buttons = document.getElementsByTagName("button");
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].disabled = false;
    }
  }

  public render() {
    const { depotsList, regionsList, isLoading } = this.props;
    var regs = regions
        ? regions.map((region, id) => {
            return {
              value: region.name,
              label: region.name,
            };
          })
        : [],
      depots = depotsList
        ? depotsList.map((depot, id) => {
            return {
              value: depot.depotCode,
              label: depot.depotName,
            };
          })
        : [];

    const BoxContent = styled.div`
      padding: 30px;
    `;

    return (
      <>
        <Box
          title={i18next.t("depot_raw_data")}
          buttons={
            <>
              <Button id="getRawSales" size="large" onClick={this.getRawSales}>
                {i18next.t("sales")}
              </Button>
              <Button id="getRawStock" size="large" onClick={this.getRawStock}>
                {i18next.t("stock")}
              </Button>
            </>
          }
        >
          <BoxContent>
            <Row>
              <Col sm={4}>
                <DateRangePicker
                  label={i18next.t("select_date")}
                  onChange={this.onDateChange}
                  value={this.state.date}
                />
              </Col>

              <Col sm={4}>
                <Select
                  label={i18next.t("region")}
                  name="selectedRegion"
                  value={this.props.selectedRegion}
                  defaultValue={"-1"}
                  onChange={this.onChange}
                  options={regs}
                />
              </Col>

              <Col sm={4}>
                <Select
                  label={i18next.t("selected_depot")}
                  name="selectedDepot"
                  value={this.props.selectedDepot}
                  defaultValue={"-1"}
                  onChange={this.onChange}
                  options={depots}
                />
              </Col>
            </Row>
          </BoxContent>
        </Box>
        <Loader background="semiLight" isLoading={isLoading} />
      </>
    );
  }
}
export default connect(
  (state: ApplicationState) => state.depotRawDataReports,
  DepotRawDataStore.actionCreators,
)(DepotRawData);
