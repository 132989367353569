﻿import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { toast } from "react-toastify";
import * as actions from "../components/Actions/receivedSalesFilesActions";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SalesFilesState {
  isLoading: boolean;
  responseMessage: string;
  regionsList: any;
  depotsList: any;
  salesFilesList: any;
  salesFileEntriesList: any;
  selectedRegion: any;
  selectedDepot: any;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface ReceiveRegions {
  type: "RECEIVE_REGIONS";
  regionsList: any;
}

interface ReceiveDepots {
  type: "RECEIVE_DEPOTS";
  depotsList: any;
  selectedRegion: any;
}

interface SetDepot {
  type: "SET_DEPOT";
  selectedDepot: any;
}

interface ReceiveSalesFiles {
  type: "RECEIVE_SALES_FILES";
  salesFilesList: any;
  selectedDepot: any;
}

interface ReceiveSalesFileEntries {
  type: "RECEIVE_SALES_FILE_ENTRIES";
  salesFileEntriesList: any;
}

interface DownloadReceivedSalesFilesReport {
  type: "DOWNLOAD_RECEIVED_SALES_FILE_REPORT";
}

interface AttemptAction {
  type: "ATTEMPT_ACTION";
}

interface ClearValues {
  type: "CLEAR_VALUES";
}

interface FinishAction {
  type: "FINISH_ACTION";
}

interface ErrorAction {
  type: "ERROR_ACTION";
  responseMessage: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
  | ReceiveRegions
  | ReceiveDepots
  | SetDepot
  | ReceiveSalesFiles
  | ReceiveSalesFileEntries
  | DownloadReceivedSalesFilesReport
  | ClearValues
  | AttemptAction
  | FinishAction
  | ErrorAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  getRegions: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    dispatch({ type: "ATTEMPT_ACTION" });
    const appState = getState();
    if (appState) {
      let callback = (regionsList) => {
          dispatch({ type: "RECEIVE_REGIONS", regionsList: regionsList });
        },
        error = (error) => {
          dispatch({ type: "ERROR_ACTION", responseMessage: error });
          toast.error(error || "Error");
        };
      actions.getRegions(callback, error);
    } else {
      dispatch({ type: "FINISH_ACTION" });
    }
  },
  getReceivedSalesFileEntries:
    (receivedSalesFileId): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState && receivedSalesFileId) {
        let callback = (salesFileEntriesList) => {
            dispatch({
              type: "RECEIVE_SALES_FILE_ENTRIES",
              salesFileEntriesList: salesFileEntriesList,
            });
          },
          error = (error) => {
            dispatch({
              type: "ERROR_ACTION",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response.statusText : "Error");
          };
        actions.getSalesFileEntries(receivedSalesFileId, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  setRegion:
    (region): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState) {
        let callback = (depotsList) => {
            dispatch({
              type: "RECEIVE_DEPOTS",
              selectedRegion: region,
              depotsList: depotsList,
            });
          },
          error = (error) => {
            dispatch({
              type: "ERROR_ACTION",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response.statusText : "Error");
          };
        actions.getDepotsForRegion(region, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  setDepot:
    (depotId, cb): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState && depotId) {
        let callback = (salesFilesList) => {
            dispatch({
              type: "RECEIVE_SALES_FILES",
              salesFilesList: salesFilesList,
              selectedDepot: depotId,
            });
            cb();
          },
          error = (error) => {
            dispatch({
              type: "ERROR_ACTION",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            cb();
            toast.error(error.response ? error.response.statusText : "Error");
          };
        actions.getSalesFilesForDepot(depotId, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  downloadReceivedSalesFileReport:
    (receivedSalesReportId): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState && receivedSalesReportId) {
        let callback = () => {
            dispatch({ type: "DOWNLOAD_RECEIVED_SALES_FILE_REPORT" });
          },
          error = (error) => {
            dispatch({
              type: "ERROR_ACTION",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response.statusText : "Error");
          };
        actions.downloadReceivedSalesFileReport(
          receivedSalesReportId,
          callback,
          error,
        );
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  clearValues: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    dispatch({ type: "ATTEMPT_ACTION" });
    const appState = getState();
    if (appState) {
      dispatch({ type: "CLEAR_VALUES" });
    } else {
      dispatch({ type: "FINISH_ACTION" });
    }
  },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: SalesFilesState = {
  isLoading: false,
  responseMessage: "",
  regionsList: [],
  depotsList: [],
  salesFilesList: [],
  salesFileEntriesList: [],
  selectedRegion: "-1",
  selectedDepot: "-1",
};

export const reducer: Reducer<SalesFilesState> = (
  state: SalesFilesState | undefined,
  incomingAction: Action,
): SalesFilesState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "ATTEMPT_ACTION":
      return {
        ...state,
        isLoading: true,
        responseMessage: "",
      };
    case "FINISH_ACTION":
      return {
        ...state,
        isLoading: false,
      };
    case "RECEIVE_SALES_FILES":
      return {
        ...state,
        salesFilesList: action.salesFilesList,
        selectedDepot: action.selectedDepot,
        isLoading: false,
      };
    case "RECEIVE_SALES_FILE_ENTRIES":
      return {
        ...state,
        salesFileEntriesList: action.salesFileEntriesList,
        isLoading: false,
      };
    case "RECEIVE_DEPOTS":
      return {
        ...state,
        depotsList: action.depotsList,
        selectedRegion: action.selectedRegion,
        isLoading: false,
      };
    case "RECEIVE_REGIONS":
      return {
        ...state,
        regionsList: action.regionsList,
      };
    case "DOWNLOAD_RECEIVED_SALES_FILE_REPORT":
      return {
        ...state,
        isLoading: false,
      };
    case "CLEAR_VALUES":
      return {
        ...state,
        responseMessage: "",
        regionsList: [],
        depotsList: [],
        salesFilesList: [],
        salesFileEntriesList: [],
        selectedRegion: "-1",
        selectedDepot: "-1",
        isLoading: false,
      };
    case "ERROR_ACTION":
      return {
        ...state,
        responseMessage: action.responseMessage,
        isLoading: false,
      };
  }
  return state;
};
