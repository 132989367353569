import React, { FunctionComponent, useState, useRef, useCallback, useEffect } from 'react'
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Toggle = styled.span`
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 18px 0;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    color: ${({ theme, isOpen })=> isOpen ? theme.colors.default : theme.colors.dark};

    svg {
        width: 20px;
        height: 20px;
        display: block;
        fill: ${({ theme })=> theme.colors.dark};
        margin-right: 8px;
    }

    &:hover,
    &.active {
        color: ${({ theme })=> theme.colors.default};

        svg {
            fill: ${({ theme })=> theme.colors.default};
        }
    }
`

const DropdownMenu = styled.div`
    position: absolute;
    left: -15px;
    bottom: 0;
    background: #fff;
    transform: translateY(calc(100% - 10px));
    box-shadow: 0 0 50px 0 rgba(82,63,105, 0.15);
    width: 200px;
    opacity: ${({ isOpen })=> isOpen ? 1 : 0};
    visibility: ${({ isOpen })=> isOpen ? 'visible' : 'hidden'};
`

const Dropdown = styled.ul`
    position: relative;
`

const Item = styled.li`
  position: relative;
  border-bottom: 1px solid #f3f3f3;

  &:last-child {
      border-bottom: 0;
  }

  > a {
    display: block;
    color: ${({ theme })=> theme.colors.dark};
    padding: 10px 15px;
    text-transform: none;
    font-size: 12px;
    font-weight: 500;

    &:first-child {
        padding-top: 15px;
    }

    &:last-child {
        padding-bottom: 15px;
    }

    &:hover {
        background: #f7f8fa;
        color: ${({ theme })=> theme.colors.default};
    }
  }
`

interface T {
    to: string,
    label: string,
  }

interface Props {
  title: string,
  items: Array<T>,
  icon: any,
}


const DropDownComponent: FunctionComponent<Props> = ({ title, items, icon : Icon }) => {
    const ref = useRef(null)
    const [isOpen, setIsOpen] = useState(false)

    const clickListener = useCallback((e: MouseEvent) => {
        if (!(ref.current! as any).contains(e.target)) {
            setIsOpen(false)
          }
        },
        [ref.current],
    )

    useEffect(() => {
        document.addEventListener('click', clickListener)
        return () => {
            document.removeEventListener('click', clickListener)
        }
    }, [])

    return (
        <Dropdown ref={ref}>
            <Toggle {... { isOpen }} onClick={()=> setIsOpen(!isOpen)}>
                {Icon ? <Icon /> : null} {title}
            </Toggle>
            <DropdownMenu {... { isOpen }}>
                {items.map(({ to, label }, index)=> (
                    <Item key={`${label}-${index}`}>
                        <NavLink {... { to }}>{label}</NavLink>
                    </Item>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
}

export default DropDownComponent
