import React from "react";

import { Flex, Modal, Typography } from "antd";
import i18next from "i18next";

import { BSDALMarketplaceEnumsOrderStatus } from "api/reactQuery/querySchemas";
import { OrderFailedIcon, OrderSuccessfulIcon } from "svg";

type Props = {
  isOpen: boolean;
  status:
    | BSDALMarketplaceEnumsOrderStatus.InProgress
    | BSDALMarketplaceEnumsOrderStatus.New;
  onClose: () => void;
};
const OrderInfoModal: React.FC<Props> = ({ isOpen, status, onClose }) => {
  const getModelContent: {
    [BSDALMarketplaceEnumsOrderStatus.New]: JSX.Element;
    [BSDALMarketplaceEnumsOrderStatus.InProgress]: JSX.Element;
  } = {
    [BSDALMarketplaceEnumsOrderStatus.InProgress]: (
      <>
        <Flex justify="center">
          <OrderFailedIcon />
        </Flex>
        <Typography.Title level={3}>
          {i18next.t("order_has_failed")}
        </Typography.Title>
        <Typography.Text>
          {i18next.t("not_enough_availability")}
        </Typography.Text>
      </>
    ),
    [BSDALMarketplaceEnumsOrderStatus.New]: (
      <>
        <Flex justify="center">
          <OrderSuccessfulIcon />
        </Flex>
        <Typography.Title level={3}>
          {i18next.t("order_has_been_successful")}
        </Typography.Title>
      </>
    ),
  };

  return (
    <Modal
      open={isOpen}
      centered
      destroyOnClose
      okButtonProps={{ style: { margin: 0, width: "100%" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      onOk={onClose}
      onCancel={onClose}
      styles={{ body: { justifyContent: "center" } }}
    >
      {getModelContent[status] ||
        getModelContent[BSDALMarketplaceEnumsOrderStatus.InProgress]}
    </Modal>
  );
};
export default OrderInfoModal;
