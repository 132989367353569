import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { toast } from 'react-toastify'
import * as actions from '../components/Actions/actions';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UsersState
{
    isLoading: boolean;
    userList: any;
    userCredentials: any;
    responseMessage: string;
    distributors: any;
    depots: any;
    selectedUser: any;
    selectedRegion: string;
    selectedDepots: any;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface AttemptEditUserAction {
    type: 'ATTEMPT_EDIT_USER_ACTION';
    distributors: any;
    depots: any;
    selectedUser: any;
    selectedDepots: any;
}

interface AttemptAddUserAction {
    type: 'ATTEMPT_ADD_USER_ACTION';
    distributors: any;
}

interface GetUsersAction
{
    type: 'GET_USERS_ACTION';
    userList: any;
}

interface EditUserAction
{
    type: 'EDIT_USER_ACTION';
    responseMessage: string;
    selectedUser: any;
}

interface AddUserAction
{
    type: 'ADD_USER_ACTION';
    responseMessage: string;
}

interface GetDepotsByRegion {
    type: 'GET_DEPOTS_BY_REGION_ACTION';
    depots: any;
    selectedRegion: string;
}

interface SelectDepots {
    type: 'SELECT_DEPOTS',
    selectedDepots: []
}

interface SetUser {
    type: 'SET_USER',
    selectedUser: any
}

interface AttemptAction
{
    type: 'ATTEMPT_ACTION';
}

interface FinishAction {
    type: 'FINISH_ACTION';
}

interface ErrorAction {
    type: 'ERROR_ACTION';
    responseMessage: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = AttemptEditUserAction | SelectDepots | SetUser | AttemptAddUserAction | GetUsersAction | EditUserAction | AddUserAction | GetDepotsByRegion
     | AttemptAction | FinishAction | ErrorAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    attemptEditUser: (userId): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState) {
            let callback = (data) => {
                dispatch({
                    type: 'ATTEMPT_EDIT_USER_ACTION', selectedUser: data.selectedUser, distributors: data.distributors,
                    depots: data.depots, selectedDepots: data.selectedDepots && data.selectedDepots.length ? data.selectedDepots?.map(x => x.id) : []
                })
            },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error })
                    toast.error(error)
                 };
            actions.attemptEdit(userId, callback, error);
        }
        else {
            dispatch({ type: 'FINISH_ACTION' })
        }
    },
    getAction: (type): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState) {
            let callback = (userList) => { dispatch({ type: 'GET_USERS_ACTION', userList: userList }) },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
                    toast.error(error.response ? error.response.statusText : 'Error')
                };
            //TODO get all users from new endpoint
            actions.getAction(type, callback, error);
        }
        else {
            dispatch({ type: 'FINISH_ACTION' })
        }
    },
    editUser: (userCredentials, cb): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState && userCredentials) {
            let callback = () => {
                dispatch({ type: 'EDIT_USER_ACTION', responseMessage: 'Success', selectedUser: userCredentials }); cb();
                toast.success('Success')
            },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
                    toast.error(error.response ? error.response.statusText : 'Error')
                };
            actions.editUser(userCredentials, callback, error);
        }
        else {
            dispatch({ type: 'FINISH_ACTION' })
        }
    },
    addUser: (userCredentials): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState && userCredentials) {
            let callback = () => {
                dispatch({ type: 'ADD_USER_ACTION', responseMessage: 'Success' })
                toast.success('Success')
            },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
                    toast.error(error.response ? error.response.statusText : 'Error')
                };
            actions.addUser(userCredentials, callback, error);
        }
        else {
            dispatch({ type: 'FINISH_ACTION' })
        }
    },
    deleteUser: (userId, cb): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState) {
            let callback = () => {
                dispatch({ type: 'ADD_USER_ACTION', responseMessage: 'Success' }); cb();
                toast.success('Success')
            },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
                    toast.error(error.response ? error.response.statusText : 'Error')
                };
            actions.deleteUser(userId, callback, error);
        }
        else {
            dispatch({ type: 'FINISH_ACTION' })
        }
    },
    attemptAddUser: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState) {
            let callback = (distributors) => { dispatch({ type: 'ATTEMPT_ADD_USER_ACTION', distributors: distributors }) },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error })
                    toast.error(error)
                 };
            actions.attemptAdd(callback, error);
        }
        else {
            dispatch({ type: 'FINISH_ACTION' })
        }
    },
    getDepotsByRegion: (region?): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState) {
            let callback = (depots) => { dispatch({ type: 'GET_DEPOTS_BY_REGION_ACTION', depots: depots, selectedRegion: region }) },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error })
                    toast.error(error)
                 };
            actions.getDepotsByRegion(region, error, callback, null, null);
        }
        else {
            dispatch({ type: 'FINISH_ACTION' })
        }
    },
    selectDepots: (selectedDepots?): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'SELECT_DEPOTS', selectedDepots: selectedDepots})
        }
        else {
            dispatch({ type: 'FINISH_ACTION' })
        }
    },
    setUser: (selectedUser): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'SET_USER', selectedUser: selectedUser })
        }
        else {
            dispatch({ type: 'FINISH_ACTION' })
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: UsersState = {
    isLoading: false,
    userList: [],
    userCredentials: {},
    responseMessage: '',
    selectedUser: {},
    distributors: [],
    depots: [],
    selectedRegion: '',
    selectedDepots: []
};

export const reducer: Reducer<UsersState> = (state: UsersState | undefined, incomingAction: Action): UsersState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ATTEMPT_ACTION':
            return {
                ...state,
                isLoading: true,
                responseMessage: ''
            };
        case 'FINISH_ACTION':
            return {
                ...state,
                isLoading: false
            };
        case 'ATTEMPT_EDIT_USER_ACTION':
            return {
                ...state,
                distributors: action.distributors,
                depots: action.depots,
                selectedUser: action.selectedUser,
                selectedDepots: action.selectedDepots,
                isLoading: false
            };
        case 'ATTEMPT_ADD_USER_ACTION':
            return {
                ...state,
                distributors: action.distributors,
                selectedDepots: [],
                isLoading: false
            };
        case 'GET_USERS_ACTION':
                return {
                    ...state,
                    userList: action.userList,
                    isLoading: false
            };
        case 'SELECT_DEPOTS':
            return {
                ...state,
                selectedDepots: action.selectedDepots
            };
        case 'SET_USER':
            return {
                ...state,
                selectedUser: action.selectedUser
            };
        case 'EDIT_USER_ACTION':
            return {
                ...state,
                isLoading: false,
                selectedUser: action.selectedUser,
                responseMessage: action.responseMessage
            };
        case 'ADD_USER_ACTION':
            return {
                ...state,
                isLoading: false,
                responseMessage: action.responseMessage
            };
        case 'GET_DEPOTS_BY_REGION_ACTION':
            return {
                ...state,
                depots: action.depots,
                selectedRegion: action.selectedRegion,
                isLoading: false
            };
        case 'ERROR_ACTION':
            return {
                ...state,
                responseMessage: action.responseMessage,
                isLoading: false
            }
    }
    return state;
};
