import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Loader } from '../svg'

const LoadingOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme, background })=> theme.colors[background]};
  opacity: 0.7;
  z-index: 4;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    animation: rotate 1s linear infinite;
    transform-origin: center center;

    ${({ theme }) => `
      @media ${theme.mediaQueries.xsOnly} {
        width: 100px;
        height: 100px;
      }

      @media ${theme.mediaQueries.smUp} {
        width: 160px;
        height: 160px;
      }
    `}
  }

  svg .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, strokeColorLoader 4s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
    }

  @keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }

  @keyframes strokeColorLoader {
    100%,
    0% {
      stroke: ${({ theme })=> theme.colors.default};
    }
    40% {
      stroke: ${({ theme })=> theme.colors.secondary};
    }
    66% {
      stroke: ${({ theme })=> theme.colors.default};
    }
    80%,
    90% {
      stroke: ${({ theme })=> theme.colors.secondary};
    }
  }
`

interface Props {
  isLoading: boolean,
  background?: 'light' | 'semiLight',
}

const LoaderComponent: FunctionComponent<Props> = ({ isLoading = false, background = 'light' }) => (
  isLoading ? (
    <LoadingOverlay {... { background }}>
        <Loader />
    </LoadingOverlay>
  ) : null
)

export default LoaderComponent
