import React from 'react'

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 405.272 405.272"
      // width="405.272"
      // height="405.272"
      enableBackground="new 0 0 405.272 405.272"
      xmlSpace="preserve"
      >
        <path d="M393.401 124.425 179.603 338.208c-15.832 15.835-41.514 15.835-57.361 0L11.878 227.836c-15.838-15.835-15.838-41.52 0-57.358 15.841-15.841 41.521-15.841 57.355-.006l81.698 81.699L336.037 67.064c15.841-15.841 41.523-15.829 57.358 0 15.835 15.838 15.835 41.514.006 57.361z"/>
      </svg>
  )
