import React, { useEffect, useState } from "react";

import { PlusOutlined } from "@ant-design/icons";
import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Upload,
  UploadFile,
  FormRule,
} from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import i18next from "i18next";
import { toast } from "react-toastify";

import {
  UpsertProductRequestBody,
  useGetAllPackaging,
  useGetAllPallets,
  useUpsertProduct,
} from "api/reactQuery/queryComponents";
import {
  BSCoreServicesDtoMarketplaceProductDistributorPriceGroupDto,
  BSDALMarketplaceEnumsDistributorPriceGroup,
  BSDALMarketplaceEnumsPalletGroupType,
  BSDALMarketplaceEnumsPalletType,
} from "api/reactQuery/querySchemas";
import { MarketplaceDistributorPriceGroups } from "components/Utils/enums";

type Props = {
  isOpen: boolean;
  toggleModalOpen: () => void;
  onAfterSuccess: () => void;
  product?: UpsertProductRequestBody & { imgUrl?: string };
};
const validateDate = (rule, value) => {
  if (value && value.isBefore(dayjs().startOf("day"))) {
    return Promise.reject(i18next.t("the_date_cannot_be_before_today"));
  }

  return Promise.resolve();
};
const requiredRule: FormRule = { message: "Field required", required: true };
const minNumberRule: FormRule = { min: 0, required: true, type: "number" };
const beforeTodayRule: FormRule = { validator: validateDate };

const CreateEditProductModal: React.FC<Props> = ({
  isOpen,
  toggleModalOpen,
  onAfterSuccess,
  product,
}) => {
  const { data: pallets, isLoading: palletsLoading } = useGetAllPallets({});
  const { data: packages, isLoading: packagingLoading } = useGetAllPackaging(
    {},
  );
  const [fileList, setFileList] = useState<UploadFile[]>();
  const { mutate, isLoading: upsertLoading } = useUpsertProduct({
    onSuccess: () => {
      onAfterSuccess();
      toggleModalOpen();
      toast.success(
        product
          ? i18next.t("product_edited_successfully")
          : i18next.t("product_created_successfully"),
      );
    },
  });
  useEffect(() => {
    setFileList(
      product?.imgUrl
        ? [
            {
              name: "Image",
              status: "done",
              uid: "-1",
              url: product?.imgUrl,
            },
          ]
        : undefined,
    );
  }, [product]);

  const [form] = useForm<UpsertProductRequestBody>();
  const onFinish = (values: UpsertProductRequestBody) => {
    const formattedValues: UpsertProductRequestBody = {
      ...values,
      "Availability.CurrentAvailableUnits":
        values["Availability.CurrentAvailableUnits"] || 0,
      "Availability.ExpiryDate": values["Availability.ExpiryDate"]
        ? dayjs(values["Availability.ExpiryDate"]).format("YYYY-MM-DD")
        : "",
      "Availability.NextAvailableUnits":
        values["Availability.NextAvailableUnits"] || 0,
      "Availability.NextAvailableUnitsDate": values[
        "Availability.NextAvailableUnitsDate"
      ]
        ? dayjs(values["Availability.NextAvailableUnitsDate"]).format(
            "YYYY-MM-DD",
          )
        : "",
      GroupType:
        values.GroupType ?? ("" as BSDALMarketplaceEnumsPalletGroupType),
      Id: product?.Id || 0,
    };
    const formData = new FormData();

    Object.keys(formattedValues).forEach((key) => {
      if (key === "ImageFile" || key === "Prices") return; //skip those as we are appending them differently
      if (typeof formattedValues[key] !== "object")
        formData.append(key, formattedValues[key]);
      else formData.append(key, JSON.stringify(formattedValues[key]));
    });

    values.Prices.forEach((priceObj, index) => {
      formData.append(`Prices[${index}][priceGroup]`, priceObj.priceGroup);
      formData.append(`Prices[${index}][price]`, Number(priceObj.price) as any);
    });

    formData.append(
      "ImageFile",
      // @ts-ignore
      !fileList?.length ? "" : values.ImageFile?.file && values.ImageFile?.file, //send empty if image is deleted, otherwise send new image
    );
    // @ts-ignore
    formData.append("IsImageDeleted", !fileList?.length);
    mutate({
      body: formData as unknown as UpsertProductRequestBody,
      headers: { "Content-Type": "multipart/form-data;" },
    });
  };
  const colSpan = 8;
  const blankInitialPriceValues: BSCoreServicesDtoMarketplaceProductDistributorPriceGroupDto[] =
    Object.values(BSDALMarketplaceEnumsDistributorPriceGroup).map((val, i) => ({
      price: null,
      priceGroup: Object.values(BSDALMarketplaceEnumsDistributorPriceGroup)[i],
    }));
  const initialValues: UpsertProductRequestBody = product
    ? {
        ...product,
        "Availability.ExpiryDate":
          product["Availability.ExpiryDate"] || undefined,
        "Availability.NextAvailableUnitsDate":
          product["Availability.NextAvailableUnitsDate"] || undefined,
        Prices: product?.Prices?.length
          ? product.Prices.sort(
              (a, b) =>
                (a.priceGroup as unknown as number) -
                (b.priceGroup as unknown as number),
            )
          : blankInitialPriceValues,
      }
    : ({
        GroupType: null,
        IsMultiPack: false,
        Prices: blankInitialPriceValues,
      } as UpsertProductRequestBody);

  return (
    <Modal
      open={isOpen}
      centered
      onOk={() => form.submit()}
      onCancel={toggleModalOpen}
      afterOpenChange={(open) => !open && setFileList(undefined)}
      confirmLoading={upsertLoading}
      title={product ? i18next.t("edit_product") : i18next.t("create_product")}
      destroyOnClose
      width="95%"
    >
      <Form
        form={form}
        initialValues={initialValues}
        labelCol={{ span: 8 }}
        preserve={false}
        labelWrap
        disabled={palletsLoading || packagingLoading}
        onFinish={onFinish}
      >
        <Row gutter={24}>
          <Col span={colSpan}>
            <Form.Item<UpsertProductRequestBody>
              label={i18next.t("name")}
              name="Name"
              rules={[requiredRule]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item<UpsertProductRequestBody>
              label={i18next.t("sap_number")}
              name="SAPNumber"
              rules={[requiredRule]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item<UpsertProductRequestBody>
              label={i18next.t("barcode")}
              name="Barcode"
              rules={[requiredRule]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item<UpsertProductRequestBody>
              label={i18next.t("units_in_pallet")}
              name="UnitsInPallet"
              rules={[requiredRule, minNumberRule]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item<UpsertProductRequestBody>
              label={i18next.t("volume_in_hl")}
              name="VolumeInHL"
              rules={[requiredRule, minNumberRule]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item<UpsertProductRequestBody>
              label={i18next.t("weight")}
              name="Weight"
              rules={[requiredRule, minNumberRule]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item<UpsertProductRequestBody>
              label={i18next.t("invoice_price")}
              name="InvoicePrice"
              rules={[requiredRule, minNumberRule]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Form.List name="Prices">
            {(fields) =>
              fields.map(({ key, name, ...restField }) => (
                <Col key={key} span={colSpan}>
                  <Form.Item
                    label={i18next.t("price_for_group_type", {
                      groupType: MarketplaceDistributorPriceGroups[name],
                    })}
                    {...restField}
                    name={[name, "price"]}
                    rules={[requiredRule, minNumberRule]}
                  >
                    <InputNumber style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              ))
            }
          </Form.List>

          <Col span={colSpan}>
            <Form.Item<UpsertProductRequestBody>
              label={i18next.t("sort_index")}
              name="SortIndex"
              rules={[requiredRule]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item<UpsertProductRequestBody>
              label={i18next.t("active")}
              name="IsActive"
              rules={[requiredRule]}
            >
              <Radio.Group>
                <Radio value={true}>{i18next.t("yes")}</Radio>
                <Radio value={false}>{i18next.t("no")}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item<UpsertProductRequestBody>
              label={i18next.t("multipack")}
              name="IsMultiPack"
              rules={[requiredRule]}
            >
              <Radio.Group>
                <Radio value={true}>{i18next.t("yes")}</Radio>
                <Radio value={false}>{i18next.t("no")}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item<UpsertProductRequestBody>
              label={i18next.t("package")}
              name="PackageId"
              rules={[requiredRule]}
            >
              <Select
                optionFilterProp="label"
                options={packages?.map((pkg) => ({
                  label: pkg.name,
                  value: pkg.id,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item<UpsertProductRequestBody>
              label={i18next.t("pallet")}
              name="PalletId"
              rules={[requiredRule]}
            >
              <Select
                optionFilterProp="label"
                options={pallets?.map((pallet) => ({
                  label: Object.values(BSDALMarketplaceEnumsPalletType)[
                    pallet.type
                  ],
                  value: pallet.id,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item<UpsertProductRequestBody>
              label={i18next.t("group_type")}
              name="GroupType"
            >
              <Select
                optionFilterProp="label"
                options={[
                  { label: "N/A", value: null },
                  ...Object.values(BSDALMarketplaceEnumsPalletGroupType).map(
                    (option, index) => ({
                      label: option,
                      value: index,
                    }),
                  ),
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item<UpsertProductRequestBody>
              label={i18next.t("expiry_date")}
              name="Availability.ExpiryDate"
              rules={[beforeTodayRule]}
            >
              <DatePicker
                style={{ width: "100%" }}
                minDate={dayjs()}
                format="DD/MM/YYYY"
              />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item<UpsertProductRequestBody>
              label={i18next.t("current_available_units")}
              name="Availability.CurrentAvailableUnits"
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item<UpsertProductRequestBody>
              label={i18next.t("import_comment")}
              name="Availability.ImportComment"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item<UpsertProductRequestBody>
              label={i18next.t("next_available_units")}
              name="Availability.NextAvailableUnits"
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item<UpsertProductRequestBody>
              label={i18next.t("next_available_units_date")}
              name="Availability.NextAvailableUnitsDate"
              rules={[beforeTodayRule]}
            >
              <DatePicker
                style={{ width: "100%" }}
                minDate={dayjs()}
                format="DD/MM/YYYY"
              />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item<UpsertProductRequestBody> label="Image" name="ImageFile">
              <Upload
                beforeUpload={() => false}
                listType="picture"
                maxCount={1}
                accept="image/png, image/jpeg"
                fileList={fileList}
                onChange={(info) => setFileList(info.fileList)}
                onPreview={() => null}
              >
                <button style={{ background: "none", border: 0 }} type="button">
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>
                    {i18next.t("upload_image")}
                  </div>
                </button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default CreateEditProductModal;
