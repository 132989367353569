import React from "react";

import { Flex, Modal, Typography } from "antd";

type Props = {
  isOpen?: boolean;
  text: string;
  title: string;
  icon: React.ReactElement;
  onClose: () => void;
};

const ErrorModal: React.FC<Props> = ({
  isOpen,
  icon,
  text,
  onClose,
  title,
}) => {
  return (
    <Modal
      open={!!isOpen}
      centered
      destroyOnClose
      okButtonProps={{ style: { margin: 0, width: "100%" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      onOk={onClose}
      onCancel={onClose}
      styles={{ body: { justifyContent: "center" } }}
    >
      <>
        <Flex
          justify="center"
          style={{ color: "palevioletred", fontSize: 100 }}
        >
          {icon}
        </Flex>
        <Typography.Title level={3}>{title}</Typography.Title>
        <Typography.Text>{text}</Typography.Text>
      </>
    </Modal>
  );
};
export default ErrorModal;
