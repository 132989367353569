import React, { FunctionComponent } from 'react'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Field from './Field'

interface Props {
    label?: string,
    id?: string,
    size?: 'small' | 'large',
    // All other props
    [x:string]: any;
  }

const DateRangePickerComponent: FunctionComponent<Props> = ({ label, id, size = 'small', ...restProps }) => (
    <Field {... { id, label, size }}>
        <DateRangePicker {... restProps}/>
    </Field>
  )

export default DateRangePickerComponent
