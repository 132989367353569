import React, { Component } from "react";
import styled from "styled-components";
import { checkHttpStatus, parseJSON } from "components/Utils/actions";
import * as endpoints from "../constants/endpoints";
import loader from "../../images/spinner.gif";
import Button from "../Button";
import {
  downloadReport,
  getReportName,
} from "../Actions/mothlyRawSalesReportActions";
import i18next from "i18next";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: white;
`;
const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  min-height: 300px;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: 12;
  transform: translate(-50%, -50%);
`;

const HeaderContainer = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 24px;
  color: #7a7a7a;

  & > h4 {
    margin-bottom: 20px;
  }
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
`;

const StyledButton = styled.button`
  cursor: pointer;
`;

interface MonthlyRawSalesReportState {
  reportName: string;
  loading: boolean;
  downloading: boolean;
}

class MonthlyRawSalesReport extends Component<{}, MonthlyRawSalesReportState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      reportName: "",
      loading: true,
      downloading: false,
    };
  }

  componentDidMount() {
    getReportName()
      .then(checkHttpStatus)
      .then((response) => {
        response.text().then((reportName) => {
          this.setState({ reportName, loading: false });
        });
      });
  }
  async downloadReport() {
    const { reportName } = this.state;
    this.setState({ downloading: true });
    const response = await downloadReport(reportName);
    const blob = await response.blob();

    // Create an anchor element and set the download attribute to trigger a download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "MonthlyReport.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.setState({ downloading: false });
  }

  render() {
    return (
      <ButtonContainer>
        {this.state.loading ? (
          <LoaderWrapper>
            <p>{i18next.t("loading")}</p>
            <img src={loader} alt="" />
          </LoaderWrapper>
        ) : (
          <HeaderContainer>
            <h4>{this.state.reportName}</h4>
            {this.state.downloading ? (
              <LoaderWrapper>
                <p>{i18next.t("downloading")}</p>
                <img src={loader} alt="" />
              </LoaderWrapper>
            ) : (
              <Button
                id="previewSalesReports"
                size="large"
                color="primary"
                variant="outlined"
                onClick={this.downloadReport.bind(this)}
              >
                {i18next.t("download_report")}
              </Button>
            )}
          </HeaderContainer>
        )}
      </ButtonContainer>
    );
  }
}

export default MonthlyRawSalesReport;
