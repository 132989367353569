import React from "react";
import { Button, Flex, Typography } from "antd";
import i18next from "i18next";

const AccessDeniedPage: React.FC = () => {
  return (
    <Flex vertical align="center">
      <Typography.Title>
        {i18next.t("you_don't_have_access_to_this_application")}
      </Typography.Title>
      <Button
        type="primary"
        onClick={() =>
          window.location.replace(process.env.REACT_APP_DISTRIBUTOR_APP_URL)
        }
      >
        {i18next.t("go_to_distributor_app")}
      </Button>
    </Flex>
  );
};
export default AccessDeniedPage;
