import { css } from 'styled-components'

export default css`
	table {
		text-align: left;
		width: 100%;
        border: 0;

		td,
		th {
			padding: 4px 10px;

			&:first-child {
				padding-left: 20px !important;
			}

			&:last-child {
				padding-right: 20px !important;
			}
		}
		tbody tr {
			transition: background ${({ theme }) => theme.transition.default};

			&:hover {
				background: ${({ theme }) => theme.colors.semiLight};
			}
		}

		thead th {
            border: 0;
			border-bottom: 1px solid ${({ theme }) => theme.colors.border};
			font-weight: 500;
			color: ${({ theme }) => theme.colors.dark};
			font-size: 10px;
            background: #fff;

			.fa {
				margin-left: 15px;
				color: #b9b9b9;
				cursor: pointer;
			}

			${({ hasActions }) => hasActions ? `
				&:last-child {
					text-align: right;
				}
			` : ''}
		}

		tbody tr td {
            border: 0;
			border-top: 1px solid ${({ theme }) => theme.colors.border};
			font-size: 10px;
            line-height: 14px;
			color: ${({ theme }) => theme.colors.dark};
			vertical-align: middle;

			${({ hasActions }) => hasActions ? `
				&:last-child {
					text-align: right;

					> span,
					> div {
						> * {
							margin-left: 10px;

							&:first-child {
								margin-left: 0;
							}
						}
					}
				}
			` : ''}
		}
	}

	.pagination {
		padding: 10px 30px;
		justify-content: flex-end;

		li {
			margin-right: 8px;
			display: inline-block;

			> button,
			> a {
				display: inline-block;
				cursor: pointer;
				border-radius: 3px;
				position: relative;
				min-width: 28px;
			    padding: 8px 10px;
				font-weight: 500;
				background: #f5f5f5;
				text-align: center;
				font-size: 10px;
				color: ${({ theme }) => theme.colors.dark};
				transition: color ${({ theme }) => theme.transition.default};
				line-height: 1;

				&:hover {
					color: ${({ theme }) => theme.colors.default};
				}

				&:hover,
				&:focus {
					outline: none;
				}
			}

			&.btn-next-more {
				> button,
				> a {
					background: transparent;
					min-width: 0;
					padding: 11px 6px;
				}
			}

			&.active {
				> button,
				> a {
					background: ${({ theme }) => theme.colors.default};
					color: #fff;
					cursor: default;
					text-decoration: none;
				}
			}

			&.disabled {
				> button,
				> a {
					cursor: default;
					opacity: 0.6;

					&:hover {
						color: ${({ theme }) => theme.colors.dark};
					}
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
`
