import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path d="M256 7.384L0 109.784v394.832h96v-256h320v256h96V109.784L256 7.384zm224 465.232h-32v-256H64v256H32V131.448l224-89.6 224 89.6v341.168z"></path>
      <path d="M256 376.616h-96v128h224v-224H256v96zm0 96h-64v-64h64v64zm32-160h64v64h-64v-64zm0 96h64v64h-64v-64z"></path>
      <path d="M144 136.616H176V168.616H144z"></path>
      <path d="M208 136.616H304V168.616H208z"></path>
      <path d="M336 136.616H368V168.616H336z"></path>
    </svg>
  );
}

export default Icon;
