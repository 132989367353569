import React from 'react'

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
            xmlSpace="preserve"
      >
      <path d="M500 10C229.4 10 10 229.4 10 500c0 112.7 38.1 216.6 102.1 299.4v7.5c1.6-.5 3.1-1 4.7-1.6C206.6 917.9 344.9 990 500 990c157.6 0 297.8-74.4 387.4-189.9.2.1.3.1.5.2v-.8C951.9 716.6 990 612.7 990 500c0-270.6-219.4-490-490-490zm448.7 469.6H703.8c-1.3-57.1-8.1-115.1-20.8-174.1 70.1-8.9 136.9-23 199.2-41.6 39.1 63.1 63 136.8 66.5 215.7zm-611.8 0c1.4-56.9 8.4-113.9 21.2-171.6 49.3 5.4 100.1 8.3 152.1 8.3 45 0 89.1-2.2 132.1-6.3 12.5 57.1 19.4 113.4 20.8 169.6H336.9zm326.2 40.8c-1.5 62.8-10 125.7-25.4 189.6-41.5-3.8-84.1-5.8-127.5-5.8-50.3 0-99.6 2.7-147.4 7.8-15.7-64.6-24.3-128.1-25.9-191.5h326.2zM858.3 229c-57.9 16.4-119.9 28.8-184.7 36.6-17.4-66.6-42.3-134.6-75.5-204 105.5 23.5 197 84.1 260.2 167.4zM500 50.8c17.6 0 35 1 52.1 3 35.2 74.2 62 145.8 80.5 216.1-39.9 3.6-80.8 5.4-122.4 5.4-48.6 0-96.2-2.5-142.3-7.4 18.5-69.7 45.1-140.7 80-214.2 17.1-1.8 34.5-2.9 52.1-2.9zm-98.1 10.8c-32.8 68.6-57.5 135.7-74.7 201.5-63.9-8.7-124.8-21.9-181.6-39 62.9-80.8 152.9-139.5 256.3-162.5zM121.1 258.7c61.3 19.4 127.2 34.4 196.5 44.2-13.1 59.9-20 118.8-21.4 176.7H51.3c3.6-81.1 28.8-156.6 69.8-220.9zm11.4 499.5c-47.8-67.9-77.2-149.5-81.2-237.8h244.9c1.5 66.7 10.4 132.2 25.8 196.5-66.6 9.3-130.2 23.2-189.5 41.3zm26.5 34.1c54.5-15.6 112.6-27.7 173.4-35.8 17.5 61.5 40.8 122.1 69.4 181.8C305.6 917 221 864.6 159 792.3zm288.9 153.9c-31.6-66.5-56.4-131-74.5-194.3 44.4-4.5 90.2-6.8 136.9-6.8 39.8 0 78.8 1.7 117 5C609 813.9 584 879 552.1 946.2c-17.1 2-34.5 3-52.1 3s-35-1.1-52.1-3zm150.2-7.8c29-60.5 52.6-121.9 70.1-184.3 61.8 7.2 121.1 18.6 176.7 33.6-62.3 74.7-148.5 128.8-246.8 150.7zM871 753.3c-60.4-17.4-124.9-30.6-192.4-38.9 15.1-63.6 23.8-128.2 25.2-194h244.9c-3.9 86.2-32 166-77.7 232.9z" >

      </path>
      </svg>
  )
