import { checkHttpStatus, parseJSON, DEFAULT_HEADERS } from '../Utils/actions';
import Cookies from 'universal-cookie';
import * as endpoints from '../constants/endpoints'

const cookies = new Cookies();

export function editDistributorDepot(editedDistributorDepot, callback, error) {
    var url = endpoints.MANAGE_DISTRIBUTOR_DEPOTS_ENDPOINT;
    url += editedDistributorDepot.id;
    delete editedDistributorDepot.id;

    return fetch(url, {
        method: 'put',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`},
        body: JSON.stringify(editedDistributorDepot)
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
}

export function addDistributorDepot(newDistributorDepot, callback, error) {
    var url = endpoints.MANAGE_DISTRIBUTOR_DEPOTS_ENDPOINT;

    return fetch(url, {
        method: 'post',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`},
        body: JSON.stringify(newDistributorDepot)
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
}

export function deleteDistributorDepot(depotId, callback, error) {
    var url = endpoints.MANAGE_DISTRIBUTOR_DEPOTS_ENDPOINT;
    url += depotId;

    return fetch(url, {
        method: 'delete',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(response => {
            try {
                callback();
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
}

export function deleteDepot(depotId, callback, error) {
    var url = endpoints.MANAGE_DISTRIBUTOR_DEPOTS_ENDPOINT;
    url += depotId;

    return fetch(url, {
        method: 'delete',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(response => {
            try {
                callback();
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
}

export function attemptEditDistributorDepot(selectedDistributorDepotId, callback, error) {
    var url = endpoints.MANAGE_DISTRIBUTOR_DEPOTS_ENDPOINT;
    url += selectedDistributorDepotId;

    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                console.log(e)
                error();
            }
        })
        .catch(e => {
            console.log(e)
            error();
        })
}
