import { checkHttpStatus, parseJSON, DEFAULT_HEADERS } from '../Utils/actions';
import Cookies from 'universal-cookie';
import FileSaver from 'file-saver';
import * as endpoints from '../constants/reportsEndpoints/reportsEndpoints'

const cookies = new Cookies();

export function getRegions(callback, error) {
    //TODO CHANGE URL
    return fetch(endpoints.GET_DEPOTS_REGIONS_ENDPOINT, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
}

export function getDepotsForRegion(region, callback, error) {
    var url = endpoints.GET_DEPOTS_FOR_REGION_ENDPOINT + '?region=' + region;

    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
}

function timeout(ms, promise) {
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            reject(new Error("timeout"))
        }, ms)
        promise.then(resolve, reject)
    })
}

export function getSalesStockDataForDepot(depotData, callback, error) {
    var url = endpoints.GET_SALES_STOCK_DATA_FOR_DEPOT_ENDPOINT,
        fileName = '';

    return timeout(8888888,fetch(url, {
        method: 'post',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`},
        body: JSON.stringify(depotData)
    })
        .then(checkHttpStatus)
        .then(function (response) {
            var x = response.headers
                .get("content-disposition");
            fileName = response.headers
                .get("content-disposition")
                .split(';')[1]
                .split('=')[1];
            return response.blob();
        })).then(function (blob) {
            try {
                FileSaver.saveAs(blob, fileName);
                callback(blob);
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
} 