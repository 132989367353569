import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { toast } from 'react-toastify'
import * as actions from '../components/Actions/ordersActions';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface OrdersState
{
    isLoading: boolean;
    ordersData: any;
    orderDetails: any;
    responseMessage: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface GetOrdersData {
    type: 'GET_ORDERS_DATA_ACTION';
    ordersData: any;
}

interface GetOrderDetails
{
    type: 'GET_ORDER_DETAILS';
    orderDetails: any;
}
interface AttemptAction
{
    type: 'ATTEMPT_ACTION';
}

interface FinishAction {
    type: 'FINISH_ACTION';
}

interface ErrorAction {
    type: 'ERROR_ACTION';
    responseMessage: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = GetOrdersData | GetOrderDetails | AttemptAction |
    FinishAction | ErrorAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    getOrdersData: (isSeen, cb): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState) {
            let callback = (ordersData) => { dispatch({ type: 'GET_ORDERS_DATA_ACTION', ordersData: ordersData }); cb(ordersData); },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
                    toast.error(error.response ? error.response.statusText : 'Error')
                };
            actions.getOrdersData(isSeen === null ? null : isSeen, callback, error);
        }
    },
    getOrderDetails: (orderId): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState) {
            let callback = (orderDetails) => { dispatch({ type: 'GET_ORDER_DETAILS', orderDetails: orderDetails }) },
                error = (error) => { dispatch({
                    type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
                    toast.error(error.response ? error.response.statusText : 'Error')
                };
            actions.getOrderDetails(orderId, callback, error);
        }
    },
    downloadOrder: (orderId): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState && orderId) {
            let callback = (orderDetails) => { dispatch({ type: 'GET_ORDER_DETAILS', orderDetails: orderDetails }) },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
                    toast.error(error.response ? error.response.statusText : 'Error')
                };
            actions.downloadOrder(orderId, callback, error);
        }
        else {
            dispatch({ type: 'FINISH_ACTION' })
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: OrdersState = {
    isLoading: false,
    ordersData: [],
    orderDetails: {},
    responseMessage: ''
};

export const reducer: Reducer<OrdersState> = (state: OrdersState | undefined, incomingAction: Action): OrdersState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ATTEMPT_ACTION':
            return {
                ...state,
                isLoading: true,
                responseMessage: ''
            };
            break;
        case 'FINISH_ACTION':
            return {
                ...state,
                isLoading: false
            };
            break;
        case 'GET_ORDERS_DATA_ACTION':
            return {
                ...state,
                ordersData: action.ordersData,
                isLoading: false
            };
            break;
        case 'GET_ORDER_DETAILS':
                return {
                    ...state,
                    orderDetails: action.orderDetails,
                    isLoading: false
                };
            break;
        case 'ERROR_ACTION':
            return {
                ...state,
                responseMessage: action.responseMessage,
                isLoading: false
            }
            break;
    }

    return state;
};
