import { checkHttpStatus, parseJSON, DEFAULT_HEADERS } from '../Utils/actions';
import * as endpoints from '../constants/endpoints'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function loadAllCommentsForTypeAndId(type, id, callback, error) {
    var url = type === 'sku' ? endpoints.MANAGE_PRODUCT_COMMENTS_ENDPOINT : endpoints.MANAGE_CUSTOMER_COMMENTS_ENDPOINT;
    url += '?id=' + id;
    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
}

export function addNewComment(newComment, callback, error) {
    var url = endpoints.MANAGE_COMMENTS_ENDPOINT;

    return fetch(url, {
        method: 'post',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`},
        body: JSON.stringify(newComment)
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
} 
