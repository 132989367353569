import * as React from 'react';
import styled from 'styled-components';
import FilterableTableContainer from '../Helpers/FilterableTableContainer';
import Form from '../Form'
import Button from '../Button'
import $ from 'jquery';
import { DeleteIcon, CloseIcon, AddIcon } from '../../svg'
import { Row, Col } from 'react-grid-system';
import Loader from '../Loader'
import Box from '../Box'
import i18next from "i18next";

const formatDate = (date) => {
	var d = new Date(date),
		month = '' + (d.getMonth() + 1),
		day = '' + d.getDate(),
		year = d.getFullYear();
	if (isNaN(d))
		return date;
	if (month.length < 2)
		month = '0' + month;
	if (day.length < 2)
		day = '0' + day;

	return [year, month, day].join('-');
}

class ModalContent extends React.Component {

	state = {
	}

	componentDidMount() {
		var ref = this;

		window.requestAnimationFrame(function () {
			$("input[type='date']").on("change", function () {
				this.setAttribute(
					"data-date",
					formatDate(this.value ? this.value : this.getAttribute(
						"data-date"))
				)
			}).trigger("change")
			if (ref.props.clickableTrEvent) {
				var table = document.getElementsByClassName('kamenitza-history-customers')[0];
				ref.props.clickableTrEvent(table);
			}
		})

		if (this.props.getMappingHistory) {
			let mappingHistoryCallback = (mappingHistoryTableProps) => {
				this.setState({
					mappingHistoryTableProps: mappingHistoryTableProps
				})
			}
			this.props.getMappingHistory(mappingHistoryCallback);
		}
	}

	render() {
		const Buttons = styled.div`
			display: flex;
			justify-content: center;

			> * {
				margin: 0 10px;
			}
		`

		const DeleteTitle = styled.div`
			font-size: 20px;
			line-height: 1.2;
			text-align: center;
			margin-bottom: 30px;
		`

		const DeleteModal = styled.div`
			padding: 30px;

			${({ theme }) => `
				@media ${theme.mediaQueries.smUp} {
					width: 400px;
				}
			`}
		`

		const LargeModal = styled.div`
			width: 95vw;
			padding: ${({ disablePadding }) => disablePadding ? 0 : '40px'};
			overflow-y: auto;
			max-height: 90vh;
			border-radius: 3px;
		`

		const TableWrapper = styled.div`
			position: relative;
		`

		const CloseButton = styled.div`
			position: absolute;
			right: 0;
			font-size: 40px;
			top: -38px;
			text-shadow: none;
			color: #fff !important;
			opacity: 1;
			cursor: pointer;
		`

		const FormWrapper = styled.div`
			margin-top: 10px;
			margin-bottom: 20px;
		`

		const Table = styled.table`
			text-align: left;
			width: 100%;

			thead th {
				font-size: 14px;
				font-weight: 500;
				color: ${({ theme }) => theme.colors.dark};
			}

			&:not(.table-bordered) thead td,
			&:not(.table-bordered) thead th {
				border-top: 0;
			}

			td,
			th {
				padding: .75rem;
				vertical-align: top;
				border-top: 1px solid #ebedf2;
			}

			tbody tr:nth-of-type(odd) {
				background-color: #f7f8fa;
			}
		`
		const SelectedProductsHeader = styled.header
			`white-space: pre-line;
    color: #6d6d6d;
    font-weight: 500;
    line-height: 1.3;
    -webkit-letter-spacing: 0.5px;
    -moz-letter-spacing: 0.5px;
    -ms-letter-spacing: 0.5px;
    letter-spacing: 0.5px;
    margin: 0 !important;`

		const SelectedProductsContainer = styled.div
			`overflow: auto;`

		const BoxForm = styled.div
			`padding: 12px 20px;
    border-bottom: 1px solid #ebedf2;`

		var modalClick = (event) => {
			event.nativeEvent.stopImmediatePropagation();
		}

		if (this.props.getMappingHistory) {

			var mappingHistoryTableProps = this.state.mappingHistoryTableProps;

			return (
				<div>
					<CloseButton onClick={this.props.close}>&times;</CloseButton>
					<LargeModal onClick={modalClick} {... { disablePadding }}>
						{mappingHistoryTableProps ?
							(<TableWrapper><FilterableTableContainer tableProps={mappingHistoryTableProps} /></TableWrapper>)
							:
							(<Loader background='semiLight' isLoading={true} />)
						}
					</LargeModal>
				</div>)

		}

		if (this.props.type === 'confirmDeletionModal') {
			return (
				<DeleteModal>
					<DeleteTitle>Are you sure you want to delete these items?</DeleteTitle>
					<Buttons>
						<Button size='large' icon={CloseIcon} onClick={this.props.close}>Cancel</Button>
						<Button size='large' icon={DeleteIcon} color='danger' onClick={this.props.confirmDelete}>Delete</Button>
					</Buttons>
				</DeleteModal>
			)
		}

		const { pocsTable, disablePadding, onChange, onDateClick } = this.props;
		return (<div>
			<CloseButton onClick={this.props.close}>&times;</CloseButton>

			<LargeModal {... { disablePadding }}>
				{this.props.formSubmit ? (
					<FormWrapper>
						<Form buttonText='Map' buttonIcon={AddIcon} onSubmit={this.props.formSubmit} dateForm>
							{this.props.onChange ? (
								<Row>
									<Col md={6}>
										<input className='form-control' type="date"
											label="From date"
											name="fromDate"
											id="fromDate"
											onChange={onChange}
											onClick={onDateClick}
											data-date={this.props.dateFrom ? this.props.dateFrom : 'From date'}
											defaultValue={this.props.dateFrom}
										/>
									</Col>
									<Col md={6}>
										<input className='form-control' type="date"
											label="To date"
											name="toDate"
											id="toDate"
											onChange={onChange}
											min={this.props.dateFrom}
											onClick={onDateClick}
											data-date={this.props.dateTo ? this.props.dateTo : 'To date'}
											defaultValue={this.props.dateTo}
										/>
									</Col>
								</Row>
							) : ''}
						</Form>
					</FormWrapper>
				) : ''}
				<Row>
					<Col xs={3}>
						<Box>
							{this.props.selectedMapping && this.props.skuMappings ?
								<SelectedProductsContainer>
									<BoxForm>
										<SelectedProductsHeader>Selected products</SelectedProductsHeader>
									</BoxForm>
									<Table>
										<thead>
											<tr>
												<th>{i18next.t('depot')}</th>
												<th>{i18next.t('code')}</th>
												<th>{i18next.t('description')}</th>
											</tr>
										</thead>
										<tbody>
											{this.props.selectedMapping.data ? this.props.selectedMapping.data.map((sku, id) => {
												return (
													<tr>
														<td>{sku["distributorDepot"] ? sku["distributorDepot"]["name"] : ''}</td>
														<td>{sku["code"]}</td>
														<td>{sku["description"]}</td>
													</tr>
												)
											}) : ''}
										</tbody>
									</Table>
								</SelectedProductsContainer>
								: this.props.selectedMapping && this.props.pocMappings ?
									<SelectedProductsContainer>
										<BoxForm>
											<SelectedProductsHeader>Selected POC mappings</SelectedProductsHeader>
										</BoxForm>
										<Table>
											<thead>
												<tr>
													<th>{i18next.t('depot')}Депо</th>
													<th>{i18next.t('client_code')}</th>
													<th>{i18next.t('client_name')}</th>
													<th>{i18next.t('client_address')}</th>
													<th>{i18next.t('client_vat')}</th>
													<th>{i18next.t('hermes_poc_name')}</th>
													<th>{i18next.t('hermes_poc_client_name')}</th>
													<th>{i18next.t('hermes_poc_code')}</th>
													<th>{i18next.t('hermes_poc_fisc_number')}</th>
													<th>{i18next.t('hermes_poc_address')}</th>
													<th>{i18next.t('hermes_poc_status')}</th>
													<th>{i18next.t('date_mapped')}</th>
												</tr>
											</thead>
											<tbody>
												{this.props.selectedMapping.data ? this.props.selectedMapping.data.map((poc, id) => {
													return (
														<tr>
															<td>{poc["depotName"]}</td>
															<td>{poc["customerCode"]}</td>
															<td>{poc["customerName"]}</td>
															<td>{poc["customerAddressOfDispatching"]}</td>
															<td>{poc["customerVat"]}</td>
															<td>{poc["hermesPocName"]}</td>
															<td>{poc["hermesPocCustomerName"]}</td>
															<td>{poc["hermesPocCode"]}</td>
															<td>{poc["hermesPocCustomerFiscalCode"]}</td>
															<td>{poc["hermesPocStreet"]}</td>
															<td>{poc["hermesPocStatus"]}</td>
															<td>{poc["dateCreated"]}</td>
														</tr>
													)
												}) : ''}
											</tbody>
										</Table>
									</SelectedProductsContainer> : ''}
						</Box>
					</Col>

					<Col xs={9}>
						{pocsTable ? pocsTable() :
							this.props.products ? <TableWrapper><FilterableTableContainer tableProps={this.props.products} /></TableWrapper> : ''}
					</Col>
				</Row>
			</LargeModal>
		</div>
		)
	}
}

export default ModalContent;
