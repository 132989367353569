import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { toast } from "react-toastify";
import * as actions from "../components/Actions/distributorDepotsActions";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface DistributorDepotsState {
  isLoading: boolean;
  selectedDistributorDepot: any;
  responseMessage: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface AttemptEditDistributorDepotAction {
  type: "ATTEMPT_EDIT_DISTRIBUTOR_DEPOT_ACTION";
  selectedDepot: any;
}

interface EditDistributorDepotAction {
  type: "EDIT_DISTRIBUTOR_DEPOT_ACTION";
  responseMessage: string;
  selectedDepot: any;
}

interface AddDistributorDepotAction {
  type: "ADD_DISTRIBUTOR_DEPOT_ACTION";
  responseMessage: string;
}

interface AttemptAction {
  type: "ATTEMPT_ACTION";
}

interface FinishAction {
  type: "FINISH_ACTION";
}

interface ErrorAction {
  type: "ERROR_ACTION";
  responseMessage: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
  | AttemptEditDistributorDepotAction
  | EditDistributorDepotAction
  | AddDistributorDepotAction
  | AttemptAction
  | FinishAction
  | ErrorAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  attemptEditDistributorDepot:
    (depotId): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState && depotId) {
        let callback = (selectedDepot) => {
            dispatch({
              type: "ATTEMPT_EDIT_DISTRIBUTOR_DEPOT_ACTION",
              selectedDepot: selectedDepot,
            });
          },
          error = (error) => {
            dispatch({ type: "ERROR_ACTION", responseMessage: error });
            toast.error(error || "Error");
          };
        actions.attemptEditDistributorDepot(depotId, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  editDistributorDepot:
    (editedDistributorDepot): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState && editedDistributorDepot) {
        let callback = (editedDepot) => {
            dispatch({
              type: "EDIT_DISTRIBUTOR_DEPOT_ACTION",
              responseMessage: "Success",
              selectedDepot: editedDepot,
            });
            toast.success("Success");
          },
          error = (error) => {
            dispatch({
              type: "ERROR_ACTION",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response?.statusText : "Error");
          };
        actions.editDistributorDepot(editedDistributorDepot, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  addDistributorDepot:
    (newDistributorDepot): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState && newDistributorDepot) {
        let callback = (editedDepot) => {
            dispatch({
              type: "ADD_DISTRIBUTOR_DEPOT_ACTION",
              responseMessage: "Success",
            });
            toast.success("Success");
          },
          error = (error) => {
            dispatch({
              type: "ERROR_ACTION",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response?.statusText : "Error");
          };
        actions.addDistributorDepot(newDistributorDepot, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  deleteDistributorDepot:
    (depotId): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState) {
        let callback = () => {
            dispatch({
              type: "ADD_DISTRIBUTOR_DEPOT_ACTION",
              responseMessage: "Success",
            });
            toast.success("Success");
          },
          error = (error) => {
            dispatch({
              type: "ERROR_ACTION",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error?.response?.statusText : "Error");
          };
        actions.deleteDistributorDepot(depotId, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: DistributorDepotsState = {
  isLoading: false,
  selectedDistributorDepot: {},
  responseMessage: "",
};

export const reducer: Reducer<DistributorDepotsState> = (
  state: DistributorDepotsState | undefined,
  incomingAction: Action,
): DistributorDepotsState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "ATTEMPT_ACTION":
      return {
        ...state,
        isLoading: true,
        responseMessage: "",
      };
    case "FINISH_ACTION":
      return {
        ...state,
        isLoading: false,
      };
    case "ATTEMPT_EDIT_DISTRIBUTOR_DEPOT_ACTION":
      return {
        ...state,
        selectedDistributorDepot: action.selectedDepot,
        isLoading: false,
      };
    case "EDIT_DISTRIBUTOR_DEPOT_ACTION":
      return {
        ...state,
        isLoading: false,
        selectedDistributorDepot: action.selectedDepot,
        responseMessage: action.responseMessage,
      };
    case "ADD_DISTRIBUTOR_DEPOT_ACTION":
      return {
        ...state,
        isLoading: false,
        responseMessage: action.responseMessage,
      };
    case "ERROR_ACTION":
      return {
        ...state,
        responseMessage: action.responseMessage,
        isLoading: false,
      };
  }
  return state;
};
