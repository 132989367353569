import React, { FunctionComponent, ReactNode, useState  } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { connect, Provider } from 'react-redux';
import styled from 'styled-components'
import { range, throttle, set } from 'lodash';

import * as endpoints from './constants/endpoints'
import Table from './Table';
import _ from 'lodash';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

interface T {
    label: string
}

interface Props {
    title?: any,
    tableSettings?: any,
    selectedRows?: any,
    // emitHermesId?: any,
    distributorCustomerIds?: any,
    selectedProductId?: any,
    isFreeCase?: any
}

class TableWrapperProductHistory extends React.Component<Props>{
    constructor(props) {
        super(props);

        this.getData = this.getData.bind(this);
        this.onSorterClick = this.onSorterClick.bind(this);
        this.paginationUpdated = this.paginationUpdated.bind(this);
        this.emitSelectedIds = this.emitSelectedIds.bind(this);
        this.emitFilterValue = this.emitFilterValue.bind(this);
        this.onFilter = _.debounce(this.getData , 2000);
        //this.emitExportData = this.emitExportData.bind(this)
    }

    state = {
        data: [],
        hermesCollectionIds: [],
        hermesCollection: [],
        totalSize: null,
        itemsPerPage: 10,
        currentPage: 1,
        isTableLoading: true,
        orderBy: 'rank', // rank
        orderDirection: 'desc', // desc
        selectedHermesId: '',
        filters: {
            hermesId: '',
            pocName: '',
            pocStatus: '',
            city: '',
            street: '',
            houseNumber: '',
            hermesCustomerName: '',
            customerFiscalCode: '',
            rank: '',
            searchValue: ''
        },
        hasChanged: false,
        shouldReRender: true,
        shouldRender: true,
        showTable: false
    }

    getData = () => {
        this.setState({isTableLoading: true})

        const url = `${endpoints.DISTRIBUTOR_PRODUCTS_ENDPOINT}/GetMappingHistory?id=${this.props.selectedProductId}&freeCase=${this.props.isFreeCase}`
        fetch(url, {
            method: 'get',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json-patch+json',
                'Authorization': `Bearer ${cookies.get('rawtoken')}`
            },
        }).then(res => res.json())
          .then(res => {
              const updatedRes = res.map(o => ({ ...o, fromDate: o.fromDate !== null ? new Date(o.fromDate).toLocaleDateString("en-GB") : '', toDate: o.toDate !== null ? new Date(o.toDate).toLocaleDateString("en-GB") : '' }))

              _.set(this.state, 'totalSize', res.length);
              _.set(this.state, 'data', updatedRes);
              _.set(this.state, 'isTableLoading', false);

              this.setState({
                  isTableLoading: false,
                  showTable: true
              })
          });
    }

    onSorterClick(label, direction, type) {
        //console.log(label, direction, type );

        this.setState({
            orderBy: label,
            orderDirection: direction
        }, () => this.getData())
    }

    paginationUpdated(itemsPerTable, currentPage, type) {
        //console.log(itemsPerTable, currentPage, type );

        this.setState({
            itemsPerPage: itemsPerTable,
            currentPage: currentPage,
        }, () => this.getData())
    }

    emitFilterValue(value, key, type) {
        //console.log(value, key, type)

        _.set(this.state, `filters.${key}`, value);
        // this.state, 'hermesCurrentPage', 1);

        this.onFilter()
    }


    onFilter() {
        this.getData()
    }

    emitSelectedIds(array, type) {
        //console.log(array)

        _.set(this.state, 'selectedHermesId', '');
        _.set(this.state, 'hermesCollectionIds', []);
    }



    componentDidMount() {
        this.getData()
    }

    public render() {
        return (
            <React.Fragment>
                {this.state.showTable && (
                    <Table
                        type="product_mapping_history"
                        title="Product Mapping History"
                        tableSettings={this.props.tableSettings}
                        data={this.state.data}
                        onSorterClick={this.onSorterClick}
                        allowMultipleSelection={false}
                        paginationUpdated={this.paginationUpdated}
                        totalItems={this.state.totalSize ? this.state.totalSize : 0}
                        currentPage={this.state.currentPage}
                        itemsPerPage={this.state.itemsPerPage}
                        isLoading={this.state.isTableLoading}
                        emitSelectedIds={this.emitSelectedIds}
                        emitFilterValue={this.emitFilterValue}
                        filters={this.state.filters}
                        selectedRows={this.state.hermesCollectionIds}
                        currentSelectedFilter={this.state.orderBy}
                        currentSortingDirection={this.state.orderDirection}
                        shouldReRender={this.state.shouldReRender}

                    />
                )}
            </React.Fragment>
        )
    }
}
export default connect (

)(TableWrapperProductHistory);
