import React from 'react'

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 422.8 422.8"
      version="1.1"
      viewBox="0 0 422.8 422.8"
      xmlSpace="preserve"
    >
      <path d="M403.2 317.4c-20.4-37.2-64-60.8-65.6-61.2L280 221.4c28.8-21.6 46.4-55.6 46.4-92 0-63.2-51.6-114.8-114.8-114.8S96.8 66.2 96.8 129.4c0 36.4 17.6 70.4 46.4 92L86 255.8c-2 .8-45.6 24.4-66.4 61.6C7.2 339.8 0 369 0 399.4v8.8h422.8v-8.8c0-30.4-6.8-59.6-19.6-82zM211.6 391H18c1.6-32.4 11.2-54.4 17.2-64.8 18-32.4 58.8-54.4 59.6-55.2l82.4-49.6-13.2-7.2c-30.4-17.2-49.6-49.6-49.6-84.8 0-53.6 43.6-97.2 97.2-97.2s97.2 43.6 97.2 97.2c0 34.8-18.8 67.6-49.6 84.8l-13.2 7.2 82.8 50c.4.4 41.2 22 59.2 54.8 6 10.8 15.6 32.4 17.2 64.8H211.6z"></path>
    </svg>
  )
