import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FilterableTableContainer from "../Helpers/FilterableTableContainer";
import { ApplicationState } from "store";
import * as DistributorsStore from "../../store/DistributorsReducer";
import Popup from "reactjs-popup";
import ModalContent from "../Modal/ModalContent";
import Button from "../Button";
import {
  EditIcon,
  CloseIcon,
  CheckIcon,
  DeleteIcon,
  AddIcon,
  DepotIcon,
} from "../../svg";
import * as endpoints from "../constants/endpoints";
import Cookies from "universal-cookie";
import i18next from "i18next";

const cookies = new Cookies();

const ManageTenants: React.FC = () => {
  const distributors = useSelector(
    (state: ApplicationState) => state.distributors,
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(DistributorsStore.actionCreators.getAllDistributors());
  }, [dispatch]);

  const deleteDistributor = (distributorId) => {
    setLoading(true);
    const callback = () => {
      dispatch(DistributorsStore.actionCreators.getAllDistributors());
    };
    dispatch(
      DistributorsStore.actionCreators.deleteDistributor(
        distributorId,
        callback,
      ),
    );
    setLoading(false);
  };

  const toggleDistributorActive = (distributor) => {
    setLoading(true);

    const url = `${endpoints.DISTRIBUTOR_EDIT_IS_ACTIVE}/${
      distributor.id
    }/${!distributor.isActive}`;
    fetch(url, {
      method: "post",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${cookies.get("rawtoken")}`,
      },
    })
      .then((res) => res.json())
      .then(() => {
        dispatch(DistributorsStore.actionCreators.getAllDistributors());
        setLoading(false);
      });
  };

  const editDistributor = (props) => {
    const distributor = props.record,
      editLinkTo = "/edit-distributor/" + distributor.id,
      depotsLinkTo = "/edit-distributor-depots/" + distributor.id;

    return (
      <>
        <Button
          icon={DepotIcon}
          color="primary"
          variant="outlined"
          onClick={() => history.push(depotsLinkTo)}
        >
          {i18next.t("depots")}
        </Button>
        <Button
          icon={EditIcon}
          variant="outlined"
          onClick={() => history.push(editLinkTo)}
        />
        {distributor.isActive ? (
          <Button
            icon={CloseIcon}
            variant="outlined"
            onClick={() => toggleDistributorActive(distributor)}
          />
        ) : (
          <Button
            icon={CheckIcon}
            variant="outlined"
            onClick={() => toggleDistributorActive(distributor)}
          />
        )}
        <Popup
          modal
          trigger={
            <Button icon={DeleteIcon} color="danger" variant="outlined" />
          }
        >
          {(close) => (
            <ModalContent
              close={close}
              type="confirmDeletionModal"
              confirmDelete={() => deleteDistributor(distributor.id)}
            />
          )}
        </Popup>
      </>
    );
  };

  const isActiveCell = ({ record }) => (
    <>
      {record.isActive ? (
        <span>{i18next.t("active")}</span>
      ) : (
        <span>{i18next.t("inactive")}</span>
      )}
    </>
  );

  const { isLoading, distributorsList } = distributors;
  const props = {
    data: distributorsList,
    fields: [
      {
        name: "name",
        displayName: i18next.t("name"),
        inputFilterable: true,
        sortable: true,
      },
      {
        name: "code",
        displayName: i18next.t("rtmid"),
        inputFilterable: true,
        sortable: true,
      },
      {
        name: "officialDistributorName",
        displayName: i18next.t("official_distributor_name"),
        inputFilterable: true,
        sortable: true,
      },
      {
        name: "sapPayerId",
        displayName: i18next.t("sap_payer_id"),
        inputFilterable: true,
        sortable: true,
      },
      {
        name: "isActive",
        displayName: i18next.t("isactive"),
        inputFilterable: true,
        sortable: true,
        render: isActiveCell,
      },
      {
        name: "edit",
        displayName: i18next.t("actions"),
        render: editDistributor,
      },
    ],
    namespace: "People",
    initialSort: "firstName",
    bsLength: 12,
    tableName: i18next.t("distributors"),
  };

  return (
    <FilterableTableContainer
      {...{ isLoading: loading || isLoading }}
      hasActions
      tableProps={props}
      buttons={
        <>
          <Button
            size="large"
            icon={AddIcon}
            onClick={() => history.push("/add-distributor")}
          >
            {i18next.t("new_distributor")}
          </Button>
        </>
      }
    />
  );
};

export default ManageTenants;
