import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../store";
import * as UsersStore from "../../store/UsersReducer";
import "react-dropdown/style.css";
import Cookies from "universal-cookie";
import * as roles from "components/Utils/actions";
import Form from "../Form";
import TextInput from "../TextInput";
import Select from "../Select";
import Button from "../Button";
import $ from "jquery";
import { AddIcon, BackIcon } from "../../svg";
import { Row, Col } from "react-grid-system";
import * as actions from "components/Utils/actions";
import regions from "../../regions.json";
import i18next from "i18next";
const regs = regions
  ? regions.map((region, id) => {
      return region.name;
    })
  : [];

const validator = require("validator");
const cookies = new Cookies();

type UsersProps = UsersStore.UsersState &
  typeof UsersStore.actionCreators &
  RouteComponentProps<{ userId: any }>;

class AddUserForm extends React.PureComponent<UsersProps> {
  constructor(props) {
    super(props);
  }

  public state = {
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    email: "",
    roleId: "",
    distributorId: "",
    tenantId: cookies.get("token")["TenantId"]
      ? cookies.get("token")["TenantId"]
      : null,
    distributorHidden: true,
    depotsTabs: regs,
    // depotsTabs: ['West', 'South', 'East', 'North', 'Sofia'],
    selectedDepots: [],
    depotsHidden: true,
    validated: true,
  };

  componentDidMount() {
    this.props.attemptAddUser();
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    var newUser = this.state,
      role = newUser["roleId"],
      distributor = newUser["distributorId"],
      roleSelect = document.getElementById("roleId"),
      distributorSelect = document.getElementById("distributorId");
    newUser["roleId"] = role
      ? role
      : roleSelect
        ? (roleSelect as HTMLInputElement).value
        : null;
    newUser["distributorId"] = distributor
      ? distributor
      : distributorSelect
        ? (distributorSelect as HTMLInputElement).value
        : null;
    newUser["selectedDepots"] = this.props.selectedDepots;
    this.props.addUser(newUser);
  };

  onChange = (event) => {
    var value = Number(event.target.value);
    var isRoleChange = event.target.name === "roleId";
    if (value === 4 || value === 3) {
      this.props.getDepotsByRegion("West");
    }
    //alert(actions.rolesEnum[Number(value)]);
    this.setState({
      [event.target.name]: event.target.value,
      distributorHidden: isRoleChange
        ? value !== 5
        : this.state.distributorHidden,
      depotsHidden: isRoleChange
        ? !(
            actions.rolesEnum[Number(value)] === "TenantSupervisor" ||
            actions.rolesEnum[Number(value)] === "TenantSupport"
          )
        : true,
    });
  };

  changeCheckbox = (event) => {
    var selectedDepots = this.props.selectedDepots,
      selectedDepotValue = Number(event.target.value);
    if (selectedDepots.includes(selectedDepotValue)) {
      var depotIdx = selectedDepots.indexOf(selectedDepotValue);
      selectedDepots.splice(depotIdx, 1);
    } else {
      selectedDepots.push(selectedDepotValue);
    }
    this.props.selectDepots(selectedDepots);
    this.setState({
      validated: !this.state.validated,
    });
  };

  depotsTabClick = (event) => {
    //function to change depots based on selected tab
    var button =
      event.target.localName === "button"
        ? event.target
        : event.target.offsetParent;
    var selectedTab = event.target.classList.contains("bubble")
      ? event.target.offsetParent.innerText
      : event.target.innerText;
    if (selectedTab) {
      this.props.getDepotsByRegion(selectedTab);
    }
    var activeButton = $(".active");
    activeButton.removeClass("active");
    button.classList.add("active");
    event.preventDefault();
  };

  navigateBack = (event) => {
    this.props.history.push("/users");
  };

  calculateDepotColumns = (depots) => {
    const depotColumnCount = Math.floor(depots.length / 4);
    var depotColumnLeftover = 4 * ((depots.length / 4) % 1);
    var leftover = depotColumnLeftover > 0 ? 1 : 0;
    var inc = depotColumnCount + leftover;
    depotColumnLeftover--;
    leftover = depotColumnLeftover > 0 ? 1 : 0;
    var range = inc + depotColumnCount + leftover;
    var depotColumns = [];
    for (let i = 1; i <= 4; i++) {
      if (i === 1) {
        depotColumns.push(depots.slice(0, inc));
      } else {
        depotColumns.push(depots.slice(inc, range));
        inc += depotColumnCount + leftover;
        depotColumnLeftover--;
        leftover = depotColumnLeftover > 0 ? 1 : 0;
        range += depotColumnCount + leftover;
      }
    }
    return depotColumns;
  };

  renderDepots = (depotColumns) => {
    return (
      <div className="depots-container">
        <>
          <label>Depots</label>
          <div>
            {this.state.depotsTabs.map((location, i) => {
              if (i === 0) {
                return (
                  <Button className="active" onClick={this.depotsTabClick}>
                    {location}
                  </Button>
                );
              }
              return <Button onClick={this.depotsTabClick}>{location}</Button>;
            })}
          </div>
        </>
        <Row>
          {depotColumns.map((depotColumn, i) => {
            return (
              <Col sm={3}>
                <ul className="custom-depots-ul">
                  {depotColumn.map((depot, i) => {
                    if (this.props.selectedDepots.includes(depot.id)) {
                      return (
                        <li className="depotLine" key={depot.id}>
                          <input
                            type="checkbox"
                            checked
                            id={depot.id}
                            value={depot.id}
                            onChange={this.changeCheckbox}
                          />
                          <label htmlFor={depot.id}>{depot.depotName}</label>
                        </li>
                      );
                    } else {
                      return (
                        <li className="depotLine" key={depot.id}>
                          <input
                            type="checkbox"
                            id={depot.id}
                            value={depot.id}
                            onChange={this.changeCheckbox}
                          />
                          <label htmlFor={depot.id}>{depot.depotName}</label>
                        </li>
                      );
                    }
                  })}
                </ul>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  };

  public render() {
    const { isLoading, distributors, depots } = this.props;
    const distributorHidden = this.state.distributorHidden;
    const depotsHidden = this.state.depotsHidden;
    var rolesArray = Object.values(roles.rolesEnum);
    rolesArray.splice(0, 1);
    //TODO check currently logged user for preselected value
    return (
      <Form
        title="Add user"
        hasBox
        buttonText={i18next.t("add_new")}
        buttonIcon={AddIcon}
        buttonJustify="space-between"
        onSubmit={this.handleSubmit}
        additionalButton={
          <Button
            icon={BackIcon}
            size="large"
            variant="outlined"
            onClick={this.navigateBack}
          >
            Back
          </Button>
        }
        {...{ isLoading }}
      >
        <TextInput
          label={i18next.t("first_name")}
          name="firstName"
          id="firstName"
          type="text"
          required
          value={this.state.firstName}
          onChange={this.onChange}
        />

        <TextInput
          label={i18next.t("last_name")}
          name="lastName"
          id="lastName"
          type="text"
          required
          value={this.state.lastName}
          onChange={this.onChange}
        />

        <TextInput
          label={i18next.t("username")}
          name="username"
          id="username"
          required
          value={this.state.username}
          onChange={this.onChange}
        />

        <TextInput
          label={i18next.t("password")}
          name="password"
          id="password"
          required
          pattern="(?=.*[A-Z]).{6,}"
          errorMessage={{
            required: "Password is required",
            pattern:
              "Password should be at least 6 characters and contains at least one upper case letter",
          }}
          value={this.state.password}
          onChange={this.onChange}
        />

        <TextInput
          label={i18next.t("email")}
          name="email"
          id="email"
          type="email"
          required
          value={this.state.email}
          onChange={this.onChange}
          validator={validator.isEmail}
          errorMessage={{ validator: "Please enter a valid email" }}
        />

        <Select
          label="Role"
          options={
            rolesArray
              ? rolesArray.map((key, id) => {
                  return {
                    value: id + 2,
                    label: key,
                  };
                })
              : []
          }
          name="roleId"
          id="roleId"
          value={this.state.roleId}
          onChange={this.onChange}
        />

        {!distributorHidden && (
          <Select
            label={i18next.t("distributor")}
            options={
              distributors
                ? distributors.map(({ id, name }) => {
                    return {
                      value: id,
                      label: name,
                    };
                  })
                : []
            }
            name="distributorId"
            id="distributorId"
            value={this.state.distributorId}
            onChange={this.onChange}
          />
        )}

        {!depotsHidden &&
          depots &&
          this.renderDepots(this.calculateDepotColumns(depots))}
      </Form>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.users,
  UsersStore.actionCreators,
)(AddUserForm);
