import React from "react";

export default () => (
  <svg
    width="208"
    height="208"
    viewBox="0 0 208 208"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="207.688"
      height="207.688"
      transform="translate(0.15625 0.15625)"
      fill="white"
    />
    <path
      d="M37.0996 78.6562V51.1562H178.1V158.156H37.0996V134.656"
      stroke="#4D5152"
      stroke-width="8"
    />
    <path
      d="M49.1562 75.1562L106.656 99.1562L164.156 75.1562V147.156H49.1562V75.1562Z"
      fill="#FED9D9"
    />
    <path d="M54.2362 103.127H27.3828V111.24H54.2362V103.127Z" fill="#4D5152" />
    <path d="M19.2691 103.127H11.1562V111.24H19.2691V103.127Z" fill="#4D5152" />
    <path
      d="M76.2616 86.9014H49.4082V95.0142H76.2616V86.9014Z"
      fill="#4D5152"
    />
    <path
      d="M41.2944 86.9014H33.1816V95.0142H41.2944V86.9014Z"
      fill="#4D5152"
    />
    <path
      d="M76.2616 119.353H49.4082V127.466H76.2616V119.353Z"
      fill="#4D5152"
    />
    <path
      d="M41.2944 119.353H33.1816V127.466H41.2944V119.353Z"
      fill="#4D5152"
    />
    <path
      d="M35.6562 53.6562L106.656 84.6562L178.656 57.1562"
      stroke="#4D5152"
      stroke-width="8"
    />
    <g filter="url(#filter0_d_250_127408)">
      <circle cx="48.6562" cy="155.656" r="28.5" fill="#F5F3F3" />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M33.6562 155.656C33.6562 147.372 40.372 140.656 48.6562 140.656C56.9405 140.656 63.6562 147.372 63.6562 155.656C63.6562 163.941 56.9405 170.656 48.6562 170.656C40.372 170.656 33.6562 163.941 33.6562 155.656ZM48.6562 136.906C38.3009 136.906 29.9062 145.301 29.9062 155.656C29.9062 166.012 38.3009 174.406 48.6562 174.406C59.0116 174.406 67.4062 166.012 67.4062 155.656C67.4062 145.301 59.0116 136.906 48.6562 136.906ZM55.2854 162.285C54.5532 163.018 53.366 163.018 52.6338 162.285L48.6563 158.308L44.6788 162.285C43.9466 163.018 42.7594 163.018 42.0272 162.285C41.2949 161.553 41.2949 160.366 42.0272 159.634L46.0046 155.656L42.0272 151.679C41.2949 150.947 41.2949 149.759 42.0272 149.027C42.7594 148.295 43.9466 148.295 44.6788 149.027L48.6563 153.005L52.6338 149.027C53.366 148.295 54.5532 148.295 55.2854 149.027C56.0177 149.759 56.0177 150.947 55.2854 151.679L51.3079 155.656L55.2854 159.634C56.0177 160.366 56.0177 161.553 55.2854 162.285Z"
      fill="#DB3F3F"
    />
    <defs>
      <filter
        id="filter0_d_250_127408"
        x="17.1562"
        y="127.156"
        width="65"
        height="65"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_250_127408"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_250_127408"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
