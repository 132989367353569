import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ApplicationState } from "store";
import * as DistributorsStore from "store/DistributorsReducer";
import "react-dropdown/style.css";
import FilterableTableContainer from "../Helpers/FilterableTableContainer";
import Popup from "reactjs-popup";
import ModalContent from "../Modal/ModalContent";
import Button from "../Button";
import {
  EditIcon,
  CloseIcon,
  CheckIcon,
  DeleteIcon,
  AddIcon,
  BackIcon,
} from "../../svg";
import * as endpoints from "../constants/endpoints";
import Cookies from "universal-cookie";
import i18next from "i18next";

const cookies = new Cookies();
interface RouteParams {
  distributorId: string; // Assumed 'distributorId' is of type string
}
const EditDistributorDepotsForm = () => {
  const { distributorId } = useParams<RouteParams>();
  const history = useHistory();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    name: "",
    code: "",
    depots: [],
    tenantId: "",
  });

  const distributor = useSelector(
    (state: ApplicationState) => state.distributors.selectedDistributor,
  );
  const isLoading = useSelector(
    (state: ApplicationState) => state.distributors.isLoading,
  );

  useEffect(() => {
    if (distributorId) {
      dispatch(
        DistributorsStore.actionCreators.attemptEditDistributor(distributorId),
      );
    }
  }, [distributorId, dispatch]);

  useEffect(() => {
    if (distributor && distributorId) {
      var depots = distributor.depots;
      var filteredDepots = depots ? depots.filter((el) => el.isActive) : [];

      setState({
        name: distributor.name,
        code: distributor.code,
        depots: depots,
        tenantId: distributor.tenantId,
      });
    }
  }, [distributor, distributorId]);

  const onChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const navigateBack = useCallback(
    () => history.push("/manage-distributors"),
    [history],
  );

  const deleteDepot = useCallback(
    (depotId) => {
      let callback = () =>
        dispatch(
          DistributorsStore.actionCreators.attemptEditDistributor(
            distributorId,
          ),
        );
      dispatch(DistributorsStore.actionCreators.deleteDepot(depotId, callback));
    },
    [dispatch, distributorId],
  );

  const toggleDepotActive = useCallback(
    (depot) => {
      const url = `${endpoints.DEPO_EDIT_IS_ACTIVE}/${
        depot.id
      }/${!depot.isActive}`;

      fetch(url, {
        method: "post",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${cookies.get("rawtoken")}`,
        },
      })
        .then((res) => res.json())
        .then(() => {
          dispatch(
            DistributorsStore.actionCreators.attemptEditDistributor(
              distributorId,
            ),
          );
        });
    },
    [dispatch, distributorId],
  );

  const editDepot = useCallback(
    (record) => {
      let linkTo = `/distributor-depots/distributorId${distributorId}&distributorName${state.name}&tenantId${state.tenantId}/manage-distributor-depot/${record.record.id}`;

      return (
        <>
          <Button
            icon={EditIcon}
            variant="outlined"
            onClick={() => history.push(linkTo)}
          />
          {record.record.isActive ? (
            <Button
              icon={CloseIcon}
              variant="outlined"
              onClick={() => toggleDepotActive(record.record)}
            />
          ) : (
            <Button
              icon={CheckIcon}
              variant="outlined"
              onClick={() => toggleDepotActive(record.record)}
            />
          )}
          <Popup
            modal
            trigger={
              <Button icon={DeleteIcon} color="danger" variant="outlined" />
            }
          >
            {(close) => (
              <ModalContent
                close={close}
                type="confirmDeletionModal"
                confirmDelete={() => deleteDepot(record.id)}
              />
            )}
          </Popup>
        </>
      );
    },
    [
      history,
      state.name,
      state.tenantId,
      toggleDepotActive,
      deleteDepot,
      distributorId,
    ],
  );

  const isActiveCell = useCallback(({ record }) => {
    return (
      <>{record.isActive ? <span>Активен</span> : <span>Неактивен</span>}</>
    );
  }, []);

  const getDepotTableProps = useCallback(() => {
    return {
      data: state.depots || [],
      fields: [
        {
          name: "depotCode",
          displayName: i18next.t("code"),
          inputFilterable: true,
          sortable: true,
        },
        {
          name: "name",
          displayName: i18next.t("name"),
          inputFilterable: true,
          sortable: true,
        },
        {
          name: "isActive",
          displayName: i18next.t("isactive"),
          inputFilterable: true,
          sortable: true,
          render: isActiveCell,
        },
        { name: "edit", displayName: i18next.t("actions"), render: editDepot },
      ],
      tableName: `${state.name}, code ${state.code}`,
      namespace: "Depots",
      initialSort: "firstName",
      bsLength: 12,
    };
  }, [state.depots, state.name, state.code, editDepot, isActiveCell]);

  return (
    <>
      <FilterableTableContainer
        isLoading={isLoading}
        hasActions
        tableProps={getDepotTableProps()}
        buttons={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              size="large"
              variant="outlined"
              onClick={navigateBack}
              icon={BackIcon}
            >
              {i18next.t("back")}
            </Button>
            <Button
              size="large"
              icon={AddIcon}
              onClick={() =>
                history.push(
                  `/distributor-depots/distributorId${distributorId}&distributorName${state.name}&tenantId${state.tenantId}/manage-distributor-depot/`,
                )
              }
            >
              {i18next.t("new_depot")}
            </Button>
          </div>
        }
      />
    </>
  );
};

export default EditDistributorDepotsForm;
