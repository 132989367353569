import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { toast } from "react-toastify";
import * as actions from "../components/Actions/WHSDataReportActions";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface WHSDataReportState {
  isLoading: boolean;
  responseMessage: string;
  regionsList: any;
  monthsList: any;
  yearsList: any;
  depotsList: any;
  selectedRegion: any;
  selectedMonth: any;
  selectedYear: any;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface InitializeSelectData {
  type: "INITIALIZE_SELECT_DATA";
  regionsList: any;
  monthsList: any;
  yearsList: any;
}

interface ReceiveDepots {
  type: "RECEIVE_DEPOTS";
  depotsList: any;
  selectedRegion: any;
  selectedMonth: any;
  selectedYear: any;
}

interface ChangeValue {
  type: "CHANGE_VALUE";
  selectedRegion: any;
  selectedMonth: any;
  selectedYear: any;
}

interface ClearValues {
  type: "CLEAR_VALUES";
}

interface AttemptAction {
  type: "ATTEMPT_ACTION";
}

interface FinishAction {
  type: "FINISH_ACTION";
}

interface ErrorAction {
  type: "ERROR_ACTION";
  responseMessage: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
  | InitializeSelectData
  | ReceiveDepots
  | ChangeValue
  | ClearValues
  | AttemptAction
  | FinishAction
  | ErrorAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  initializeSelectData:
    (): AppThunkAction<KnownAction> => (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState) {
        let callback = (data) => {
            dispatch({
              type: "INITIALIZE_SELECT_DATA",
              regionsList: data.regionsList,
              monthsList: data.monthsList,
              yearsList: data.yearsList,
            });
          },
          error = (error) => {
            dispatch({ type: "ERROR_ACTION", responseMessage: error });
            toast.error(error || "Error");
          };
        actions.initializeSelectData(callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  getDepotsForSelectedData:
    (selectedData, cb): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState && selectedData) {
        let callback = (depotsList) => {
            dispatch({
              type: "RECEIVE_DEPOTS",
              depotsList: depotsList,
              selectedRegion: selectedData.regions[0],
              selectedMonth: selectedData.month,
              selectedYear: selectedData.year,
            });
            if (cb) {
              cb();
            }
          },
          error = (error) => {
            dispatch({
              type: "ERROR_ACTION",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response.statusText : "Error");
          };
        actions.getDepotsForSelectedData(selectedData, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  changeValue:
    (type, value): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState && type && value) {
        dispatch({
          type: "CHANGE_VALUE",
          selectedRegion:
            type === "selectedRegion"
              ? value
              : appState.whsDataReports.selectedRegion,
          selectedMonth:
            type === "selectedMonth"
              ? value
              : appState.whsDataReports.selectedMonth,
          selectedYear:
            type === "selectedYear"
              ? value
              : appState.whsDataReports.selectedYear,
        });
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  clearValues: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    dispatch({ type: "ATTEMPT_ACTION" });
    const appState = getState();
    if (appState) {
      dispatch({ type: "CLEAR_VALUES" });
    } else {
      dispatch({ type: "FINISH_ACTION" });
    }
  },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: WHSDataReportState = {
  isLoading: false,
  responseMessage: "",
  regionsList: [],
  monthsList: [],
  yearsList: [],
  depotsList: [],
  selectedRegion: "North-East",
  selectedMonth: new Date().getMonth() + 1,
  selectedYear: new Date().getFullYear(),
};

export const reducer: Reducer<WHSDataReportState> = (
  state: WHSDataReportState | undefined,
  incomingAction: Action,
): WHSDataReportState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "ATTEMPT_ACTION":
      return {
        ...state,
        isLoading: true,
        responseMessage: "",
      };
    case "FINISH_ACTION":
      return {
        ...state,
        isLoading: false,
      };
    case "INITIALIZE_SELECT_DATA":
      return {
        ...state,
        regionsList: action.regionsList,
        monthsList: action.monthsList,
        yearsList: action.yearsList,
        isLoading: false,
      };
    case "RECEIVE_DEPOTS":
      return {
        ...state,
        depotsList: action.depotsList,
        selectedRegion: action.selectedRegion,
        selectedMonth: action.selectedMonth,
        selectedYear: action.selectedYear,
        isLoading: false,
      };
    case "CHANGE_VALUE":
      return {
        ...state,
        selectedRegion: action.selectedRegion,
        selectedMonth: action.selectedMonth,
        selectedYear: action.selectedYear,
        isLoading: false,
      };
    case "CLEAR_VALUES":
      return {
        ...state,
        responseMessage: "",
        regionsList: [],
        monthsList: [],
        yearsList: [],
        depotsList: [],
        isLoading: false,
      };
    case "ERROR_ACTION":
      return {
        ...state,
        responseMessage: action.responseMessage,
        isLoading: false,
      };
  }
  return state;
};
