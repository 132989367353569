import { ValueFormatterParams } from "ag-grid-community";
import moment from "moment";
import dayjs from "dayjs";
import { dateFormat } from "components/Utils/dayjsUtils";

export const decimalNumberFormatter = (params: ValueFormatterParams) => {
  return params.value.toLocaleString("en", {
    maximumFractionDigits: 4,
    minimumFractionDigits: 0,
    useGrouping: false,
  });
};

export const dateFormatter = (params: ValueFormatterParams) =>
  params.value ? dayjs(params.value).format(dateFormat) : "-";

export const currencyBGNFormatter = (params: ValueFormatterParams) =>
  `${params?.value} лв`;

export const dateTimeFormatter = (params: ValueFormatterParams) =>
  moment(new Date(params.value)).format("YYYY-MM-DD HH:mm:ss");
