import React, { FunctionComponent } from "react";
import Field from "./Field";

interface T {
  value: string | number;
  label: string;
  disabled?: boolean;
}

interface Props {
  label?: string;
  id?: string;
  size?: "small" | "large";
  options: Array<T>;
  disableMargin?: boolean;
  hideLabelOption?: boolean;
  // All other props
  [x: string]: any;
}

const TextInputComponent: FunctionComponent<Props> = ({
  label,
  id,
  size = "small",
  disableMargin,
  options,
  hideLabelOption,
  ...restProps
}) => (
  <Field {...{ id, label, size, disableMargin }}>
    <select {...restProps} {...{ id }}>
      {!hideLabelOption && (
        <option disabled value={-1}>
          Select {label}
        </option>
      )}
      {options.map(({ value, label, ...rest }) => {
        return (
          <option value={value} {...rest}>
            {label}
          </option>
        );
      })}
    </select>
  </Field>
);

export default TextInputComponent;
