import * as React from "react";
import styled from "styled-components";
import Table from "../Table";
import * as endpoints from "../constants/endpoints";
import loader from "../../images/spinner.gif";
import Cookies from "universal-cookie";
import { translateHEStatus } from "helpers/translateEnumValues";
import i18next from "i18next";
import { RouteComponentProps } from "react-router-dom";
import { FC, useEffect, useState } from "react";

const cookies = new Cookies();
const PageWrapper = styled.div`
  button.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  .search {
    padding-top: 3px;
  }

  .ovarlay-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.15);
    z-index: 12;
  }

  #eod-table-container tbody tr td:last-child {
    width: 15%;
  }
`;
const getEODTableSettings = {
  tableColumns: [
    {
      key: "date",
      title: i18next.t("date"),
      isFilterable: false,
      sortable: true,
    },
    {
      key: "type",
      title: i18next.t("type"),
      isFilterable: false,
      sortable: true,
    },
    {
      key: "HE_start",
      title: i18next.t("he_start"),
      isFilterable: false,
      sortable: true,
    },
    {
      key: "HE_end",
      title: i18next.t("he_end"),
      isFilterable: false,
      sortable: true,
    },
    {
      key: "HE_status",
      title: i18next.t("he_status"),
      isFilterable: false,
      sortable: true,
    },
    {
      key: "distributor_file_send",
      title: i18next.t("count_distributors_sent_file"),
      isFilterable: false,
      sortable: true,
    },
    {
      key: "depots_data_send_to_hermes",
      title: i18next.t("count_depots_sent_to_hermes"),
      isFilterable: false,
      sortable: true,
    },
  ],
  tableCells: [
    "dateCreated",
    "exportFileName",
    "startTime",
    "endTime",
    "status",
    "distributorsSentCount",
    "hermesDepotsCount",
  ],
  searchSuccess: true,
  dateCreated: "0001-01-01T00:00:00",
  dateUpdated: "0001-01-01T00:00:00",
};

const getErrorsTableSettings = {
  tableColumns: [
    {
      key: "dateCreated",
      title: i18next.t("date"),
      isFilterable: false,
      sortable: true,
    },
    {
      key: "errorType",
      title: i18next.t("type"),
      isFilterable: false,
      sortable: true,
    },
    {
      key: "fileType",
      title: i18next.t("file"),
      isFilterable: false,
      sortable: true,
    },
    {
      key: "fileName",
      title: i18next.t("name"),
      isFilterable: false,
      sortable: true,
    },
    {
      key: "errorMessage",
      title: i18next.t("errorMessage"),
      isFilterable: false,
      sortable: true,
    },
  ],
  tableCells: [
    "dateCreated",
    "errorType",
    "fileType",
    "fileName",
    "errorMessage",
  ],
};
const Dashboard: FC<RouteComponentProps<{ location: any }>> = (props) => {
  const [EODReport, setEODReport] = useState({
    page: 1,
    itemsPerPage: 10,
    orderBy: "date",
    orderDirection: "desc",
    totalSize: null,
    data: [],
    isLoading: false,
  });
  const [EODReportFilters, setEODReportFilters] = useState({});
  const [dataErrors, setDataErrors] = useState({
    page: 1,
    itemsPerPage: 15,
    orderBy: "dateCreated",
    orderDirection: "desc",
    totalSize: null,
    data: [],
    isLoading: false,
  });
  const [errorsFilters, setErrorsFilters] = useState({});
  const [showOverlayLoader, setShowOverlayLoader] = useState(false);
  const [enableInfiniteScroll, setEnableInfiniteScroll] = useState(true);

  useEffect(() => {
    getEODReports();
    getErrors();

    const handleScroll = async () => {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 5
      ) {
        if (enableInfiniteScroll) {
          setDataErrors((prevState) => ({
            ...prevState,
            page: prevState.page + 1,
          }));
          await getErrors();
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [enableInfiniteScroll]);

  const getEODReports = async () => {
    setEODReport((prevState) => ({ ...prevState, isLoading: true }));
    const url = `${endpoints.HermesExports}`;

    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${cookies.get("rawtoken")}`,
        },
        body: JSON.stringify({
          page: EODReport.page - 1,
          pageSize: EODReport.itemsPerPage,
          orderBy: EODReport.orderBy,
          orderDirection: EODReport.orderDirection,
        }),
      });

      const res = await response.json();

      const newItems = res.data.map((item) => {
        return {
          ...item,
          dateCreated: new Date(item.dateCreated).toLocaleDateString("en-GB"),
          startTime: new Date(item.startTime).toLocaleDateString("en-GB"),
          endTime: item.endTime
            ? new Date(item.endTime).toLocaleDateString("en-GB")
            : "-",
          status: translateHEStatus(item.status),
        };
      });

      setEODReport((prevState) => ({
        ...prevState,
        totalSize: res.dataTotalSize,
        data: newItems,
        isLoading: false,
      }));
    } catch (error) {
      console.error("Error fetching EOD Reports:", error);

      setEODReport((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const getErrors = async () => {
    if (enableInfiniteScroll) {
      const url = `${endpoints.MANAGE_ERRORS}`;
      setShowOverlayLoader(true);
      try {
        const response = await fetch(url, {
          method: "post",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${cookies.get("rawtoken")}`,
          },
          body: JSON.stringify({
            page: dataErrors.page - 1,
            pageSize: dataErrors.itemsPerPage,
            orderBy: dataErrors.orderBy,
            orderDirection: dataErrors.orderDirection,
          }),
        });

        const res = await response.json();

        const newData = res.data.map((item) => {
          const dateArray = item.dateCreated.split("T");
          const date = new Date(dateArray[0]).toLocaleDateString("en-GB");
          const time = dateArray[1];
          return {
            ...item,
            dateCreated: `${date} at ${time}`,
          };
        });

        setDataErrors((prevState) => ({
          ...prevState,
          totalSize: res.dataTotalSize,
          data: [...prevState.data, ...newData],
          isLoading: false,
        }));

        if (newData.length === 0) {
          setEnableInfiniteScroll(false);
        }
      } catch (error) {
        setDataErrors((prevState) => ({ ...prevState, isLoading: false }));
        setShowOverlayLoader(false);
      }
      setShowOverlayLoader(false);
    }
  };

  const paginationUpdated = async (itemsPerTable, currentPage, type) => {
    if (type === "eod_report") {
      await getEODReports();
    } else {
      await getErrors();
    }
  };

  return (
    <PageWrapper>
      <div id="eod-table-container">
        <Table
          type="eod_report"
          title="EOD Report"
          tableSettings={getEODTableSettings}
          data={EODReport.data}
          allowMultipleSelection={false}
          paginationUpdated={paginationUpdated}
          totalItems={EODReport.totalSize ? EODReport.totalSize : 0}
          currentPage={EODReport.page}
          itemsPerPage={EODReport.itemsPerPage}
          isLoading={EODReport.isLoading}
          filters={EODReportFilters}
          currentSortingDirection={EODReport.orderDirection}
          selectedRows={[]}
          showMainAutocomplete
          hideFilters
          hideExport
          hideSearch
        />
      </div>
      <div className="pt-4 pb-4" />
      <div id="error-table-container">
        <Table
          type="errors"
          title="Errors"
          tableSettings={getErrorsTableSettings}
          data={dataErrors.data}
          allowMultipleSelection={false}
          paginationUpdated={paginationUpdated}
          totalItems={dataErrors.totalSize ? dataErrors.totalSize : 0}
          currentPage={dataErrors.page}
          itemsPerPage={dataErrors.itemsPerPage}
          isLoading={dataErrors.isLoading}
          filters={errorsFilters}
          selectedRows={[]}
          emitSelectedIds={() => {}}
          currentSortingDirection={dataErrors.orderDirection}
          hideFilters
          hideExport
          hideSearch
          hideFooter
        />

        {showOverlayLoader && (
          <div
            className="d-flex align-items-center justify-content-center ovarlay-loader"
            style={{ minHeight: "300px" }}
          >
            <div className="text-center">
              Downloading ... <br />
              <img src={loader} alt="" />
            </div>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default Dashboard;
