import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import Headline from './Headline'

const Box = styled.div`
  position: relative;
  box-shadow: 0 0 13px 0 rgba(82,63,105,.1);
  background: #fff;
  margin-bottom: ${({ hasMargin }) => hasMargin ? '20px' : 0};
`

const Head = styled.div`
  padding: 12px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`

const Content = styled.div`
  padding: ${({ hasContentGutter }) => hasContentGutter ? '25px' : 0};
`

const Buttons = styled.div`
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: flex-end;

  > * {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
`

interface Props {
    title?: string,
    children: ReactNode,
    hasMargin?: boolean,
    hasContentGutter?: boolean,
    buttons?: ReactNode
  }

const BoxComponent: FunctionComponent<Props> = ({ title, children, hasMargin = false, hasContentGutter = false, buttons }) => (
    <>
      <Box {... { hasMargin }}>
        {title && (
          <Head>
              <Headline disableMargin>{title}</Headline>
          </Head>
        )}
        <Content {... { hasContentGutter }}>
          {children}
        </Content>
      </Box>
      {buttons && (
        <Buttons>{buttons}</Buttons>
      )}
    </>
  )

export default BoxComponent
