import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { toast } from "react-toastify";
import * as actions from "../components/Actions/tenantsActions";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface TenantsState {
  isLoading: boolean;
  tenantsList: any;
  selectedTenant: any;
  responseMessage: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface AttemptEditTenantAction {
  type: "ATTEMPT_EDIT_TENANT_ACTION";
  selectedTenant: any;
}

interface GetTenantsAction {
  type: "GET_TENANTS_ACTION";
  tenantsList: any;
}

interface EditTenantAction {
  type: "EDIT_TENANT_ACTION";
  responseMessage: string;
  selectedTenant: any;
}

interface AddTenantAction {
  type: "ADD_TENANT_ACTION";
  responseMessage: string;
}

interface AttemptAction {
  type: "ATTEMPT_ACTION";
}

interface FinishAction {
  type: "FINISH_ACTION";
}

interface ErrorAction {
  type: "ERROR_ACTION";
  responseMessage: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
  | AttemptEditTenantAction
  | GetTenantsAction
  | EditTenantAction
  | AddTenantAction
  | AttemptAction
  | FinishAction
  | ErrorAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  attemptEditTenant:
    (tenantId): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState) {
        let callback = (editedTenant) => {
            dispatch({
              type: "ATTEMPT_EDIT_TENANT_ACTION",
              selectedTenant: editedTenant,
            });
          },
          error = (error) => {
            dispatch({ type: "ERROR_ACTION", responseMessage: error });
            toast.error(error || "Error");
          };
        actions.attemptEditTenant(tenantId, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  getAllTenants: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    dispatch({ type: "ATTEMPT_ACTION" });
    const appState = getState();
    if (appState) {
      let callback = (tenantsList) => {
          dispatch({ type: "GET_TENANTS_ACTION", tenantsList: tenantsList });
        },
        error = (error) => {
          dispatch({
            type: "ERROR_ACTION",
            responseMessage: error.response
              ? error.response.statusText
              : "Error",
          });
          toast.error(error.response ? error.response.statusText : "Error");
        };
      actions.getTenantsAction(callback, error);
    } else {
      dispatch({ type: "FINISH_ACTION" });
    }
  },
  editTenant:
    (editedTenant): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState && editedTenant) {
        let callback = () => {
            dispatch({
              type: "EDIT_TENANT_ACTION",
              responseMessage: "Success",
              selectedTenant: editedTenant,
            });
            toast.success("Success");
          },
          error = (error) => {
            dispatch({
              type: "ERROR_ACTION",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response.statusText : "Error");
          };
        actions.editTenant(editedTenant, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  addTenant:
    (newTenant): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState && newTenant) {
        let callback = () => {
            dispatch({ type: "ADD_TENANT_ACTION", responseMessage: "Success" });
            toast.success("Success");
          },
          error = (error) => {
            dispatch({
              type: "ERROR_ACTION",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response.statusText : "Error");
          };
        actions.addTenant(newTenant, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  deleteTenant:
    (tenantId, cb): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState) {
        let callback = () => {
            dispatch({ type: "ADD_TENANT_ACTION", responseMessage: "Success" });
            cb();
            toast.success("Success");
          },
          error = (error) => {
            dispatch({
              type: "ERROR_ACTION",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response.statusText : "Error");
          };
        actions.deleteTenant(tenantId, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: TenantsState = {
  isLoading: false,
  tenantsList: [],
  selectedTenant: {},
  responseMessage: "",
};

export const reducer: Reducer<TenantsState> = (
  state: TenantsState | undefined,
  incomingAction: Action,
): TenantsState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "ATTEMPT_ACTION":
      return {
        ...state,
        isLoading: true,
        responseMessage: "",
      };
    case "FINISH_ACTION":
      return {
        ...state,
        isLoading: false,
      };
    case "ATTEMPT_EDIT_TENANT_ACTION":
      return {
        ...state,
        selectedTenant: action.selectedTenant,
        isLoading: false,
        responseMessage: "",
      };
    case "GET_TENANTS_ACTION":
      return {
        ...state,
        tenantsList: action.tenantsList,
        isLoading: false,
      };
    case "EDIT_TENANT_ACTION":
      return {
        ...state,
        isLoading: false,
        selectedTenant: action.selectedTenant,
        responseMessage: action.responseMessage,
      };
    case "ADD_TENANT_ACTION":
      return {
        ...state,
        isLoading: false,
        responseMessage: action.responseMessage,
      };
    case "ERROR_ACTION":
      return {
        ...state,
        responseMessage: action.responseMessage,
        isLoading: false,
      };
  }
  return state;
};
