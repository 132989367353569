import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../store";
import * as TenantsStore from "../../store/TenantsReducer";
import "react-dropdown/style.css";
import Form from "../Form";
import TextInput from "../TextInput";
import Button from "../Button";
import { BackIcon, AddIcon, EditIcon } from "../../svg";
import i18next from "i18next";

type TenantsProps = TenantsStore.TenantsState &
  typeof TenantsStore.actionCreators &
  RouteComponentProps<{ tenantId: any }>;

class EditTenantForm extends React.PureComponent<TenantsProps> {
  constructor(props) {
    super(props);
  }

  public state = {
    name: "",
  };

  componentDidMount() {
    const selectedTenantId = this.props.match.params.tenantId;
    if (selectedTenantId) {
      this.props.attemptEditTenant(selectedTenantId);
    }
  }

  componentWillReceiveProps(nextProps, prevState) {
    var isEdit = this.props.match.params.tenantId;
    if (isEdit) {
      const { selectedTenant } = nextProps;
      this.setState({
        name: selectedTenant["name"],
      });
    }
  }

  public handleSubmit = (event) => {
    var isEdit = this.props.match.params.tenantId;
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    var editedTenant = { ...this.props.selectedTenant };
    editedTenant.name = this.state.name;
    if (isEdit) {
      this.props.editTenant(editedTenant);
    } else {
      this.props.addTenant(editedTenant);
    }
    this.setState({
      validated: true,
    });
  };

  public onChange = (event) => {
    var validity = event.target.validity;
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  navigateBack = (event) => {
    this.props.history.push("/manage-tenants");
  };

  public render() {
    const { isLoading } = this.props;
    var isEdit = this.props.match.params.tenantId;
    return (
      <Form
        title={i18next.t("edit_tenant")}
        onSubmit={this.handleSubmit}
        hasBox
        buttonIcon={isEdit ? EditIcon : AddIcon}
        buttonText={isEdit ? i18next.t("edit") : i18next.t("create")}
        buttonJustify="space-between"
        additionalButton={
          <Button
            icon={BackIcon}
            size="large"
            variant="outlined"
            onClick={this.navigateBack}
          >
            {i18next.t("back")}
          </Button>
        }
        {...{ isLoading }}
      >
        <TextInput
          label={i18next.t("name")}
          name="name"
          id="name"
          type="text"
          required
          value={this.state.name}
          onChange={this.onChange}
        />
      </Form>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.tenants,
  TenantsStore.actionCreators,
)(EditTenantForm);
