import React, { FunctionComponent, ReactNode, useState  } from 'react'
import { connect, Provider } from 'react-redux';
import styled from 'styled-components'
import Pagination from 'react-pagination-js';
import 'react-pagination-js/dist/styles.css';

interface Props {
    totalItems?: number,
    paginationUpdated?: any,
    currentPage?: number,
    itemsPerPage?: number,
  }

class PaginationComponent extends React.Component<Props>{
    constructor(props) {
        super(props);
        localStorage.setItem('selectedCustomer', '');
        this.handleSelectChange = this.handleSelectChange.bind(this)
    }

    state = {
        itemsPerTable: 10,
        currentPage: 1
    }

    updatePropsToParent = () => {
        this.props.paginationUpdated(this.state.itemsPerTable, this.state.currentPage)
    }

    handleSelectChange = (event) => {
        this.setState({itemsPerTable: parseInt(event.target.value), currentPage: 1}, () => this.updatePropsToParent())
    }

   handlePageClick = (page) => {
        this.setState({currentPage: page}, () => this.updatePropsToParent())
    };

    componentDidMount() {
        this.setState({
            itemsPerTable: this.props.itemsPerPage ? this.props.itemsPerPage : 5,
            currentPage: this.props.currentPage ? this.props.currentPage : 1
        })
    }

    public render() {
        const TableFoot = styled.div`
            .react-pagination-js-bootstrap { font-size: 0px}
            .react-pagination-js-bootstrap a { display: block; font-size: 14px}

        `

        const PagingInfo = styled.div`
            display: flex;
            align-items: center;
            background: #c2aa73;
            color: #fff !important;
            font-size: 10px !important;
            padding: 4px 8px !important;
            border-radius: 2px !important;
            margin-left: 20px;
            height: 36px;
        `

        const SelectWrap = styled.div`
            select { border: 1px solid #e2e2e2; height: 36px; border-radius: 2px; }
        `

        const visibleItems = this.state.itemsPerTable
        const totalItems = this.props.totalItems
        const currentPage = this.state.currentPage

        const startIndex = ((currentPage - 1) * visibleItems) + 1;
        let endIndex = ((currentPage - 1) * visibleItems) + visibleItems

        if ( endIndex >= totalItems ) {
            endIndex = totalItems
        }

        return (
            <TableFoot>
                <div className="pagination-wrap d-flex align-items-center">
                    <SelectWrap>
                        <select name="" id="" onChange={(event) => this.handleSelectChange(event)}>
    						<option selected={this.state.itemsPerTable === 5} value="5">5</option>
                            <option selected={this.state.itemsPerTable === 10} value="10">10</option>
                            <option selected={this.state.itemsPerTable === 20} value="20">20</option>
                            <option selected={this.state.itemsPerTable === 50} value="50">50</option>
                        </select>
                    </SelectWrap>

                    <PagingInfo>
                        <p>
                            Showing {startIndex} - {endIndex} of {totalItems} results
                        </p>
                    </PagingInfo>

                    <div className="ml-auto">
                        <Pagination
                            currentPage={currentPage}
                            totalSize={totalItems}
                            sizePerPage={visibleItems}
                            changeCurrentPage={this.handlePageClick}
                            theme="bootstrap"
                            className="pagination"

                        />
                    </div>
                </div>
            </TableFoot>
        )
    }
}
export default connect(
    //(state: ApplicationState) => state.poc,
    //POCStore.actionCreators
)(PaginationComponent);
