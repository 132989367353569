import * as React from "react";
import { Route, Redirect } from "react-router";

import * as actions from "../components/Utils/actions";
import { USER_ROLES } from "../components/Utils/actions";

class ProtectedRoute extends React.Component {
  render() {
    const {
      roles,
      isAuthenticated,
      component: Component,
      ...props
    } = this.props;
    return (
      <Route
        {...props}
        render={(props) =>
          roles ? (
            roles.includes(actions.getUserRole()) ? (
              <Component {...props} />
            ) : actions.getUserRole() === USER_ROLES.DISTRIBUTOR ? (
              <Redirect to="access-denied" />
            ) : (
              <p>You cannot access this route.</p>
            )
          ) : (
            <p>You cannot access this route.</p>
          )
        }
      />
    );
  }
}

export default ProtectedRoute;
