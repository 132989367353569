import React from 'react'

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 480 480"
      version="1.1"
      viewBox="0 0 480 480"
      xmlSpace="preserve"
    >
      <path d="M80 48H16C7.168 48 0 55.168 0 64v64c0 8.832 7.168 16 16 16s16-7.168 16-16V80h48c8.832 0 16-7.168 16-16s-7.168-16-16-16zM464 336c-8.832 0-16 7.168-16 16v48h-48c-8.832 0-16 7.168-16 16s7.168 16 16 16h64c8.832 0 16-7.168 16-16v-64c0-8.832-7.168-16-16-16zM464 48h-64c-8.832 0-16 7.168-16 16s7.168 16 16 16h48v48c0 8.832 7.168 16 16 16s16-7.168 16-16V64c0-8.832-7.168-16-16-16zM80 400H32v-48c0-8.832-7.168-16-16-16s-16 7.168-16 16v64c0 8.832 7.168 16 16 16h64c8.832 0 16-7.168 16-16s-7.168-16-16-16zM80 112c-8.832 0-16 7.168-16 16v224c0 8.832 7.168 16 16 16s16-7.168 16-16V128c0-8.832-7.168-16-16-16zM144 112c-8.832 0-16 7.168-16 16v160c0 8.832 7.168 16 16 16s16-7.168 16-16V128c0-8.832-7.168-16-16-16zM208 112c-8.832 0-16 7.168-16 16v160c0 8.832 7.168 16 16 16s16-7.168 16-16V128c0-8.832-7.168-16-16-16zM272 112c-8.832 0-16 7.168-16 16v224c0 8.832 7.168 16 16 16s16-7.168 16-16V128c0-8.832-7.168-16-16-16zM336 112c-8.832 0-16 7.168-16 16v160c0 8.832 7.168 16 16 16s16-7.168 16-16V128c0-8.832-7.168-16-16-16zM400 112c-8.832 0-16 7.168-16 16v224c0 8.832 7.168 16 16 16s16-7.168 16-16V128c0-8.832-7.168-16-16-16zM144.64 336h-.32c-8.832 0-15.84 7.168-15.84 16s7.328 16 16.16 16 16-7.168 16-16-7.168-16-16-16zM208.64 336h-.32c-8.832 0-15.84 7.168-15.84 16s7.328 16 16.16 16 16-7.168 16-16-7.168-16-16-16zM336.64 336h-.32c-8.832 0-15.84 7.168-15.84 16s7.328 16 16.16 16 16-7.168 16-16-7.168-16-16-16z"></path>
    </svg>
  )
