import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import Popup from "reactjs-popup";
import * as POCStore from "../../store/POCReducer";
import { RouteComponentProps } from "react-router-dom";
import Button from "../Button";
import Cookies from "universal-cookie";
import { EditIcon, DeleteIcon } from "../../svg";
import * as paginationEndpoints from "../constants/paginationEndpoints/paginationEndpoints";
import * as actions from "components/Utils/actions";
import * as clickableRow from "../Utils/clickableRow";
import Table from "../Table";
import TableWrapper from "../TableWrapper";
import _ from "lodash";
import * as endpoints from "../constants/endpoints";
import styled from "styled-components";
import { Modal } from "reactstrap";
import Headline from "../Headline";
import i18next from "i18next";

const cookies = new Cookies();

const dateRender = (date) => {
  return date ? actions.formatDate(date) : date;
};

const ranksActionFormatter = (cell, row, index) => {
  return cell + "%";
};

interface ModalProps {
  selectedPocMapId?: any;
  closeModal?: any;
  isOpen?: any;
  collection?: any;
  selectedRows?: any;
}

class ModalPocMapEdit extends React.Component<ModalProps> {
  state = {
    hasChanged: false,
    selectedHermesId: "",
    hermesCollectionIds: [],
  };

  _isMounted = false;

  constructor(props) {
    super(props);

    this.createPocMapping = this.createPocMapping.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  closeModal = (shouldUpdate) => {
    this.props.closeModal(shouldUpdate);
  };

  getHermesPocsTableSettings = () => {
    return {
      tableID: "HermesPOCs",
      keyField: "hermesId",
      tableColumns: [
        {
          key: "hermesId",
          title: i18next.t("code"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "pocName",
          title: i18next.t("name"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "pocStatus",
          title: i18next.t("status"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "city",
          title: i18next.t("city"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "street",
          title: i18next.t("address"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "houseNumber",
          title: i18next.t("number"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "hermesCustomerName",
          title: i18next.t("client"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "customerFiscalCode",
          title: i18next.t("bulstat"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "rank",
          title: i18next.t("p%"),
          dataFormat: ranksActionFormatter,
          isFilterable: true,
          sortable: true,
        },
      ],
      tableCells: [
        "hermesId",
        "pocName",
        "pocStatus",
        "city",
        "street",
        "houseNumber",
        "hermesCustomerName",
        "customerFiscalCode",
        "rank",
      ],
    };
  };

  emitHermesId = (hermesId) => {
    _.set(this.state, "selectedHermesId", hermesId);
  };

  deleteMapping = () => {
    const url = `${endpoints.POC_MAPPINGS_ENDPOINT}/${this.props.selectedPocMapId}`;

    if (window.confirm("Сигурен ли си, че искаш да изтриеш")) {
      fetch(url, {
        method: "delete",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${cookies.get("rawtoken")}`,
        },
      }).then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.setState(
            {
              hermesCollectionIds: [],
              hasChanged: true,
            },
            () => {
              this.closeModal(this.state.hasChanged);
            },
          );
        }
      });
    }
  };

  createPocMapping = () => {
    const url = `${endpoints.POC_MAPPINGS_ENDPOINT}/${this.props.collection[0].id}`;

    if (window.confirm("Сигурен ли си, че искаш го промениш")) {
      fetch(url, {
        method: "PUT",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${cookies.get("rawtoken")}`,
        },
        body: JSON.stringify({
          pocId: this.state.selectedHermesId,
          customerId: this.props.collection[0].customerId,
          depotId: this.props.collection[0].depotId,
          fromDate: null,
          toDate: null,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          this.setState(
            {
              hasChanged: true,
            },
            () => {
              this.closeModal(this.state.hasChanged);
            },
          );
        });
    }
  };

  public render() {
    const PageWrapper = styled.div`
      //button.disabled { opacity: 0.6; pointer-events: none;}
      .search {
        padding-top: 3px;
      }
    `;

    const TableDetails = styled.div`
      flex-grow: 1;

      table {
        background: #fff;
      }
      table,
      td,
      th {
        border: 1px solid #e2e2e2;
      }
      table {
        width: 100%;
        border-collapse: collapse;
      }

      th {
        text-align: left;
        font-size: 10px;
        padding: 5px 5px;
        font-weight: 500;
        color: #6d6d6d;
      }
      td {
        font-size: 10px;
        text-align: left;
        padding: 5px 5px;
        min-height: 40px;
      }

      button {
        font-size: 8px;
      }

      tr + tr {
        border-top: 1px solid #e2e2e2;
      }
    `;

    const Head = styled.div`
      padding: 12px 20px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    `;

    return (
      <PageWrapper>
        <div className="modal-wrapper">
          <Modal isOpen={this.props.isOpen} scrollable={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">Редактиране на POC Mapping</h5>

              <button
                type="button"
                onClick={() => this.closeModal(false)}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <TableDetails>
                <Head>
                  <Headline disableMargin>Selected POC Mappings</Headline>
                </Head>

                <table>
                  <tr>
                    <th>{i18next.t("depot_name")}</th>
                    <th>{i18next.t("code")}</th>
                    <th>{i18next.t("namet")}</th>
                    <th>{i18next.t("address")}</th>
                    <th>{i18next.t("bulstat")}</th>
                    <th>{i18next.t("poc_code")}</th>
                    <th>{i18next.t("poc_name")}</th>
                    <th>{i18next.t("poc_address")}</th>
                    <th>{i18next.t("client")}</th>
                    <th>{i18next.t("bulstat")}</th>
                    <th>{i18next.t("poc_status")}</th>
                    <th>{i18next.t("date_mapped")}</th>
                  </tr>

                  {this.props.collection &&
                    this.props.collection.map((rowData, index) => (
                      <tr>
                        <td>{rowData.depotName}</td>
                        <td>{rowData.customerCode}</td>
                        <td>{rowData.customerName}</td>
                        <td>{rowData.customerAddressOfDispatching}</td>
                        <td>{rowData.customerVat}</td>
                        <td>{rowData.hermesPocCode}</td>
                        <td>{rowData.hermesPocName}</td>
                        <td>{rowData.hermesPocStreet}</td>
                        <td>{rowData.hermesPocCustomerName}</td>
                        <td>{rowData.hermesPocCustomerFiscalCode}</td>
                        <td>{rowData.hermesPocStatus}</td>
                        <td>{rowData.dateCreated}</td>
                      </tr>
                    ))}
                </table>
              </TableDetails>

              <TableWrapper
                title="Hermes"
                tableSettings={this.getHermesPocsTableSettings()}
                selectedRows={this.props.selectedRows}
                emitHermesId={this.emitHermesId}
                distributorCustomerIds={this.props.collection[0].customerId}
              />
            </div>

            <div className="modal-footer">
              <div className="actions d-flex justify-content-end pt-4 pb-4">
                <Button
                  id="edit-map-btn"
                  icon={EditIcon}
                  size="large"
                  variant="outlined"
                  // className={
                  // 	this.state.selectedPocMapId !== '' ? 'active': 'disabled'
                  // }
                  onClick={this.createPocMapping}
                >
                  {i18next.t("create_mapping")}
                </Button>

                <Button
                  id="delete-map-btn"
                  icon={DeleteIcon}
                  variant="outlined"
                  size="large"
                  color="danger"
                  onClick={this.deleteMapping}
                  className={`
										ml-3 mr-3
										${this.state.hermesCollectionIds.length > 0 ? "active" : "disabled"}

										`}
                >
                  {i18next.t("delete_mapping")}
                </Button>

                <Button
                  id="close-modal-btn"
                  variant="outlined"
                  size="large"
                  color="danger"
                  // className="btn btn-secondary"
                  onClick={() => this.closeModal(false)}
                >
                  {i18next.t("close")}
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </PageWrapper>
    );
  }
}
export default connect(
  (state: ApplicationState) => state.poc,
  POCStore.actionCreators,
)(ModalPocMapEdit);
