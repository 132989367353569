import * as endpoints from '../constants/endpoints';
import Cookies from 'universal-cookie';

const cookies = new Cookies();


export function getReportName (){
    const url = endpoints.GET_LATEST_RAW_DATA;
    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
}

export function downloadReport (reportName) {
    const url = endpoints.DOWNLOAD_MONTHLY_REPORT + reportName;
    return  fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    });
}