import React from 'react'

export default ()=> (
  <svg className='circular' viewBox='25 25 50 50'>
    <circle
      cx='50'
      cy='50'
      r='20'
      fill='none'
      strokeMiterlimit='10'
      strokeWidth='2'
      className='path'
    ></circle>
  </svg>
)
