import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as ReceivedSalesFilesStore from "../../store/ReceivedSalesFilesReducer";
import FilterableTableContainer from "../Helpers/FilterableTableContainer";
import { RouteComponentProps } from "react-router";
import Loader from "../Loader";
import Button from "../Button";
import * as actions from "components/Utils/actions";

type ReceivedSalesFilesProps = ReceivedSalesFilesStore.SalesFilesState &
  typeof ReceivedSalesFilesStore.actionCreators &
  RouteComponentProps<{ distributorFileId: any }>;

class ReceivedSalesFilesDetails extends React.PureComponent<ReceivedSalesFilesProps> {
  state = {
    shouldRender: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const selectedDistributorFileId = this.props.match.params.distributorFileId;
    this.props.getReceivedSalesFileEntries(selectedDistributorFileId);
  }

  renderDateMapped = (props) => {
    var date = props.value;
    return date ? actions.formatDate(date) : date;
  };

  getReceivedSalesFilesTableProps = () => {
    const selectedDistributorFileId = this.props.match.params.distributorFileId;

    return {
      // Data for the table to display; can be anything
      data: this.props.salesFileEntriesList,
      // Fields to show in the table, and what object properties in the data they bind to
      fields: [
        {
          name: "entryData",
          displayName: "Данни за запис",
          render: ({ value }) => <textarea>{value}</textarea>,
          inputFilterable: true,
          sortable: true,
        },
        {
          name: "position",
          displayName: "Позиция",
          inputFilterable: true,
          sortable: true,
        },

        {
          name: "status",
          displayName: "Статус",
          inputFilterable: true,
          exactFilterable: true,
          sortable: true,
        },
        { name: "error", displayName: "Грешка" },
        {
          name: "dateCreated",
          displayName: "Дата на създаване",
          render: this.renderDateMapped,
        },
      ],
      headerVisible: true,
      namespace: "ReceivedSalesFileEntries",
      initialSort: "depot",
      bsLength: 12,
      tableName: `Received Sales File Entries for File Id: ${selectedDistributorFileId}`,
    };
  };

  navigateBack = (event) => {
    this.props.history.push("/received-sales-files-report");
  };

  toggleMapBtnState = (state) => {
    //edit to set editability
    var editBtn = document.getElementById("edit-sku-btn"),
      deleteBtn = document.getElementById("delete-map-btn"),
      createBtn = document.getElementById("create-sku-btn");
    if (editBtn && deleteBtn && createBtn) {
      (editBtn as HTMLInputElement).disabled = state;
      (createBtn as HTMLInputElement).disabled = state;
      (deleteBtn as HTMLInputElement).disabled = state;
    }
  };

  resetBtnState() {
    let buttons = document.getElementsByTagName("button");
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].disabled = false;
    }
  }

  public render() {
    const { isLoading } = this.props;

    return (
      <FilterableTableContainer
        {...{ isLoading }}
        tableProps={this.getReceivedSalesFilesTableProps()}
        buttons={
          <Button size="large" variant="outlined" onClick={this.navigateBack}>
            Back
          </Button>
        }
      />
    );
  }
}
export default connect(
  (state: ApplicationState) => state.receivedSalesFiles,
  ReceivedSalesFilesStore.actionCreators,
)(ReceivedSalesFilesDetails);
