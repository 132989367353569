import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as WHSStockReportsReducer from "../../store/WHSStockReportsReducer";
import FilterableTableContainer from "../Helpers/FilterableTableContainer";
import { RouteComponentProps } from "react-router";
import $ from "jquery";
import regions from "../../regions.json";
import Button from "../Button";
import Select from "../Select";
import DateRangePicker from "../DateRangePicker";
import { Row, Col } from "react-grid-system";
import Box from "../Box";
import * as actions from "components/Utils/actions";
import i18next from "i18next";

type WHSStockReportsProps = WHSStockReportsReducer.WHSStockReportsState &
  typeof WHSStockReportsReducer.actionCreators &
  RouteComponentProps<{}>;

const dateRender = (props) => {
  var date = props.value;
  return date ? actions.formatDate(date) : date;
};

class WHSStockReport extends React.PureComponent<WHSStockReportsProps> {
  state = {
    shouldRender: false,
    selectedRegion: "",
    selectedDepotsList: [],
    generateSalesReportForDepots: [],
    date: [new Date(), new Date()],
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.clearValues();
    this.toggleMapBtnState(true);
  }

  componentDidUpdate() {
    if (this.props.selectedDepotsList.length === 0) {
      this.toggleMapBtnState(true);
    } else {
      this.toggleMapBtnState(false);
    }
  }

  downloadReport = (reportId) => {};

  downloadReportAction = (props) => {
    let report = props.record;

    return (
      <div className="align-center">
        <Button size="large" onClick={() => this.downloadReport(report.id)}>
          Download
        </Button>
      </div>
    );
  };

  getGeneratedStocksTableProps = () => {
    return {
      // Data for the table to display; can be anything
      data: this.props.generatedReportsList
        ? this.props.generatedReportsList
        : [],
      // Fields to show in the table, and what object properties in the data they bind to
      fields: [
        {
          name: "brand",
          displayName: i18next.t("brand"),
          inputFilterable: true,
          sortable: true,
        },
        {
          name: "date",
          displayName: i18next.t("date"),
          inputFilterable: true,
          sortable: true,
          render: dateRender,
        },
        {
          name: "address",
          displayName: i18next.t("address"),
          inputFilterable: true,
          exactFilterable: true,
          sortable: true,
        },
        {
          name: "depotName",
          displayName: i18next.t("depot_name"),
          inputFilterable: true,
          exactFilterable: true,
          sortable: true,
        },
        {
          name: "depotCode",
          displayName: i18next.t("depo_code"),
          inputFilterable: true,
          exactFilterable: true,
          sortable: true,
        },
        {
          name: "supplier",
          displayName: i18next.t("supplier"),
          inputFilterable: true,
          exactFilterable: true,
          sortable: true,
        },
        //{ name: 'hermesPocName', displayName: i18next.t('depot') "ПОК име", inputFilterable: true, exactFilterable: true, sortable: true },
        //{ name: 'hermesPocCode', displayName: i18next.t('depot') "ПОК код" },
        { name: "skuId", displayName: i18next.t("sku_code") }, //hermesSkuCode
        { name: "skuName", displayName: i18next.t("sku_name") },
        { name: "packagingType", displayName: i18next.t("packaging") },
        { name: "quantity", displayName: i18next.t("qty") },
        { name: "volume", displayName: i18next.t("volume") },
      ],
      namespace: "GeneratedStocksReports",
      initialSort: "id",
      bsLength: 12,
      tableName: "Generated Stocks Reports",
    };
  };

  onDateChange = (date) => {
    var selectedDepots = $("#selectedDepot").val();
    this.props.setSelectedDepots(selectedDepots);

    this.setState({
      date: date,
    });
  };

  onChange = (event) => {
    event.preventDefault();
    if (event.target.name === "selectedRegion") {
      var region = event.target.value;
      this.props.clearValues();
      this.props.setRegion(region);
    } else if (event.target.name === "selectedDepot") {
      if (this.props.selectedRegion && this.state.date) {
        this.toggleMapBtnState(false);
      }
    }
  };

  generateSalesReport = (event) => {
    var selectedDepots = $("#selectedDepot").val(),
      depotData = {
        dateStart: this.state.date[0],
        dateEnd: this.state.date[1],
        depotCodes: selectedDepots,
        reportType: "excel",
      };
    this.props.downloadStocksReportForDepots(depotData);
  };

  previewSalesReports = (event) => {
    var selectedDepots = $("#selectedDepot").val(),
      depotData = {
        dateStart: this.state.date[0],
        dateEnd: this.state.date[1],
        depotCodes: selectedDepots,
        reportType: "preview",
      };
    this.props.generateStocksReportForDepots(depotData);
  };

  toggleMapBtnState = (state) => {
    //edit to set editability
    var generateSalesReport = document.getElementById("generateSalesReport"),
      refreshGeneratedSalesReports = document.getElementById(
        "previewSalesReports",
      );
    if (generateSalesReport && refreshGeneratedSalesReports) {
      (generateSalesReport as HTMLInputElement).disabled = state;
      (refreshGeneratedSalesReports as HTMLInputElement).disabled = state;
    }
  };

  resetBtnState() {
    let buttons = document.getElementsByTagName("button");
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].disabled = false;
    }
  }

  public render() {
    const TableHead = styled.div`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > * {
        margin: 0 8px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    `;

    TableHead.Field = styled.div`
      width: ${({ size }) => (size === "large" ? "280px" : "100%")};
    `;

    TableHead.Button = styled.div`
      position: relative;
      padding-top: 20px;
    `;

    const { depotsList, selectedDepotsList, isLoading } = this.props;
    var regs = regions
        ? regions.map((region, id) => {
            return {
              value: region.name,
              label: region.name,
            };
          })
        : [],
      depots = depotsList
        ? depotsList.map((depot, id) => {
            return {
              value: depot.depotCode,
              label: depot.depotName,
            };
          })
        : [];
    regs.push({ value: "All", label: "All" });
    return (
      <div>
        <Row>
          <Col md={3}>
            <Box hasContentGutter>
              <div className="box-content">
                <Row>
                  <Col md={12}>
                    <TableHead.Field>
                      <DateRangePicker
                        label="Date"
                        onChange={this.onDateChange}
                        value={this.state.date}
                      />
                    </TableHead.Field>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <TableHead.Field>
                      <Select
                        label="Region"
                        name="selectedRegion"
                        value={this.props.selectedRegion}
                        defaultValue={"-1"}
                        onChange={this.onChange}
                        options={regs}
                      />
                    </TableHead.Field>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <TableHead.Field>
                      <Select
                        className="custom-height-select"
                        multiple
                        label="Selected Depot"
                        id="selectedDepot"
                        name="selectedDepot"
                        defaultValue={selectedDepotsList}
                        onChange={this.onChange}
                        options={depots}
                      />
                    </TableHead.Field>
                  </Col>
                </Row>
              </div>
            </Box>
          </Col>
          <Col md={9}>
            <FilterableTableContainer
              {...{ isLoading }}
              tableProps={this.getGeneratedStocksTableProps()}
              buttons={
                <>
                  <Button
                    id="generateSalesReport"
                    size="large"
                    variant="outlined"
                    onClick={this.generateSalesReport}
                  >
                    Excel
                  </Button>

                  <Button
                    id="previewSalesReports"
                    size="large"
                    color="primary"
                    variant="outlined"
                    onClick={this.previewSalesReports}
                  >
                    Preview
                  </Button>
                </>
              }
            />
          </Col>
        </Row>
      </div>
    );
  }
}
export default connect(
  (state: ApplicationState) => state.whsStockReports,
  WHSStockReportsReducer.actionCreators,
)(WHSStockReport);
