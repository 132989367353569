import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../store";
import * as UsersStore from "../../store/UsersReducer";
import * as actions from "components/Utils/actions";
import "react-dropdown/style.css";
import Form from "../Form";
import Button from "../Button";
import TextInput from "../TextInput";
import Select from "../Select";
import { EditIcon, BackIcon } from "../../svg";
import { Col, Row } from "react-grid-system";
import * as roles from "components/Utils/actions";
import $ from "jquery";
import regions from "../../regions.json";
import i18next from "i18next";
const regs = regions
  ? regions.map((region, id) => {
      return region.name;
    })
  : [];

const validator = require("validator");

type UsersProps = UsersStore.UsersState &
  typeof UsersStore.actionCreators &
  RouteComponentProps<{ userId: any }>;

class EditUserForm extends React.PureComponent<UsersProps> {
  constructor(props) {
    super(props);
  }

  public state = {
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    roleId: "",
    distributorId: "",
    validated: false,
    //depotsTabs: ['West', 'South', 'East', 'North', 'Sofia'],
    depotsTabs: regs,
    selectedDepots: [],
    distributorHidden: true,
    depotsHidden: true,
  };

  componentDidMount() {
    const selectedUserId = this.props.match.params.userId;
    this.props.attemptEditUser(selectedUserId);
  }

  componentWillReceiveProps(nextProps, prevState) {
    const { selectedUser, selectedDepots } = nextProps;

    this.setState({
      firstName: selectedUser["firstName"],
      lastName: selectedUser["lastName"],
      username: selectedUser["username"],
      email: selectedUser["email"],
      roleId: selectedUser["roleId"],
      distributorId: selectedUser["distributorId"],
      distributorHidden: selectedUser
        ? !(Number(selectedUser.roleId) === 5)
        : true,
      depotsHidden: selectedUser
        ? !(
            actions.rolesEnum[Number(selectedUser.roleId)] ===
              "TenantSupervisor" ||
            actions.rolesEnum[Number(selectedUser.roleId)] === "TenantSupport"
          )
        : true,
      selectedDepots: selectedDepots,
    });
  }

  public handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    var editedUser = { ...this.props.selectedUser };
    editedUser.firstName = this.state.firstName;
    editedUser.lastName = this.state.lastName;
    editedUser.username = this.state.username;
    editedUser.email = this.state.email;
    editedUser.roleId = this.state.roleId;
    editedUser.distributorId = this.state.distributorId
      ? this.state.distributorId
      : "";
    editedUser["selectedDepots"] = this.props.selectedDepots;

    // better log something than pass null as callback
    this.props.editUser(editedUser, () => {
      console.log("callback");
    });

    this.setState({
      validated: true,
    });
  };

  public onChange = (event) => {
    var value = Number(event.target.value);
    var isRoleChange = event.target.name === "roleId";
    console.log(event.target);
    if (value === 4 || value === 3) {
      var selectedUser = { ...this.props.selectedUser };
      selectedUser[event.target.name] = event.target.value;
      this.props.setUser(selectedUser);
      this.props.getDepotsByRegion("West");
    }
    var dh = isRoleChange
      ? !(
          actions.rolesEnum[Number(value)] === "TenantSupervisor" ||
          actions.rolesEnum[Number(value)] === "TenantSupport"
        )
      : true;
    this.setState({
      [event.target.name]: event.target.value,
      distributorHidden: isRoleChange
        ? value !== 5
        : this.state.distributorHidden,
      depotsHidden: isRoleChange && dh,
    });
  };

  changeCheckbox = (event) => {
    var selectedDepots = this.props.selectedDepots,
      selectedDepotValue = Number(event.target.value);
    if (selectedDepots.includes(selectedDepotValue)) {
      var depotIdx = selectedDepots.indexOf(selectedDepotValue);
      selectedDepots.splice(depotIdx, 1);
    } else {
      selectedDepots.push(selectedDepotValue);
    }
    this.props.selectDepots(selectedDepots);
    this.setState({
      validated: !this.state.validated,
    });
  };

  depotsTabClick = (event) => {
    //function to change depots based on selected tab
    var button =
      event.target.localName === "button"
        ? event.target
        : event.target.offsetParent;
    var selectedTab = event.target.classList.contains("bubble")
      ? event.target.offsetParent.innerText
      : event.target.innerText;
    if (selectedTab) {
      this.props.getDepotsByRegion(selectedTab);
    }
    var activeButton = $(".active");
    activeButton.removeClass("active");
    button.classList.add("active");
    event.preventDefault();
  };

  navigateBack = (event) => {
    var role = actions.getUserRole(),
      backUrl =
        role === "Administrator"
          ? "/manage-admin-tenant-admin"
          : role === "TenantAdmin"
            ? "/users"
            : "";
    this.props.history.push(backUrl);
  };

  calculateDepotColumns = (depots) => {
    const depotColumnCount = Math.floor(depots.length / 4);
    var depotColumnLeftover = 4 * ((depots.length / 4) % 1);
    var leftover = depotColumnLeftover > 0 ? 1 : 0;
    var inc = depotColumnCount + leftover;
    depotColumnLeftover--;
    leftover = depotColumnLeftover > 0 ? 1 : 0;
    var range = inc + depotColumnCount + leftover;
    var depotColumns = [];
    for (let i = 1; i <= 4; i++) {
      if (i === 1) {
        depotColumns.push(depots.slice(0, inc));
      } else {
        depotColumns.push(depots.slice(inc, range));
        inc += depotColumnCount + leftover;
        depotColumnLeftover--;
        leftover = depotColumnLeftover > 0 ? 1 : 0;
        range += depotColumnCount + leftover;
      }
    }
    return depotColumns;
  };

  renderDepots = (depotColumns) => {
    return (
      <div className="depots-container">
        <>
          <label>Depots</label>
          <div>
            {this.state.depotsTabs.map((location, i) => {
              if (i === 0) {
                return (
                  <Button className="active" onClick={this.depotsTabClick}>
                    {location}
                  </Button>
                );
              }
              return <Button onClick={this.depotsTabClick}>{location}</Button>;
            })}
          </div>
        </>
        <Row>
          {depotColumns.map((depotColumn, i) => {
            return (
              <Col sm={3}>
                <ul className="custom-depots-ul">
                  {depotColumn.map((depot, i) => {
                    if (this.props.selectedDepots.includes(depot.id)) {
                      return (
                        <li
                          className="depotLine"
                          style={{ marginBottom: 8 }}
                          key={depot.id}
                        >
                          <input
                            type="checkbox"
                            checked
                            id={depot.id}
                            value={depot.id}
                            onChange={this.changeCheckbox}
                          />
                          <label htmlFor={depot.id}>{depot.depotName}</label>
                        </li>
                      );
                    } else {
                      return (
                        <li
                          className="depotLine"
                          style={{ marginBottom: 8 }}
                          key={depot.id}
                        >
                          <input
                            type="checkbox"
                            id={depot.id}
                            value={depot.id}
                            onChange={this.changeCheckbox}
                          />
                          <label htmlFor={depot.id}>{depot.depotName}</label>
                        </li>
                      );
                    }
                  })}
                </ul>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  };

  public render() {
    const { isLoading, selectedUser, distributors, depots } = this.props;
    const distributorHidden = this.state.distributorHidden;
    const depotsHidden = this.state.depotsHidden;
    //TODO check currently logged user for preselected value
    var rolesArray = Object.values(roles.rolesEnum);
    rolesArray.splice(0, 1);
    return (
      <>
        <Form
          title={i18next.t("edit_user")}
          hasBox
          buttonText={i18next.t("edit")}
          buttonIcon={EditIcon}
          buttonJustify="space-between"
          additionalButton={
            <Button
              icon={BackIcon}
              size="large"
              variant="outlined"
              onClick={this.navigateBack}
            >
              {i18next.t("back")}
            </Button>
          }
          onSubmit={this.handleSubmit}
          {...{ isLoading }}
        >
          <TextInput
            label={i18next.t("name")}
            name="firstName"
            id="firstName"
            type="text"
            required
            value={this.state.firstName}
            onChange={this.onChange}
          />

          <TextInput
            label={i18next.t("last_name")}
            name="lastName"
            id="lastName"
            type="text"
            required
            value={this.state.lastName}
            onChange={this.onChange}
          />

          <TextInput
            label={i18next.t("username")}
            name="username"
            id="userName"
            required
            value={this.state.username}
            onChange={this.onChange}
          />

          <TextInput
            label={i18next.t("email")}
            name="email"
            id="email"
            type="email"
            required
            value={this.state.email}
            onChange={this.onChange}
            validator={validator.isEmail}
            errorMessage={{ validator: "Please enter a valid email" }}
          />

          <Select
            label={i18next.t("role")}
            options={
              rolesArray
                ? rolesArray.map((key, id) => {
                    return {
                      value: id + 2,
                      label: key,
                    };
                  })
                : []
            }
            name="roleId"
            id="roleId"
            value={this.state.roleId}
            onChange={this.onChange}
          />

          {!distributorHidden && (
            <Select
              label={i18next.t("selected_distributor")}
              options={
                distributors
                  ? distributors.map(({ name, id }) => {
                      return {
                        value: id,
                        label: name,
                      };
                    })
                  : []
              }
              name="distributorId"
              value={this.state.distributorId}
              onChange={this.onChange}
            />
          )}

          {!depotsHidden &&
            depots &&
            this.renderDepots(this.calculateDepotColumns(depots))}
        </Form>
      </>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.users,
  UsersStore.actionCreators,
)(EditUserForm);
