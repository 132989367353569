import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as POCStore from "../../store/POCReducer";
import Cookies from "universal-cookie";
import ModalSkuMapWrap from "./ModalSkuMapEditActionWrap";
import _ from "lodash";
import * as endpoints from "../constants/endpoints";
import styled from "styled-components";
import { Modal } from "reactstrap";
import Headline from "../Headline";

import { toast } from "react-toastify";
import i18next from "i18next";
import "react-datepicker/dist/react-datepicker.css";

const cookies = new Cookies();

interface ModalProps {
  selectedPocMapId?: any;
  closeModal?: any;
  isOpen?: any;
  collection?: any;
  selectedRows?: any;
  modalAction?: any;
}

class ModalSkuMapEdit extends React.Component<ModalProps> {
  state = {
    hasChanged: false,
    selectedHermesId: "",
    hermesCollectionIds: [],
    dateFrom: "",
    dateTo: "",
    disableBtn: false,
  };

  _isMounted = false;

  constructor(props) {
    super(props);

    this.createMapping = this.createMapping.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.props.collection[0].skuMapping !== null) {
      this.setState({
        dateFrom: this.props.collection[0].skuMapping.fromDate
          ? this.props.collection[0].skuMapping.fromDate
          : "",
        dateTo: this.props.collection[0].skuMapping.toDate
          ? this.props.collection[0].skuMapping.toDate
          : "",
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  closeModal = (shouldUpdate) => {
    this.props.closeModal(shouldUpdate);
  };

  getHermesSkuTableSettings = () => {
    return {
      tableID: "HermesSKU",
      keyField: "hermesId",
      tableColumns: [
        {
          key: "name",
          title: i18next.t("name"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "packaging",
          title: i18next.t("packaging"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "qty",
          title: i18next.t("count"),
          isFilterable: false,
          sortable: false,
        },
        {
          key: "volume",
          title: i18next.t("volume"),
          isFilterable: false,
          sortable: false,
        },
      ],
      tableCells: ["name", "packaging", "quantity", "volume"],
    };
  };

  emitHermesId = (hermesId) => {
    _.set(this.state, "selectedHermesId", hermesId);
  };

  deleteMapping = () => {
    const url = `${endpoints.POC_MAPPINGS_ENDPOINT}/${this.props.selectedPocMapId}`;

    if (window.confirm(i18next.t("confirm_delete"))) {
      fetch(url, {
        method: "delete",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${cookies.get("rawtoken")}`,
        },
      }).then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.setState(
            {
              hermesCollectionIds: [],
              hasChanged: true,
            },
            () => {
              this.closeModal(this.state.hasChanged);
            },
          );
        }
      });
    }
  };

  createMapping = () => {
    let action = null;
    let urlAddon = "";
    this.setState({
      disableBtn: true,
    });

    if (this.props.modalAction === "create") {
      action = "POST";
    } else {
      action = "PUT";
      urlAddon = `/${this.props.collection[0].skuMapping.id}`;
    }

    const url = `${endpoints.SKU_MAPPINGS_ENDPOINT}${urlAddon}`;

    let skuId;
    if (this.state.selectedHermesId === "") {
      skuId = this.props.collection[0].sku.id;
    } else {
      skuId = this.state.selectedHermesId;
    }

    const bodyCollection = this.props.collection.map((item, index) => {
      return {
        skuId: skuId,
        productId: this.props.collection[index].product.id,
        depotId: this.props.collection[index].product.distributorDepot.id,
        isFreeCase: this.props.collection[index].product.hasFreeCase,
        fromDate: this.state.dateFrom,
        toDate: this.state.dateTo,
      };
    });

    fetch(url, {
      method: action,
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${cookies.get("rawtoken")}`,
      },
      body: JSON.stringify(bodyCollection),
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.setState(
            {
              hasChanged: true,
              // disableBtn: false
            },
            () => {
              this.closeModal(this.state.hasChanged);
            },
          );

          toast.success(i18next.t("successful_mapping"));
        } else if (res.status === 409) {
          this.setState({
            disableBtn: false,
          });
          toast.error(i18next.t("mapping_conflict"));
        } else {
          this.setState({
            disableBtn: false,
          });
          toast.error(i18next.t("internal_server_error"));
        }
        // this.setState({
        // 	disableBtn: false
        // })
      })
      .catch((error) => {
        console.log(error);
      });
  };

  emitDate = (value, type) => {
    if (type === "dateFrom") {
      _.set(this.state, "dateFrom", value);
    } else {
      _.set(this.state, "dateTo", value);
    }
  };

  public render() {
    const ModalWrapper = styled.div`
      //button.disabled { opacity: 0.6; pointer-events: none;}
      .search {
        padding-top: 3px;
      }

      // .actions { display: none;}
      // .actions button { width: 33.33%;}
      // .actions .react-datepicker-wrapper { width: 30%}
    `;
    const TableDetails = styled.div`
      flex-grow: 1;

      table {
        background: #fff;
      }
      table,
      td,
      th {
        border: 1px solid #e2e2e2;
      }
      table {
        width: 100%;
        border-collapse: collapse;
      }

      th {
        text-align: left;
        font-size: 10px;
        padding: 5px 5px;
        font-weight: 500;
        color: #6d6d6d;
      }
      td {
        font-size: 10px;
        text-align: left;
        padding: 5px 5px;
        min-height: 40px;
      }

      button {
        font-size: 8px;
      }

      tr + tr {
        border-top: 1px solid #e2e2e2;
      }
    `;

    const Head = styled.div`
      padding: 12px 20px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    `;

    return (
      <ModalWrapper>
        <div className="modal-wrapper">
          <Modal isOpen={this.props.isOpen} scrollable={true}>
            <div className="modal-header">
              {this.props.modalAction === "edit" ? (
                <h5 className="modal-title mt-0">
                  {i18next.t("title_edit_sku_mapping")}
                </h5>
              ) : (
                <h5 className="modal-title mt-0">
                  {i18next.t("title_create_sku_mapping")}
                </h5>
              )}

              <button
                type="button"
                onClick={() => this.closeModal(false)}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <TableDetails>
                <Head>
                  <Headline disableMargin>
                    {i18next.t("selected_products")}
                  </Headline>
                </Head>

                <table>
                  <thead>
                    <tr>
                      <th>{i18next.t("depot_name")}</th>
                      <th>{i18next.t("code")}</th>
                      <th>{i18next.t("description")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.collection &&
                      this.props.collection.map((rowData, index) => (
                        <tr key={index}>
                          <td>{rowData.depotName}</td>
                          <td>{rowData.product.code}</td>
                          <td>{rowData.product.description}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </TableDetails>
              <ModalSkuMapWrap {...this.props}></ModalSkuMapWrap>
            </div>
          </Modal>
        </div>
      </ModalWrapper>
    );
  }
}
export default connect(
  (state: ApplicationState) => state.poc,
  POCStore.actionCreators,
)(ModalSkuMapEdit);
