import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FilterableTableContainer from "../Helpers/FilterableTableContainer";
import { ApplicationState } from "store";
import * as UsersStore from "../../store/UsersReducer";
import Popup from "reactjs-popup";
import ModalContent from "../Modal/ModalContent";
import Button from "../Button";
import * as endpoints from "../constants/endpoints";
import Cookies from "universal-cookie";
import { EditIcon, CloseIcon, CheckIcon, DeleteIcon, AddIcon } from "../../svg";
import i18next from "i18next";

const cookies = new Cookies();

const UsersList: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoading, userList } = useSelector(
    (state: ApplicationState) => state.users,
  );

  useEffect(() => {
    dispatch(UsersStore.actionCreators.getAction("allUsers"));
  }, [dispatch]);

  const handleDeleteUser = useCallback(
    (userId: number) => {
      let callback = () =>
        dispatch(UsersStore.actionCreators.getAction("allUsers"));
      dispatch(UsersStore.actionCreators.deleteUser(userId, callback));
    },
    [dispatch],
  );

  const handleToggleUserActive = useCallback(
    (user: any) => {
      const url = `${endpoints.USER_EDIT_IS_ACTIVE}/${
        user.userId
      }/${!user.isActive}`;
      fetch(url, {
        method: "post",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${cookies.get("rawtoken")}`,
        },
      })
        .then((response) => response.json())
        .then((editedUser) => {
          let callback = () =>
            dispatch(UsersStore.actionCreators.getAction("allUsers"));
          dispatch(UsersStore.actionCreators.editUser(editedUser, callback));
        });
    },
    [dispatch],
  );

  const editUser = (props: any) => {
    let linkTo = "/edit-user/" + props.record.userId;
    return (
      <>
        <Button
          icon={EditIcon}
          variant="outlined"
          onClick={() => history.push(linkTo)}
        />
        {props.record.isActive ? (
          <Button
            icon={CloseIcon}
            variant="outlined"
            onClick={() => handleToggleUserActive(props.record)}
          />
        ) : (
          <Button
            icon={CheckIcon}
            variant="outlined"
            onClick={() => handleToggleUserActive(props.record)}
          />
        )}
        <Popup
          modal
          trigger={
            <Button icon={DeleteIcon} color="danger" variant="outlined" />
          }
        >
          {(close) => (
            <ModalContent
              close={close}
              type="confirmDeletionModal"
              confirmDelete={() => handleDeleteUser(props.record.userId)}
            />
          )}
        </Popup>
      </>
    );
  };

  const isActiveCell = (props: any) => {
    return (
      <>{props.record.isActive ? <span>Active</span> : <span>Inactive</span>}</>
    );
  };

  let tableProps = {
    data: userList,
    fields: [
      {
        name: "firstName",
        displayName: i18next.t("name"),
        inputFilterable: true,
        sortable: true,
      },
      {
        name: "lastName",
        displayName: i18next.t("last_name"),
        inputFilterable: true,
        sortable: true,
      },
      {
        name: "username",
        displayName: i18next.t("username"),
        inputFilterable: true,
        sortable: true,
      },
      {
        name: "email",
        displayName: i18next.t("email"),
        inputFilterable: true,
        exactFilterable: true,
        sortable: true,
      },
      {
        name: "roleId",
        displayName: i18next.t("role"),
        inputFilterable: true,
        sortable: true,
      },
      {
        name: "isActive",
        displayName: i18next.t("active"),
        inputFilterable: true,
        sortable: true,
        render: isActiveCell,
      },
      { name: "edit", displayName: i18next.t("actions"), render: editUser },
    ],
    namespace: "Users",
    initialSort: "firstName",
    bsLength: 12,
    useEditAction: true,
    tableName: i18next.t("users_list"),
    useRoleFields: ["roleId"],
  };

  return (
    <FilterableTableContainer
      isLoading={isLoading}
      tableProps={tableProps}
      hasActions
      buttons={
        <>
          <Button
            size="large"
            icon={AddIcon}
            onClick={() => history.push("/add-user")}
          >
            {i18next.t("new_user")}
          </Button>
        </>
      }
    />
  );
};

export default UsersList;
