import React from "react";

import { Form, Modal, Input, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import i18next from "i18next";
import { toast } from "react-toastify";

import { useUpsertPackaging } from "api/reactQuery/queryComponents";
import {
  BSCoreServicesDtoMarketplacePackagingDto,
  BSDALMarketplaceEnumsPackagingType,
} from "api/reactQuery/querySchemas";

type Props = {
  isOpen: boolean;
  toggleModalOpen: () => void;
  onAfterSuccess?: () => void;
  packaging?: BSCoreServicesDtoMarketplacePackagingDto;
};

const requiredRule = { message: "Field required", required: true };

const CreateEditPackagingModal: React.FC<Props> = ({
  isOpen,
  toggleModalOpen,
  onAfterSuccess,
  packaging,
}) => {
  const { mutate } = useUpsertPackaging({
    onSuccess: () => {
      toast.success(
        packaging
          ? i18next.t("packaging_edited_successfully")
          : i18next.t("packaging_created_successfully"),
      );
      toggleModalOpen();
      onAfterSuccess();
    },
  });

  const [form] = useForm<BSCoreServicesDtoMarketplacePackagingDto>();

  const onFinish = (values: BSCoreServicesDtoMarketplacePackagingDto) => {
    form.isFieldsTouched()
      ? mutate({ body: { ...values, id: packaging?.id } })
      : toggleModalOpen();
  };

  return (
    <Modal
      open={isOpen}
      centered
      destroyOnClose
      onOk={() => form.submit()}
      onCancel={() => toggleModalOpen()}
      title={
        packaging ? i18next.t("edit_packaging") : i18next.t("create_packaging")
      }
    >
      <Form
        form={form}
        initialValues={packaging}
        preserve={false}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
      >
        <Form.Item<BSCoreServicesDtoMarketplacePackagingDto>
          label={i18next.t("name")}
          name="name"
          rules={[requiredRule]}
        >
          <Input />
        </Form.Item>
        <Form.Item<BSCoreServicesDtoMarketplacePackagingDto>
          label={i18next.t("price")}
          name="price"
          rules={[requiredRule]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item<BSCoreServicesDtoMarketplacePackagingDto>
          label={i18next.t("type")}
          name="type"
          rules={[requiredRule]}
        >
          <Select
            optionFilterProp="label"
            options={Object.values(BSDALMarketplaceEnumsPackagingType).map(
              (option, index) => ({
                label: option,
                value: index,
              }),
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default CreateEditPackagingModal;
