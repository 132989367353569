import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "store";
import * as DistributorsStore from "../../store/DistributorsReducer";
import Form from "../Form";
import TextInput from "../TextInput";
import Select from "../Select";
import Button from "../Button";
import { AddIcon, BackIcon, EditIcon } from "../../svg";
import "react-dropdown/style.css";
import i18next from "i18next";
import { MarketplaceDistributorPriceGroups } from "components/Utils/enums";

type DistributorProps = DistributorsStore.DistributorsState &
  typeof DistributorsStore.actionCreators &
  RouteComponentProps<{ distributorId: any }>;

class EditDistributorForm extends React.PureComponent<DistributorProps> {
  constructor(props) {
    super(props);
  }

  public state = {
    name: "",
    code: "",
    tenantId: "",
    hermesSupplierId: "",
    sapPayerId: "",
    officialDistributorName: "",
    priceGroup: 0,
  };

  componentDidMount() {
    const selectedDistributorId = this.props.match.params.distributorId;
    this.props.attemptEditDistributor(selectedDistributorId);
  }

  componentWillReceiveProps(nextProps, prevState) {
    var isEdit = this.props.match.params.distributorId;
    if (isEdit) {
      const { selectedDistributor } = nextProps;
      if (selectedDistributor) {
        this.setState({
          name: selectedDistributor["name"],
          code: selectedDistributor["code"],
          tenantId: selectedDistributor["tenantId"],
          hermesSupplierId: selectedDistributor["hermesSupplierId"],
          sapPayerId: selectedDistributor["sapPayerId"],
          priceGroup: selectedDistributor["priceGroup"],
          officialDistributorName:
            selectedDistributor["officialDistributorName"],
        });
      }
    }
  }

  public handleSubmit = (event) => {
    var isEdit = this.props.match.params.distributorId;
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    var editedDistributor = { ...this.props.selectedDistributor };
    editedDistributor.name = this.state.name;
    editedDistributor.code = this.state.code;
    editedDistributor.priceGroup = this.state.priceGroup;
    editedDistributor.tenantId = this.state.tenantId;
    editedDistributor.hermesSupplierId = this.state.hermesSupplierId;
    editedDistributor.sapPayerId = this.state.sapPayerId;
    editedDistributor.officialDistributorName =
      this.state.officialDistributorName;
    if (isEdit) {
      this.props.editDistributor(editedDistributor);
    } else {
      this.props.addDistributor(editedDistributor);
    }
    this.setState({
      validated: true,
    });
  };

  public onChange = (event) => {
    var validity = event.target.validity;
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  navigateBack = (event) => {
    this.props.history.push("/manage-distributors");
  };

  public render() {
    const { isLoading, suppliers } = this.props;

    var isEdit = this.props.match.params.distributorId;
    return (
      <Form
        {...{ isLoading }}
        title={
          isEdit
            ? i18next.t("edit_distributor")
            : i18next.t("create_distributor")
        }
        hasBox
        submitDisabled={Number(this.state.priceGroup) === 0}
        buttonSize="large"
        buttonIcon={isEdit ? AddIcon : EditIcon}
        additionalButton={
          <Button
            icon={BackIcon}
            size="large"
            variant="outlined"
            onClick={this.navigateBack}
          >
            {i18next.t("back")}
          </Button>
        }
        onSubmit={this.handleSubmit}
        buttonText={isEdit ? i18next.t("save") : i18next.t("create")}
        buttonJustify="space-between"
      >
        <TextInput
          label={i18next.t("name")}
          name="name"
          id="name"
          type="text"
          required
          value={this.state.name}
          onChange={this.onChange}
        />
        <TextInput
          label={i18next.t("code")}
          name="code"
          id="code"
          type="text"
          required
          value={this.state.code}
          onChange={this.onChange}
        />
        <TextInput
          label={i18next.t("official_distributor_name")}
          name="officialDistributorName"
          id="officialDistributorName"
          type="text"
          value={this.state.officialDistributorName}
          onChange={this.onChange}
        />
        <TextInput
          label={i18next.t("sap_payer_id")}
          name="sapPayerId"
          id="sapPayerId"
          type="text"
          value={this.state.sapPayerId}
          onChange={this.onChange}
        />
        <Select
          label={i18next.t("price_group")}
          hideLabelOption
          options={[
            { value: 0, label: "Please select price group", disabled: true },
            ...Object.values(MarketplaceDistributorPriceGroups).map(
              (priceGroup, i) => ({
                value: i + 1,
                label: priceGroup,
              }),
            ),
          ]}
          name="priceGroup"
          value={this.state.priceGroup}
          onChange={this.onChange}
        />
        <Select
          label={i18next.t("selected_supplier")}
          options={
            suppliers
              ? suppliers.map(({ name, id }) => {
                  return {
                    value: id,
                    label: name,
                  };
                })
              : []
          }
          name="hermesSupplierId"
          value={this.state.hermesSupplierId}
          onChange={this.onChange}
        />
      </Form>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.distributors,
  DistributorsStore.actionCreators,
)(EditDistributorForm);
