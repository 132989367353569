import * as WeatherForecasts from './WeatherForecasts';
import * as Counter from './Counter';
import * as AuthenticationReducer from './AuthenticationReducer';
import * as UsersReducer from './UsersReducer';
import * as SKUReducer from './SKUReducer';
import * as POCReducer from './POCReducer';
import * as OrdersReducer from './OrdersReducer';
import * as TenantsReducer from './TenantsReducer';
import * as DistributorsReducer from './DistributorsReducer';
import * as DistributorDepotsReducer from './DistributorDepotsReducer';
import * as ReceivedSalesFilesReducer from './ReceivedSalesFilesReducer';
import * as DepotRawDataReducer from './DepotRawDataReducer';
import * as WHSDataReportReducer from './WHSDataReportReducer';
import * as GeneratingSalesReportsReducer from './GeneratingSalesReportsReducer';
import * as WHSStockReportsReducer from './WHSStockReportsReducer';
import * as UploadedFilesReportsReducer from './UploadedFilesReportsReducer';
import * as CommentsReducer from './CommentsReducer';

// The top-level state object
export interface ApplicationState {
    counter: Counter.CounterState | undefined;
    weatherForecasts: WeatherForecasts.WeatherForecastsState | undefined;
    authentication: AuthenticationReducer.AuthenticationState | undefined;
    users: UsersReducer.UsersState | undefined;
    sku: SKUReducer.SKUState | undefined;
    poc: POCReducer.POCState | undefined;
    orders: OrdersReducer.OrdersState | undefined;
    tenants: TenantsReducer.TenantsState | undefined;
    distributors: DistributorsReducer.DistributorsState | undefined;
    distributorDepots: DistributorDepotsReducer.DistributorDepotsState | undefined;
    receivedSalesFiles: ReceivedSalesFilesReducer.SalesFilesState | undefined;
    depotRawDataReports: DepotRawDataReducer.DepotRawDataState | undefined;
    whsDataReports: WHSDataReportReducer.WHSDataReportState | undefined;
    generatingSalesReports: GeneratingSalesReportsReducer.GeneratingSalesReportsState | undefined;
    whsStockReports: WHSStockReportsReducer.WHSStockReportsState | undefined;
    uploadedFilesReports: UploadedFilesReportsReducer.UploadedFilesReportsState | undefined;
    comments: CommentsReducer.CommentsState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    counter: Counter.reducer,
    weatherForecasts: WeatherForecasts.reducer,
    authentication: AuthenticationReducer.reducer,
    users: UsersReducer.reducer,
    sku: SKUReducer.reducer,
    poc: POCReducer.reducer,
    orders: OrdersReducer.reducer,
    tenants: TenantsReducer.reducer,
    distributors: DistributorsReducer.reducer,
    distributorDepots: DistributorDepotsReducer.reducer,
    receivedSalesFiles: ReceivedSalesFilesReducer.reducer,
    depotRawDataReports: DepotRawDataReducer.reducer,
    whsDataReports: WHSDataReportReducer.reducer,
    generatingSalesReports: GeneratingSalesReportsReducer.reducer,
    whsStockReports: WHSStockReportsReducer.reducer,
    uploadedFilesReports: UploadedFilesReportsReducer.reducer,
    comments: CommentsReducer.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
