export const GET_DEPOTS_REGIONS_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + '';
export const GET_WHS_DATA_REPORT_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/Reports/action/WhsDataReport';
export const GET_DEPOTS_FOR_REGION_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/distributors/DistributorDepots/GetAllByRegion';
export const GET_RECEIVED_SALES_FILES_FOR_DEPOT_ID_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/distributors/DistributorFiles/GetDistributorFilesByFileTypeByDepotId?fileType=SALES';
export const DOWNLOAD_RECEIVED_SALES_FILES_REPORT_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/distributors/DistributorFiles/DownloadDistributorFileXml';
export const GET_RECEIVED_SALES_FILE_ENTRIES_FOR_FILE_ID_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/distributors/DistributorFileEntries/GetDistributorFileEntriesByDistributorFileId';
export const GET_SALES_STOCK_DATA_FOR_DEPOT_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/Reports/action/RawData/';
export const GENERATE_SALES_REPORT_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/Reports/action/WhsSaleData';
export const GENERATE_UPLOADED_FILES_REPORT_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/Distributors/DistributorFiles/GetByPeriod';
export const GENERATE_STOCKS_REPORT_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/Reports/action/WhsStockReport';
export const GET_SALES_REPORTS_LIST_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + '';
export const DOWNLOAD_GENERATED_SALES_REPORT_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + '';
export const MANAGE_DISTRIBUTOR_DEPOTS_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/distributors/DistributorDepots/';