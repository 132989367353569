import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components";

const Sizes = {
  large: css`
    padding: 10px 14px;
    font-size: 12px;

    svg {
      width: 16px;
      height: 16px;
      margin-right: ${({ hasText }) => (hasText ? "10px" : 0)};
    }

    &:hover {
      .bubble {
        height: 200px;
      }
    }
  `,
  small: css`
    padding: 8px 10px;
    font-size: 11px;

    svg {
      width: 12px;
      height: 12px;
      margin-right: ${({ hasText }) => (hasText ? "4px" : 0)};
    }

    &:hover {
      .bubble {
        height: 160px;
      }
    }
  `,
  extraLarge: css`
    padding: 15px 20px;
    font-size: 17px;
    min-width: 150px;

    &:hover {
      .bubble {
        height: 200px;
      }
    }
  `,
};

const Variants = {
  filled: css`
    border: 0;
    background: ${({ theme, color }) => theme.colors[color]};
    color: #fff;

    .bubble {
      background: ${({ theme }) => theme.colors.secondary};
    }

    svg {
      fill: #fff;
    }

    &:hover {
      color: #fff !important;

      svg {
        fill: #fff;
      }
    }
  `,
  outlined: css`
    color: ${({ theme, color }) => theme.colors[color]} !important;
    background: #fff;
    border: 1px solid ${({ theme, color }) => theme.colors[color]};

    .bubble {
      background: ${({ theme, color }) => theme.colors[color]};
    }

    svg {
      fill: ${({ theme, color }) => theme.colors[color]};
    }

    &:hover {
      color: #fff !important;

      svg {
        fill: #fff;
      }
    }
  `,
};

const Button = styled.button`
  position: relative;
  display: inline-block;
  border: 0;
  border-radius: 3px;
  line-height: 1 !important;
  font-weight: 500;
  overflow: hidden !important;
  transition: color ${({ theme }) => theme.transition.default};
  text-transform: uppercase;

  ${({ size }) => Sizes[size]}
  ${({ variant }) => Variants[variant]}

    &:focus,
    &:active {
    outline: none;
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  .bubble {
    position: absolute;
    width: 0;
    height: 0;
    display: block;
    border-radius: 50%;
    transition:
      width ${({ theme }) => theme.transition.default},
      height ${({ theme }) => theme.transition.default};
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .children {
    position: relative;
    z-index: 2;
  }

  svg {
    position: relative;
    transition: fill ${({ theme }) => theme.transition.default};
  }

  ${({ Icon, hasText }) =>
    Icon && hasText
      ? `
      .children {
        top: 2px;
      }

      svg {
        top: -2px;
      }
    `
      : ""}

  &:hover {
    .bubble {
      width: 225%;
    }
  }
`;

interface Props {
  as?: any;
  to?: string;
  color?: "default" | "danger" | "primary";
  variant?: "filled" | "outlined";
  size?: "small" | "large" | "extraLarge";
  children?: string;
  icon?: any;
  onClick?: any;
  type?: string;
  // All other props
  [x: string]: any;
}

const ButtonComponent: FunctionComponent<
  Props &
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
> = ({
  as,
  to,
  color = "default",
  variant = "filled",
  size = "small",
  children,
  icon: Icon,
  onClick,
  type,
  ...restProps
}) => {
  const onMouseEnter = (event) => {
    const $target = event.target,
      $animatedElement = $target.querySelector(".bubble"),
      rect = $target.getBoundingClientRect(),
      offset = {
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
      },
      relX = event.pageX - offset.left,
      relY = event.pageY - offset.top;

    if ($animatedElement) {
      $animatedElement.setAttribute(
        "style",
        `top: ${relY}px; left: ${relX}px;`,
      );
    }
  };

  return (
    <Button
      {...{
        as,
        to,
        color,
        variant,
        size,
        Icon,
        onClick,
        type,
      }}
      hasText={Boolean(children)}
      onMouseEnter={(ev) => onMouseEnter(ev)}
      {...restProps}
    >
      <span className="children">
        {Icon && <Icon />} {children}
      </span>
      <span className="bubble" />
    </Button>
  );
};

export default ButtonComponent;
