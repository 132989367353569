import * as React from "react";
import { connect, Provider } from "react-redux";
import styled from "styled-components";
import { ApplicationState } from "../../store";
import * as POCStore from "../../store/POCReducer";
import { RouteComponentProps } from "react-router-dom";
import $ from "jquery";
import Button from "../Button";
import { AddIcon } from "../../svg";
import Table from "../Table";
import _ from "lodash";
import * as endpoints from "../constants/endpoints";
import loader from "../../images/spinner.gif";
import HeadlineComponent from "../Headline";
import i18next from "i18next";
import Cookies from "universal-cookie";
const cookies = new Cookies();

// At runtime, Redux will merge together...
type POCProps = POCStore.POCState & // ... state we've requested from the Redux store
  typeof POCStore.actionCreators & // ... plus action creators we've requested
  RouteComponentProps<{ location: any }>; // ... plus incoming routing parameters

class CreateMappingPOC extends React.Component<POCProps> {
  state = {
    selectedDistributor: { code: "" },
    selectedCustomerData: "",
    selectedDepo: {},
    depots: [],
    depotsLoaded: false,
    // data and settings for distributor
    dataDistributors: [],
    distributorCustomerCollectionIds: [],
    distributorCustomerCollection: [],
    distributorsTotalSize: null,
    distributorItemsPerPage: 5,
    distributorCurrentPage: 1,
    isDistributorLoading: true,
    distributorOrderBy: "name",
    distributorOrderDirection: "asc",
    distributorCustomerIds: "",
    distributorFilters: {
      depotName: "",
      code: "",
      name: "",
      townOfDispatching: "",
      addressOfDispatching: "",
      vat: "",
      searchValue: "",
    },

    // data and settings for hermes
    dataHermes: [],
    hermesCollectionIds: [],
    hermesCollection: [],
    hermesTotalSize: null,
    hermesItemsPerPage: 10,
    hermesCurrentPage: 1,
    isHermesLoading: true,
    hermesOrderBy: "pocName",
    hermesOrderDirection: "asc",
    selectedHermesId: "",
    hermesFilters: {
      hermesId: "",
      pocName: "",
      pocStatus: "",
      city: "",
      street: "",
      houseNumber: "",
      hermesCustomerName: "",
      customerFiscalCode: "",
      rank: "",
      searchValue: "",
    },
    showOvarlayLoader: false,
  };
  _isMounted = false;

  constructor(props) {
    super(props);
    localStorage.setItem("selectedCustomer", "");
    this.onSorterClick = this.onSorterClick.bind(this);
    this.paginationUpdated = this.paginationUpdated.bind(this);
    this.emitSelectedIds = this.emitSelectedIds.bind(this);
    this.emitFilterValue = this.emitFilterValue.bind(this);
    this.emitSelectedDepo = this.emitSelectedDepo.bind(this);

    this.getDistributorCustomers = this.getDistributorCustomers.bind(this);
    this.getHermesData = this.getHermesData.bind(this);
    this.getAllDepo = this.getAllDepo.bind(this);
    this.onFilterDistributor = _.debounce(this.getDistributorCustomers, 2000);
    this.onFilterHermes = _.debounce(this.getHermesData, 2000);
    this.createPocMapping = this.createPocMapping.bind(this);
    this.getHermesObjById = this.getHermesObjById.bind(this);
    this.getDistributorObjById = this.getDistributorObjById.bind(this);
    this.emitExportDistributorData = this.emitExportDistributorData.bind(this);
    this.emitExportHermesData = this.emitExportHermesData.bind(this);
  }

  componentDidMount() {
    this.getAllDepo();

    this._isMounted = true;

    if (this._isMounted) {
      this.getDistributorCustomers();
      this.getHermesData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getHermesObjById = (id) => {
    const collection = this.state.dataHermes;
    const indexOf = collection.findIndex((item) => item.id === id);
    return collection[`${indexOf}`];
  };

  getDistributorObjById = (id) => {
    const collection = this.state.dataDistributors;
    const indexOf = collection.findIndex((item) => item.id === id);
    return collection[`${indexOf}`];
  };

  getDistributorCustomers = () => {
    this.setState({ isDistributorLoading: true });
    const url = `${endpoints.DISTRIBUTOR_CUSTOMERS_ENDPOINT}/Page`;

    fetch(url, {
      method: "post",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${cookies.get("rawtoken")}`,
      },
      body: JSON.stringify({
        page: this.state.distributorCurrentPage - 1,
        pageSize: this.state.distributorItemsPerPage,
        orderBy: this.state.distributorOrderBy, // name
        orderDirection: this.state.distributorOrderDirection, // asc
        filters: {
          depotName: this.state.distributorFilters.depotName,
          code: this.state.distributorFilters.code,
          name: this.state.distributorFilters.name,
          townOfDispatching: this.state.distributorFilters.townOfDispatching,
          addressNumberOfDispatching:
            this.state.distributorFilters.addressOfDispatching,
          vat: this.state.distributorFilters.vat,
          searchValue: this.state.distributorFilters.searchValue,
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          distributorsTotalSize: res.dataTotalSize,
          dataDistributors: res.data,
          isDistributorLoading: false,
        });
      });
  };

  getHermesData = () => {
    this.setState({ isHermesLoading: true });
    const url = `${endpoints.HERMES_POC_ENDPOINT}/Page`;
    fetch(url, {
      method: "post",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${cookies.get("rawtoken")}`,
      },
      body: JSON.stringify({
        page: this.state.hermesCurrentPage - 1,
        pageSize: this.state.hermesItemsPerPage,
        orderBy: this.state.hermesOrderBy, // pocName
        orderDirection: this.state.hermesOrderDirection, // asc
        filters: {
          hermesId: this.state.hermesFilters.hermesId,
          pocName: this.state.hermesFilters.pocName,
          pocStatus: this.state.hermesFilters.pocStatus,
          city: this.state.hermesFilters.city,
          street: this.state.hermesFilters.street,
          houseNumber: this.state.hermesFilters.houseNumber,
          hermesCustomerName: this.state.hermesFilters.hermesCustomerName,
          customerFiscalCode: this.state.hermesFilters.customerFiscalCode,
          rank: this.state.hermesFilters.rank,
          searchValue: this.state.hermesFilters.searchValue,
        },
        distributorCustomerIds: this.state.distributorCustomerIds,
        selectedId: 0,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          hermesTotalSize: res.dataTotalSize,
          dataHermes: res.data,
          isHermesLoading: false,
        });
      });
  };

  getAllDepo = () => {
    const url = `${endpoints.MANAGE_DISTRIBUTOR_DEPOTS_ENDPOINT}`;
    fetch(url, {
      method: "get",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${cookies.get("rawtoken")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        //console.log(res)
        this.setState({ depots: res, depotsLoaded: true });
      });
  };

  getDistributorCustomersTableSettings = () => {
    return {
      tableColumns: [
        {
          key: "depotName",
          title: i18next.t("depot_name"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "code",
          title: i18next.t("code"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "name",
          title: i18next.t("Name"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "townOfDispatching",
          title: i18next.t("city"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "addressOfDispatching",
          title: i18next.t("address"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "vat",
          title: i18next.t("bulstat"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "comment",
          title: i18next.t("comments"),
          filter: null,
          isFilterable: false,
        },
      ],
      tableCells: [
        "depotName",
        "code",
        "name",
        "townOfDispatching",
        "addressOfDispatching",
        "vat",
      ],
      searchSuccess: true,
      dateCreated: "0001-01-01T00:00:00",
      dateUpdated: "0001-01-01T00:00:00",
    };
  };

  getHermesPocsTableSettings = () => {
    return {
      tableColumns: [
        {
          key: "hermesId",
          title: i18next.t("code"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "pocName",
          title: i18next.t("name"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "pocStatus",
          title: i18next.t("status"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "city",
          title: i18next.t("city"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "street",
          title: i18next.t("address"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "houseNumber",
          title: i18next.t("number"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "hermesCustomerName",
          title: i18next.t("client"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "customerFiscalCode",
          title: i18next.t("bulstat"),
          isFilterable: true,
          sortable: true,
        },
        {
          key: "rank",
          title: i18next.t("p%"),
          isFilterable: true,
          sortable: true,
        },
      ],
      tableCells: [
        "hermesId",
        "pocName",
        "pocStatus",
        "city",
        "street",
        "houseNumber",
        "hermesCustomerName",
        "customerFiscalCode",
        "rank",
      ],
    };
  };

  onSorterClick(label, direction, type) {
    if (type === "distributor") {
      this.setState(
        {
          distributorOrderBy: label,
          distributorOrderDirection: direction,
        },
        () => this.getDistributorCustomers(),
      );
    } else {
      this.setState(
        {
          hermesOrderBy: label,
          hermesOrderDirection: direction,
        },
        () => this.getHermesData(),
      );
    }
  }

  emitSelectedIds(array, type) {
    if (type === "distributor") {
      if (array.length === 0) {
        _.set(this.state, "distributorCustomerIds", []);
        _.set(this.state, "distributorCustomer", []);
        _.set(this.state, "distributorCustomerCollectionIds", []);
        _.set(this.state, "distributorCustomerCollection", []);
        _.set(this.state, "hermesOrderBy", "pocName");
        _.set(this.state, "hermesOrderDirection", "asc");
        _.set(this.state, "selectedHermesId", []);
        _.set(this.state, "hermesCollectionIds", []);
        _.set(this.state, "hermesCurrentPage", 1);

        const that = this;

        setTimeout(function () {
          that.getHermesData();
        }, 1000);
      } else {
        const newCollection = [];
        array.map((item) => {
          newCollection.push(this.getDistributorObjById(item));
        });

        _.set(this.state, "distributorCustomerIds", [...array]);
        _.set(this.state, "distributorCustomerCollectionIds", [...array]);
        _.set(this.state, "distributorCustomerCollection", newCollection);
        _.set(this.state, "hermesOrderBy", "rank");
        _.set(this.state, "hermesOrderDirection", "desc");
        _.set(this.state, "selectedHermesId", "");
        _.set(this.state, "hermesCollectionIds", []);
        _.set(this.state, "hermesCollection", []);
        _.set(this.state, "hermesCurrentPage", 1);

        const that = this;

        setTimeout(function () {
          that.getHermesData();
        }, 1000);
      }
    } else {
      if (array.length === 0) {
        _.set(this.state, "selectedHermesId", "");
        _.set(this.state, "hermesCollectionIds", []);
        this.setState({
          hermesCollection: [],
        });
      } else {
        const newCollection = [];
        array.map((item) => {
          newCollection.push(this.getHermesObjById(item));
        });

        _.set(this.state, "selectedHermesId", array[0]);
        _.set(this.state, "hermesCollectionIds", array);

        this.setState({
          hermesCollection: newCollection,
        });
      }
    }
  }

  emitFilterValue(value, key, type) {
    if (type === "distributor") {
      console.log(key, value);
      _.set(this.state, `distributorFilters.${key}`, value);
      _.set(this.state, "distributorCurrentPage", 1);

      this.onFilterDistributor();
    } else {
      console.log(key, value);
      _.set(this.state, `hermesFilters.${key}`, value);
      _.set(this.state, "hermesCurrentPage", 1);

      this.onFilterHermes();
    }
  }

  emitSelectedDepo(obj) {
    _.set(this.state, "selectedDepo", obj);
  }

  onFilterDistributor() {
    this.getDistributorCustomers();
  }

  onFilterHermes() {
    this.getHermesData();
  }

  paginationUpdated(itemsPerTable, currentPage, type) {
    if (type === "distributor") {
      this.setState(
        {
          distributorItemsPerPage: itemsPerTable,
          distributorCurrentPage: currentPage,
        },
        () => this.getDistributorCustomers(),
      );
    } else {
      this.setState(
        {
          hermesItemsPerPage: itemsPerTable,
          hermesCurrentPage: currentPage,
        },
        () => this.getHermesData(),
      );
    }
  }

  createPocMapping() {
    const url = `${endpoints.POC_MAPPINGS_ENDPOINT}`;
    const findDistributorIndex = this.state.dataDistributors.findIndex(
      (item) => item.id === this.state.distributorCustomerIds[0],
    );
    const distributorObj = this.state.dataDistributors[findDistributorIndex];

    fetch(url, {
      method: "post",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${cookies.get("rawtoken")}`,
      },
      body: JSON.stringify({
        pocId: this.state.selectedHermesId,
        customerId: this.state.distributorCustomerIds[0],
        depotId: distributorObj.depotId,
        fromDate: null,
        toDate: null,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        this.getDistributorCustomers();
        this.getHermesData();

        this.setState({
          distributorCustomerCollectionIds: [],
          distributorCustomerIds: [],
          hermesCollectionIds: [],
          selectedHermesId: "",
          distributorCustomerCollection: [],
          hermesCollection: [],
        });
      });
  }

  emitExportDistributorData() {
    this.setState({ showOvarlayLoader: true });
    const url = `${endpoints.DISTRIBUTOR_CUSTOMERS_ENDPOINT}/exportExcel`;

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${cookies.get("rawtoken")}`,
      },
      body: JSON.stringify({
        depotName: this.state.distributorFilters.depotName,
        code: this.state.distributorFilters.code,
        name: this.state.distributorFilters.name,
        townOfDispatching: this.state.distributorFilters.townOfDispatching,
        addressNumberOfDispatching:
          this.state.distributorFilters.addressOfDispatching,
        vat: this.state.distributorFilters.vat,
        searchValue: this.state.distributorFilters.searchValue,
      }),
    })
      .then((res) => res.blob())
      .then((blob) => {
        this.setState({ showOvarlayLoader: false });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = `distributor_data.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  emitExportHermesData() {
    this.setState({ showOvarlayLoader: true });
    const url = `${endpoints.HERMES_POC_ENDPOINT}/exportExcel`;

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${cookies.get("rawtoken")}`,
      },
      body: JSON.stringify({
        distributorCustomerIds: this.state.distributorCustomerIds,
        filters: {
          hermesId: this.state.hermesFilters.hermesId,
          pocName: this.state.hermesFilters.pocName,
          pocStatus: this.state.hermesFilters.pocStatus,
          city: this.state.hermesFilters.city,
          street: this.state.hermesFilters.street,
          houseNumber: this.state.hermesFilters.houseNumber,
          hermesCustomerName: this.state.hermesFilters.hermesCustomerName,
          customerFiscalCode: this.state.hermesFilters.customerFiscalCode,
          rank: this.state.hermesFilters.rank,
          searchValue: this.state.hermesFilters.searchValue,
        },
      }),
    })
      .then((res) => res.blob())
      .then((blob) => {
        this.setState({ showOvarlayLoader: false });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = `hermes_data.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  public render() {
    const PageWrapper = styled.div`
      button.disabled {
        opacity: 0.6;
        pointer-events: none;
      }

      .search {
        padding-top: 3px;
      }

      .ovarlay-loader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.15);
        z-index: 12;
      }
    `;

    const TableDetails = styled.div`
      flex-grow: 1;
      padding-right: 50px;

      table {
        background: #fff;
      }
      table,
      td,
      th {
        border: 1px solid #e2e2e2;
      }
      table {
        width: 100%;
        border-collapse: collapse;
      }

      th {
        text-align: left;
        font-size: 10px;
        padding: 5px 5px;
        font-weight: 500;
        color: #6d6d6d;
      }
      td {
        font-size: 10px;
        text-align: left;
        padding: 5px 5px;
        min-height: 40px;
      }

      button {
        font-size: 8px;
      }

      tr + tr {
        border-top: 1px solid #e2e2e2;
      }
    `;

    return (
      <PageWrapper>
        <div id="poc-mappings-container">
          <HeadlineComponent disableMargin={false}>
            {i18next.t("title_create_poc_mapping")}
          </HeadlineComponent>

          {this.state.depotsLoaded && (
            <Table
              type="distributor"
              title={i18next.t("title_distributor")}
              tableSettings={this.getDistributorCustomersTableSettings()}
              data={this.state.dataDistributors}
              onSorterClick={this.onSorterClick}
              allowMultipleSelection={false}
              paginationUpdated={this.paginationUpdated}
              totalItems={
                this.state.distributorsTotalSize
                  ? this.state.distributorsTotalSize
                  : 0
              }
              currentPage={this.state.distributorCurrentPage}
              itemsPerPage={this.state.distributorItemsPerPage}
              isLoading={this.state.isDistributorLoading}
              emitSelectedIds={this.emitSelectedIds}
              emitFilterValue={this.emitFilterValue}
              emitSelectedDepo={this.emitSelectedDepo}
              filters={this.state.distributorFilters}
              currentSelectedFilter={this.state.distributorOrderBy}
              currentSortingDirection={this.state.distributorOrderDirection}
              selectedRows={this.state.distributorCustomerCollectionIds}
              selectedDepo={this.state.selectedDepo}
              showMainAutocomplete={true}
              depots={this.state.depots}
              depotsLoaded={this.state.depotsLoaded}
              emitExportData={this.emitExportDistributorData}
              showActionCell={true}
              showCommentsBtn={true}
              commentUrl={"/comments/poc/"}
            />
          )}

          <div className="d-flex align-items-center justify-content-between">
            <TableDetails>
              {this.state.distributorCustomerIds !== "" && (
                <table>
                  {this.state.distributorCustomerCollection.length > 0 &&
                    this.state.distributorCustomerCollection.map(
                      (rowData, index) => (
                        <tr>
                          <th>{i18next.t("distributor")}</th>
                          <td>{rowData.code}</td>
                          <td>{rowData.name}</td>
                          <td>{rowData.townOfDispatching}</td>
                          <td>{rowData.addressOfDispatching}</td>
                          <td>{rowData.vat}</td>
                        </tr>
                      ),
                    )}

                  {this.state.distributorCustomerCollection.length > 0 && (
                    <tr>
                      <th>Kamenitza</th>
                      <td>
                        {this.state.hermesCollection[0]
                          ? this.state.hermesCollection[0].hermesId
                          : ""}
                      </td>
                      <td>
                        {this.state.hermesCollection[0]
                          ? this.state.hermesCollection[0].pocName
                          : ""}
                      </td>
                      <td>
                        {this.state.hermesCollection[0]
                          ? this.state.hermesCollection[0].city
                          : ""}
                      </td>
                      <td>
                        {this.state.hermesCollection[0]
                          ? this.state.hermesCollection[0].street
                          : ""}
                      </td>
                      <td>
                        {this.state.hermesCollection[0]
                          ? this.state.hermesCollection[0].customerFiscalCode
                          : ""}
                      </td>
                    </tr>
                  )}
                </table>
              )}
            </TableDetails>

            <div className="actions d-flex justify-content-end pt-4 pb-4">
              <Button
                icon={AddIcon}
                size="large"
                className={
                  this.state.selectedHermesId !== "" &&
                  this.state.distributorCustomerIds !== ""
                    ? "active"
                    : "disabled"
                }
                id="create-map-btn"
                onClick={this.createPocMapping}
              >
                {i18next.t("map")}
              </Button>
            </div>
          </div>

          <Table
            type="hermes"
            title={i18next.t("title_hermes")}
            tableSettings={this.getHermesPocsTableSettings()}
            data={this.state.dataHermes}
            onSorterClick={this.onSorterClick}
            allowMultipleSelection={false}
            paginationUpdated={this.paginationUpdated}
            totalItems={
              this.state.hermesTotalSize ? this.state.hermesTotalSize : 0
            }
            currentPage={this.state.hermesCurrentPage}
            itemsPerPage={this.state.hermesItemsPerPage}
            isLoading={this.state.isHermesLoading}
            emitSelectedIds={this.emitSelectedIds}
            emitFilterValue={this.emitFilterValue}
            filters={this.state.hermesFilters}
            selectedRows={this.state.hermesCollectionIds}
            currentSelectedFilter={this.state.hermesOrderBy}
            currentSortingDirection={this.state.hermesOrderDirection}
            emitExportData={this.emitExportHermesData}
          />

          {this.state.showOvarlayLoader === true && (
            <div
              className="d-flex align-items-center justify-content-center ovarlay-loader"
              style={{ minHeight: "300px" }}
            >
              <div className="text-center">
                {i18next.t("downloading")} <br />
                <img src={loader} alt="" />
              </div>
            </div>
          )}
        </div>
      </PageWrapper>
    );
  }
}
export default connect(
  (state: ApplicationState) => state.poc,
  POCStore.actionCreators,
)(CreateMappingPOC);
