import React, { useEffect, useState } from "react";

import i18next from "i18next";

import { useGetAllPallets } from "api/reactQuery/queryComponents";
import { BSCoreServicesDtoMarketplacePalletDto } from "api/reactQuery/querySchemas";
import Button from "components/Button";
import FilterableTableContainer from "components/Helpers/FilterableTableContainer";
import CreateEditPalletModal from "components/Pallets/CreateEditPalletModal";
import { MarketplacePalletsTypes } from "components/Utils/enums";
import { AddIcon, EditIcon } from "svg";

const Pallets: React.FC = () => {
  const { data: pallets, refetch, isLoading } = useGetAllPallets({});
  const [modalOpen, setModalOpen] = useState(false);
  const [palletForEdit, setPalletForEdit] =
    useState<BSCoreServicesDtoMarketplacePalletDto>();
  const toggleModalOpen = () => {
    modalOpen && palletForEdit && setPalletForEdit(undefined);
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    palletForEdit && !modalOpen && setModalOpen(true);
  }, [palletForEdit]);

  const tableProps = {
    bsLength: 12,
    data: pallets,
    fields: [
      {
        displayName: i18next.t("barcode"),
        inputFilterable: true,
        name: "barcode",
        sortable: true,
      },
      {
        displayName: i18next.t("type"),
        inputFilterable: true,
        name: "type",
        render: (props) => MarketplacePalletsTypes[props?.value],
        sortable: true,
      },
      {
        displayName: i18next.t("price"),
        inputFilterable: true,
        name: "price",
        sortable: true,
      },
      {
        displayName: i18next.t("weight"),
        inputFilterable: true,
        name: "weight",
        sortable: true,
      },
      {
        displayName: i18next.t("actions"),
        name: "edit",
        render: (props) => (
          <Button
            icon={EditIcon}
            variant="outlined"
            onClick={() => setPalletForEdit(props.record)}
          />
        ),
      },
    ],
    tableName: i18next.t("pallets"),
  };

  return (
    <div style={{ paddingTop: 20 }}>
      <FilterableTableContainer
        {...{ isLoading: isLoading }}
        hasActions
        tableProps={tableProps}
        buttons={
          <Button size="large" icon={AddIcon} onClick={toggleModalOpen}>
            {i18next.t("create_pallet")}
          </Button>
        }
      />
      <CreateEditPalletModal
        isOpen={modalOpen}
        toggleModalOpen={toggleModalOpen}
        onAfterSuccess={refetch}
        pallet={palletForEdit}
      />
    </div>
  );
};
export default Pallets;
