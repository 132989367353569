import React, { useMemo, useRef, useState } from "react";

import { ColDef, GetRowIdParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Modal } from "antd";
import i18next from "i18next";
import { toast } from "react-toastify";

import {
  useGetAllDistributorsForInvoiceProduct,
  useUpsertInvoiceProductDistributor,
} from "api/reactQuery/queryComponents";
import {
  BSCoreServicesDtoMarketplaceInvoiceProductDistributorDto,
  BSCoreServicesDtoMarketplaceProductDistributorDto,
  BSCoreServicesDtoMarketplaceProductDto,
} from "api/reactQuery/querySchemas";
import { getGridDefaultProps } from "constants/agGridConstants";

type Props = {
  isOpen: boolean;
  toggleModalOpen: () => void;
  productId: BSCoreServicesDtoMarketplaceProductDto["id"];
};

const OnInvoiceConfigModal: React.FC<Props> = ({
  isOpen,
  toggleModalOpen,
  productId,
}) => {
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const { data } = useGetAllDistributorsForInvoiceProduct(
    {
      queryParams: { productId },
    },
    { enabled: !!productId && isOpen },
  );
  const gridRef =
    useRef<
      AgGridReact<BSCoreServicesDtoMarketplaceInvoiceProductDistributorDto>
    >(null);

  const { mutate } = useUpsertInvoiceProductDistributor({
    onError: () => {
      toast.error("Something went wrong");
    },
    onSuccess: () => {
      toast.success("Configuration updated successfully");
      toggleModalOpen();
    },
  });
  const columnDefs = useMemo<
    ColDef<BSCoreServicesDtoMarketplaceInvoiceProductDistributorDto>[]
  >(
    () => [
      {
        field: "distributorName",
        flex: 2,
        headerName: i18next.t("distributor"),
      },
      {
        field: "distributorDepotName",
        flex: 2,
        headerName: i18next.t("depot"),
      },
      {
        editable: true,
        field: "monthlyLimit",
        headerName: i18next.t("monthly_limit"),
      },
    ],
    [],
  );

  const onSaveHandler = () => {
    const allRowData: BSCoreServicesDtoMarketplaceProductDistributorDto[] = [];
    gridRef?.current?.api?.forEachNode((node) => allRowData.push(node.data));

    allRowData.length &&
      mutate({ body: allRowData, queryParams: { productId } });
  };

  return (
    <Modal
      open={isOpen}
      centered
      destroyOnClose
      afterOpenChange={(open) => !open && setIsSaveDisabled(true)}
      okText="Save"
      onOk={onSaveHandler}
      okButtonProps={{ disabled: isSaveDisabled }}
      onCancel={toggleModalOpen}
      width="50%"
      styles={{ body: { height: "70vh" } }}
      title="On Invoice Configuration"
    >
      <AgGridReact
        ref={gridRef}
        onCellValueChanged={() => setIsSaveDisabled(false)}
        getRowId={(
          params: GetRowIdParams<BSCoreServicesDtoMarketplaceInvoiceProductDistributorDto>,
        ) => String(params.data.distributorId)}
        rowData={data}
        domLayout="normal"
        singleClickEdit
        columnDefs={columnDefs}
        {...getGridDefaultProps()}
      />
    </Modal>
  );
};
export default OnInvoiceConfigModal;
