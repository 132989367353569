import { Button, DatePicker, Flex, Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { DownloadOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import {
  useGetDifferenceReport,
  useGetOnTimeDeliveryReport,
  useGetRealOrderReport,
} from "api/reactQuery/queryComponents";
import { dateFormat, formatDateForBE } from "components/Utils/dayjsUtils";
import { downloadBlob } from "components/Utils/actions";
import i18next from "i18next";

const MarketplaceReports: React.FC = () => {
  const [startDate, setStartDate] = useState<Dayjs>(dayjs);
  const [endDate, setEndDate] = useState<Dayjs>(dayjs);

  const { mutate: getOnTimeDeliveryReport, isLoading: onTimeDeliveryLoading } =
    useGetOnTimeDeliveryReport({
      onSuccess: (data) =>
        downloadBlob(
          data as Blob,
          `On_time_delivery_report_${dayjs(startDate).format("DD_MM_YY")}-${dayjs(endDate).format("DD_MM_YY")}.xlsx`,
        ),
    });
  const { mutate: getRealOrderReport, isLoading: realOrderReportsLoading } =
    useGetRealOrderReport({
      onSuccess: (data) =>
        downloadBlob(
          data as Blob,
          `Real_order_report_${dayjs(startDate).format("DD_MM_YY")}-${dayjs(endDate).format("DD_MM_YY")}.xlsx`,
        ),
    });
  const { mutate: getDifferenceReport, isLoading: differenceReportLoading } =
    useGetDifferenceReport({
      onSuccess: (data) =>
        downloadBlob(
          data as Blob,
          `Demand_diff_report_${dayjs(startDate).format("DD_MM_YY")}-${dayjs(endDate).format("DD_MM_YY")}.xlsx`,
        ),
    });

  return (
    <>
      <Typography.Title level={3} style={{ marginBottom: 20 }}>
        {i18next.t("marketplace_reports")}
      </Typography.Title>
      <Flex align="center" justify="space-between">
        <div>
          <Typography.Text style={{ marginRight: 10 }}>
            {i18next.t("select_period")}
          </Typography.Text>
          <DatePicker.RangePicker
            format={dateFormat}
            maxDate={dayjs()}
            defaultValue={[startDate, endDate]}
            onChange={(dates) => {
              setStartDate(dates[0]);
              setEndDate(dates[1]);
            }}
          />
        </div>
        <div>
          <Button
            style={{ padding: "0 50px", marginRight: 10 }}
            type="primary"
            disabled={!startDate || !endDate}
            loading={differenceReportLoading}
            onClick={() =>
              getDifferenceReport({
                queryParams: {
                  startDate: formatDateForBE(startDate),
                  endDate: formatDateForBE(endDate),
                },
              })
            }
          >
            <DownloadOutlined style={{ verticalAlign: "middle" }} />
            {i18next.t("demand_diff_report")}
          </Button>
          <Button
            style={{ padding: "0 50px", marginRight: 10 }}
            type="primary"
            disabled={!startDate || !endDate}
            loading={onTimeDeliveryLoading}
            onClick={() =>
              getOnTimeDeliveryReport({
                queryParams: {
                  startDate: formatDateForBE(startDate),
                  endDate: formatDateForBE(endDate),
                },
              })
            }
          >
            <DownloadOutlined style={{ verticalAlign: "middle" }} />
            {i18next.t("on_time_delivery_report")}
          </Button>
          <Button
            style={{ padding: "0 50px", marginRight: 10 }}
            type="primary"
            disabled={!startDate || !endDate}
            loading={realOrderReportsLoading}
            onClick={() =>
              getRealOrderReport({
                queryParams: {
                  startDate: formatDateForBE(startDate),
                  endDate: formatDateForBE(endDate),
                },
              })
            }
          >
            <DownloadOutlined style={{ verticalAlign: "middle" }} />
            {i18next.t("real_order_report")}
          </Button>
        </div>
      </Flex>
    </>
  );
};
export default MarketplaceReports;
