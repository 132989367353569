import React from "react";

import { Button, Col, DatePicker, Row, Select, Statistic } from "antd";
import dayjs, { Dayjs } from "dayjs";
// import { useDispatch } from "react-redux";
import i18next from "i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import {
  useGetMpDistributorDepots,
  useGetMpDistributors,
} from "api/reactQuery/queryComponents";
import {
  BSCoreWebRequestsResponsesDistributorsDistributorDepotResponse,
  BSCoreWebRequestsResponsesDistributorsDistributorResponse,
} from "api/reactQuery/querySchemas";
// import * as AuthenticationStore from "store/AuthenticationReducer";
import { LocationIcon } from "svg";

const OrderInfoLabel = styled.p`
  color: #6d6d6d;
  font-size: 12px;
  line-height: 20px;
`;
const OrderInfoValue = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
`;
const CountdownContainer = styled.div`
  position: sticky;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 5;
  top: 55px;
  margin-bottom: 10px;
  background: ${({ theme }) => theme.colors.semiLight};

  .ant-statistic-content {
    font-size: 28px;
    color: #d21919;
    padding: 3px 25px;
    background-color: #fed9d9;
    border-radius: 6px;
    font-family: Monospaced, math;
  }
`;

type Props = {
  resetCalculatedData: () => void;
  selectedDistributor: BSCoreWebRequestsResponsesDistributorsDistributorResponse;
  setSelectedDistributor: React.Dispatch<
    React.SetStateAction<BSCoreWebRequestsResponsesDistributorsDistributorResponse>
  >;
  selectedDepot: BSCoreWebRequestsResponsesDistributorsDistributorDepotResponse;
  setSelectedDepot: React.Dispatch<
    React.SetStateAction<BSCoreWebRequestsResponsesDistributorsDistributorDepotResponse>
  >;
  deliveryDate: Dayjs;
  setDeliveryDate: React.Dispatch<React.SetStateAction<Dayjs>>;
  onSaveAndSend: () => void;
  saveAndSendDisabled: boolean;
  saveAndSendLoading: boolean;
  initialOrderLoading: boolean;
  showCountdown: boolean;
  dateCreated: Dayjs;
};

const ORDER_TIME_IN_MINUTES = 60;

const OrderInfoSection: React.FC<Props> = ({
  resetCalculatedData,
  selectedDepot,
  setSelectedDistributor,
  selectedDistributor,
  setSelectedDepot,
  setDeliveryDate,
  deliveryDate,
  onSaveAndSend,
  saveAndSendDisabled,
  saveAndSendLoading,
  initialOrderLoading,
  showCountdown,
  dateCreated,
}) => {
  // const dispatch = useDispatch();
  const history = useHistory();
  const { data: distributors, isLoading: distributorsLoading } =
    useGetMpDistributors({});

  const { data: distributorDepots, isLoading: depotsLoading } =
    useGetMpDistributorDepots(
      {
        queryParams: { distributorId: selectedDistributor?.id },
      },
      { enabled: !!selectedDistributor?.id },
    );
  const onCountdownFinish = () => {
    toast.warn(
      i18next.t("your_order_session_is_over_and_your_order_has_been_deleted."),
    );
    // dispatch(AuthenticationStore.actionCreators.removeAuthentication());
    // dispatch(AuthenticationStore.actionCreators.changeAuthentication(false));
    history.push("/");
  };

  const disableDates = (current: Dayjs) => {
    const now = dayjs();
    const afterThreePM =
      now.hour() > 15 || (now.hour() === 15 && now.minute() > 0);

    // Disable tomorrow if it's after 15:00 today
    if (afterThreePM) {
      const tomorrow = dayjs().add(1, "day").startOf("day");

      return current.isSame(tomorrow, "day") || current.day() === 0;
    }

    // Disable all Sundays
    return current.day() === 0;
  };

  return (
    <>
      {showCountdown && dateCreated && (
        <CountdownContainer>
          <Statistic.Countdown
            prefix={i18next.t("time_left")}
            format="mm:ss"
            value={dateCreated?.add(ORDER_TIME_IN_MINUTES, "minutes").valueOf()}
            onFinish={onCountdownFinish}
          />
        </CountdownContainer>
      )}
      <Row style={{ marginBottom: 15, width: "100%" }}>
        <Col span={6}>
          <OrderInfoLabel>{i18next.t("distributor_name")}</OrderInfoLabel>
          <Select
            style={{ minWidth: 200 }}
            placeholder={i18next.t("select_distributor")}
            loading={distributorsLoading}
            disabled={saveAndSendLoading}
            showSearch
            optionFilterProp="label"
            onChange={(value) => {
              resetCalculatedData();
              setSelectedDepot(undefined);
              setDeliveryDate(undefined);
              setSelectedDistributor({
                id: value,
                officialDistributorName:
                  distributors?.find((distributor) => distributor.id === value)
                    .officialDistributorName || i18next.t("no_name"),
              });
            }}
            options={distributors?.map((distributor) => ({
              label:
                distributor.officialDistributorName || i18next.t("no_name"),
              value: distributor.id,
            }))}
          />
        </Col>
        {selectedDistributor && (
          <Col span={6}>
            <OrderInfoLabel>{i18next.t("depot_name")}</OrderInfoLabel>
            <OrderInfoValue>
              <Select
                style={{ minWidth: 200 }}
                placeholder={i18next.t("select_depot")}
                showSearch
                loading={depotsLoading}
                disabled={saveAndSendLoading}
                optionFilterProp="label"
                value={selectedDepot?.id}
                onChange={(value) => {
                  resetCalculatedData();
                  setDeliveryDate(undefined);
                  setSelectedDepot(
                    distributorDepots?.find((depot) => depot.id === value),
                  );
                }}
                options={distributorDepots?.map((depot) => ({
                  label: depot.officialDepotName || i18next.t("no_name"),
                  value: depot.id,
                }))}
              />
            </OrderInfoValue>
            <OrderInfoLabel>
              <LocationIcon />
              {selectedDepot?.deliveryAddress ||
                i18next.t("no_address_provided")}
            </OrderInfoLabel>
          </Col>
        )}
        {!!selectedDepot && (
          <Col span={6}>
            <OrderInfoLabel>{i18next.t("delivery_date")}</OrderInfoLabel>
            <DatePicker
              showNow={false}
              allowClear={false}
              value={deliveryDate}
              disabled={saveAndSendLoading}
              disabledDate={disableDates}
              onChange={(date) => setDeliveryDate(date)}
              minDate={dayjs().add(1, "day")}
              format="DD/MM/YYYY"
            />
          </Col>
        )}
        {!!selectedDepot && deliveryDate && !initialOrderLoading && (
          <Col
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "end",
              width: "100%",
            }}
            span={6}
          >
            <Button
              type="primary"
              size="large"
              style={{
                alignItems: "center",
                backgroundColor: "#183F23",
                color: "white",
                display: "flex",
                fontSize: 16,
                fontWeight: 700,
                opacity: saveAndSendDisabled ? 0.5 : 1,
                padding: "0 32px",
              }}
              disabled={saveAndSendDisabled}
              onClick={onSaveAndSend}
              loading={saveAndSendLoading}
            >
              {i18next.t("save_and_send_order")}
            </Button>
          </Col>
        )}
      </Row>
      <Row style={{ marginBottom: 30, marginTop: 25 }}>
        {selectedDepot && (
          <>
            <Col span={6}>
              <OrderInfoLabel>{i18next.t("depot_contact_name")}</OrderInfoLabel>
              <OrderInfoValue>
                {selectedDepot?.contactPersonName ||
                  i18next.t("no_contact_provided")}
              </OrderInfoValue>
            </Col>
            <Col span={6}>
              <OrderInfoLabel>
                {i18next.t("depot_contact_phone")}
              </OrderInfoLabel>
              <OrderInfoValue>
                {selectedDepot?.contactPersonPhone ||
                  i18next.t("no_contact_provided")}
              </OrderInfoValue>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};
export default OrderInfoSection;
