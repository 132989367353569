const translateHEStatus = (item) => {
  let typeValue = "";

  switch (item) {
    case 0:
      typeValue = "Generation in progress";
      break;
    case 1:
      typeValue = "File(s) export failed.";
      break;
    case 2:
      typeValue = "Files(s) upload failed.";
      break;
    case 3:
      typeValue = "All files are successfully uploaded. Pending processing by Hermes.";
      break;
    case 4:
      typeValue = "All files are successfully processed by Hermes.";
      break;
    case 5:
      typeValue = "Hermes is missing file(s). Check Hermes logs.";
      break;
    case 6:
      typeValue = "General file(s) processing error by Hermes. Check Hermes logs.";
      break;
  }

  return typeValue;
};

export {
  translateHEStatus,
};
