import React from "react";

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.75791 4.5C7.75791 3.56704 8.0267 2.9492 8.38118 2.58029C8.71657 2.23125 9.22764 2 10 2C10.7724 2 11.2834 2.23125 11.6188 2.58029C11.9733 2.9492 12.2421 3.56704 12.2421 4.5V5H7.75791V4.5ZM5.51582 7V9C5.51582 9.55229 6.01773 10 6.63686 10C7.256 10 7.75791 9.55229 7.75791 9V7H12.2421V9C12.2421 9.55229 12.744 10 13.3631 10C13.9823 10 14.4842 9.55229 14.4842 9V7H16.8092L17.7578 18H2.24222L3.1908 7H5.51582ZM5.51582 5V4.5C5.51582 3.24496 5.87738 2.1128 6.67886 1.27871C7.49942 0.424752 8.66993 0 10 0C11.3301 0 12.5006 0.424752 13.3212 1.27871C14.1226 2.1128 14.4842 3.24497 14.4842 4.5V5H16.8092C17.9808 5 18.9548 5.80461 19.0447 6.84661L19.9933 17.8466C20.0934 19.0082 19.0638 20 17.7578 20H2.24222C0.936182 20 -0.0934316 19.0082 0.00673714 17.8466L0.955314 6.84661C1.04517 5.80461 2.01923 5 3.1908 5H5.51582Z"
    />
  </svg>
);
