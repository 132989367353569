import React from "react";

import { Form, Modal, Input, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import i18next from "i18next";
import { toast } from "react-toastify";

import { useUpsertPallet } from "api/reactQuery/queryComponents";
import {
  BSCoreServicesDtoMarketplacePalletDto,
  BSDALMarketplaceEnumsPalletType,
} from "api/reactQuery/querySchemas";

type Props = {
  isOpen: boolean;
  toggleModalOpen: () => void;
  onAfterSuccess?: () => void;
  pallet?: BSCoreServicesDtoMarketplacePalletDto;
};

const requiredRule = { message: "Field required", required: true };
const CreateEditPalletModal: React.FC<Props> = ({
  isOpen,
  toggleModalOpen,
  onAfterSuccess,
  pallet,
}) => {
  const { mutate } = useUpsertPallet({
    onSuccess: () => {
      toast.success(
        pallet
          ? i18next.t("pallet_edited_successfully")
          : i18next.t("pallet_created_successfully"),
      );
      toggleModalOpen();
      onAfterSuccess();
    },
  });

  const [form] = useForm<BSCoreServicesDtoMarketplacePalletDto>();
  const onFinish = (values: BSCoreServicesDtoMarketplacePalletDto) => {
    form.isFieldsTouched()
      ? mutate({ body: { ...values, id: pallet?.id } })
      : toggleModalOpen();
  };

  return (
    <Modal
      open={isOpen}
      centered
      destroyOnClose
      onOk={() => form.submit()}
      onCancel={() => toggleModalOpen()}
      title={pallet ? i18next.t("edit_pallet") : i18next.t("create_pallet")}
    >
      <Form
        form={form}
        initialValues={pallet}
        preserve={false}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
      >
        <Form.Item<BSCoreServicesDtoMarketplacePalletDto>
          label={i18next.t("barcode")}
          name="barcode"
          rules={[requiredRule]}
        >
          <Input />
        </Form.Item>
        <Form.Item<BSCoreServicesDtoMarketplacePalletDto>
          label={i18next.t("weight")}
          name="weight"
          rules={[requiredRule]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item<BSCoreServicesDtoMarketplacePalletDto>
          label={i18next.t("price")}
          name="price"
          rules={[requiredRule]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item<BSCoreServicesDtoMarketplacePalletDto>
          label={i18next.t("type")}
          name="type"
          rules={[requiredRule]}
        >
          <Select
            optionFilterProp="label"
            options={Object.values(BSDALMarketplaceEnumsPalletType).map(
              (option, index) => ({
                label: option,
                value: index,
              }),
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default CreateEditPalletModal;
