import React from 'react'

export default () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-35 0 511 512"
    >
      <path d="M436.031 107.031L331.43 4.297A15 15 0 00320.918 0H56.468C25.61 0 .5 25.105.5 55.969V456.03C.5 486.891 25.605 512 56.469 512H384.55c30.86 0 55.969-25.105 55.969-55.969V117.734a15 15 0 00-4.489-10.703zM335.918 50.758l52.922 51.976h-48.153a4.776 4.776 0 01-4.769-4.77zM384.551 482H56.469C42.152 482 30.5 470.352 30.5 456.031V55.97C30.5 41.649 42.148 30 56.469 30h249.453v67.965c0 19.172 15.598 34.77 34.77 34.77h69.828V456.03c0 14.32-11.649 25.969-25.97 25.969zm0 0"></path>
      <path d="M324.645 238H113.19c-8.285 0-15 6.715-15 15s6.715 15 15 15h211.454c8.285 0 15-6.715 15-15s-6.715-15-15-15zm0 0M113.191 328h172.414c8.286 0 15-6.715 15-15s-6.714-15-15-15H113.191c-8.285 0-15 6.715-15 15s6.715 15 15 15zm0 0M306.406 358H113.191c-8.285 0-15 6.715-15 15s6.715 15 15 15h193.215c8.281 0 15-6.715 15-15s-6.719-15-15-15zm0 0"></path>
    </svg>
  )
