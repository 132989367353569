import React, { FunctionComponent, ReactNode, useState  } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { connect, Provider } from 'react-redux';
import styled from 'styled-components'
import Headline from './Headline'
import Pagination from './Pagination'
import Button from './Button'
import { range, throttle, set } from 'lodash';
import loader from '../images/spinner.gif'
import * as endpoints from './constants/endpoints'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { FilterIcon } from '../svg';
import { FileExport } from '../svg';
import { ClearFilter } from '../svg';
import { EditIcon, DeleteIcon, AddIcon, ViewIcon } from '../svg'
import i18next from "i18next";

import Cookies from 'universal-cookie';
const cookies = new Cookies();

interface T {
    label: string
}

interface Props {
    type?: string,
    title?: string,
    tableSettings?: any,
    data?: any,
    onSorterClick?: any,
    paginationUpdated?: any,
    allowMultipleSelection?: boolean,
    totalItems?: number,
    currentPage?: number,
    itemsPerPage?: number,
    isLoading?: boolean,
    emitSelectedIds?: any,
    emitFilterValue?: any,
    emitSelectedDepo?: any,
    selectedRows?: any,
    selectedDepo?: any,
    showMainAutocomplete?: any,
    currentSelectedFilter?: any,
    currentSortingDirection?: any,
    showCommentsBtn?: boolean,
    showActionCell?: boolean,
    filters?: any,
    depots?:any,
    depotsLoaded?: any,
    shouldReRender?: any,
    emitExportData?: any,
    commentUrl?: any,
    toggleHistoryModal?: any,
    hideFilters?: any,
    hideExport?: any
    hideSearch?: any,
    hideFooter?: any
}

class TableComponent extends React.Component<Props>{
    constructor(props) {
        super(props);

        this.handlePageClick = this.handlePageClick.bind(this)
        this.rowClicked = this.rowClicked.bind(this)
        this.setIsFilterShown = this.setIsFilterShown.bind(this)
        this.handleFieldChange = this.handleFieldChange.bind(this)
        this.exportData = this.exportData.bind(this)
    }

    state = {
        isFilterShown: false,
        showClearFilterLink: false,
        sortingDirection: '',
        sortingLabel: '',
        selectedIds: [],
        selectedIndexes: [],
        filters: {
            searchValue: '',
            depotName: '',
            code: '',
            name: '',
            townOfDispatching: '',
            addressOfDispatching: '',
            vat: '',
            hermesId: '',
            pocName: '',
            pocStatus: '',
            city: '',
            street: '',
            houseNumber: '',
            hermesCustomerName: '',
            customerFiscalCode: '',
            rank: '',
            //edit poc mappings
            customerCode: '',
            customerName: '',
            customerAddressOfDispatching: '',
            customerVat: '',
            hermesPocCode: '',
            hermesPocName: '',
            hermesPocStreet: '',
            hermesPocCustomerName: '',
            hermesPocCustomerFiscalCode: '',
            hermesPocStatus: '',
            dateCreated: ''
        },
        depots: [],
        depotsLoaded: false,
        selectedDepotObj: {},
        depoObjTemplate: {
            'id': '',
            'tenantId': '',
            'depotCode': '',
            'depotName': '',
            'region': '',
            'exportFolder': '',
            'distributorId': '',
            'isActive': true,
            'softwareProviderCompanyName': '',
            'softwareProviderPhone': '',
            'softwareProviderEmail': '',
            'softwareProviderContactPerson': '',
            'dateCreated': '',
            'dateUpdated': ''
        }
    }

    rowClicked = (event, row, index) => {
        let selectedIds = this.state.selectedIds;

        let selectedIndexes
        if (JSON.parse(localStorage.getItem("selectedIndexes")) === null) {
            selectedIndexes = []
        } else {
            selectedIndexes = JSON.parse(localStorage.getItem("selectedIndexes"));
        }

        selectedIndexes.push(index)

        if (selectedIndexes.length > 2) {
            const lastItem = selectedIndexes[selectedIndexes.length -1]
            const firstItem = selectedIndexes[selectedIndexes.length -2]
            selectedIndexes = []
            selectedIndexes.push(firstItem)
            selectedIndexes.push(lastItem)
        }

        if (this.props.allowMultipleSelection) {
            if (event.shiftKey) {
                selectedIds.push(row.id)

                selectedIndexes.sort()
                const indexes = range(selectedIndexes[0], selectedIndexes[1] + 1)

                // first clean selectedIds
                selectedIds = []

                // then loop and push id's between last and first
                indexes.map(index => {
                    selectedIds.push(this.props.data[index].id)
                })
            // Note you can't use this key combo on apple devices. for testing use altKey
            } else if (event.ctrlKey || event.metaKey) {
                // if row.id is allready in selectedIds remove it, else add it
                if (selectedIds.includes(row.id)) {
                    // remove it
                    const index = selectedIds.indexOf(row.id);
                    selectedIds.splice(index, 1);
                } else {
                    selectedIds.push(row.id)
                }
            } else {
                // case user click on the same item twice
                if (selectedIds[0] === row.id ) {
                    selectedIds = []
                } else {
                    selectedIds = []
                    selectedIds.push(row.id)
                    selectedIndexes = [index]
                }
            }
        } else {
            // case user click on the same item twice
            if (selectedIds[0] === row.id ) {
                selectedIds = []
            } else {
                selectedIds = []
                selectedIds.push(row.id)
            }
        }

        selectedIndexes.sort()

        localStorage.setItem('selectedIndexes', JSON.stringify(selectedIndexes));

        this.setState({selectedIds}
            ,() => this.props.emitSelectedIds(selectedIds, this.props.type)
            )
    }

    handleColumnSort = (key) => {
        if (key === '') {
            return false;
        }

        if (this.state.sortingLabel === key) {
            this.setState(
                {
                    sortingDirection:
                        this.state.sortingDirection === 'asc' ? 'desc' : 'asc'
                },
                () => {
                    this.props.onSorterClick(
                        this.state.sortingLabel,
                        this.state.sortingDirection,
                        this.props.type
                    );
                }
            );
        } else {
            this.setState(
                {
                    sortingLabel: key,
                    sortingDirection: 'asc'
                },
                () => {
                    this.props.onSorterClick(
                        this.state.sortingLabel,
                        this.state.sortingDirection,
                        this.props.type
                    );
                }
            );
        }
    }

    paginationUpdated = (itemsPerTable, currentPage) => {
        this.props.paginationUpdated(itemsPerTable, currentPage, this.props.type)
    }

   handlePageClick = (page) => {
        this.setState({currentPage: page})
    };

    setIsFilterShown = (e) => {
        e.preventDefault()
        this.setState({
            isFilterShown: !this.state.isFilterShown
        })
    }

    handleFieldChange(event, key, type) {
        set(this.state, event.target.name, event.target.value);

        this.props.emitFilterValue(event.target.value, key, type)
    }

    handleMainAutocompleteChange(event, item) {
        if (item) {
            set(this.state, 'filters.depotName', item.depotName);
            this.setState({selectedDepotObj: item})

            this.props.emitFilterValue(item.depotName, 'depotName', this.props.type)
            this.props.emitSelectedDepo(item)
        } else {
            set(this.state, 'filters.depotName', '');
            this.setState({selectedDepotObj: this.state.depoObjTemplate})
            this.props.emitFilterValue('', 'depotName', this.props.type)
            this.props.emitSelectedDepo({})
        }
    }

    handleAutocompleteChange(event, item) {
        if (item) {
            set(this.state, 'filters.depotName', item.depotName);
            this.setState({selectedDepotObj: item})
            this.props.emitFilterValue(item.depotName, 'depotName', this.props.type)
            this.props.emitSelectedDepo(item)
        } else {
            set(this.state, 'filters.depotName', '');
            this.setState({selectedDepotObj: this.state.depoObjTemplate})
            this.props.emitFilterValue('', 'depotName', this.props.type)
            this.props.emitSelectedDepo({})
        }
    }

    componentDidMount() {
        this.setState({selectedIds:this.props.selectedRows})
        const filterKeys =  Object.keys(this.props.filters)
        const filterArray = filterKeys.map(item => {
            return this.props.filters[item]
        })

        const isFilterNotEmpty = filterArray.some(item => item !== '')

        if ( isFilterNotEmpty ) {
            this.setState({
                isFilterShown: true,
                showClearFilterLink: true
            })
        }

        if (this.props.selectedDepo) {
            this.setState({selectedDepotObj: this.props.selectedDepo})
        } else {
            this.setState({ selectedDepotObj: this.state.depoObjTemplate})
        }

        if ( this.props.currentSelectedFilter ) {
            this.setState({ sortingLabel: this.props.currentSelectedFilter})
        }

        if ( this.props.currentSortingDirection ) {
            this.setState({ sortingDirection: this.props.currentSortingDirection})
        }

        if (this.props.depots) {
            this.setState({ depots: this.props.depots})
        }
    }

    exportData(e) {
        e.preventDefault();

        this.props.emitExportData()
    }

    clearFilters(e) {
        e.preventDefault();

        const filters = this.state.filters;

        Object.keys(this.props.filters).map(item => {
            if (this.props.filters[`${item}`] !== '') {
                this.props.emitFilterValue('', item, this.props.type)
            }
        })

        set(this.state, 'filters.depotName', '');

        if (this.props.emitSelectedDepo) {
            this.setState({selectedDepotObj: this.state.depoObjTemplate})
            this.props.emitFilterValue('', 'depotName', this.props.type)
            this.props.emitSelectedDepo({})
        }
    }

    public render() {
        const TableWrapper = styled.div`
            th { text-align: left; font-size: 10px; padding: 5px 5px; font-weight: 500; color: #6d6d6d; }
            td { font-size: 10px; text-align: left; padding: 5px 5px; min-height: 40px; height: 41px; vertical-align: middle; }

            th:first-child,
            td:first-child { padding-left: 20px;}

            th:last-child,
            td:last-child { padding-right: 20px;}

            .scrollable-table-wrapper { overflow-x: auto;}
            .scrollable-table-wrapper table { min-width: 1000px;}


            // this one is fix for the last cell
            tbody tr td:last-child { width:0.1%; white-space: nowrap;}

             button { font-size: 8px}

            tr + tr { border-top: 1px solid #e2e2e2 }


            .row-green { background: green; color: #fff; }
            .row-yellow { background: yellow; color: #000; }
            .row-selected { background: #5d78ff !important; color: #fff; }
            .row-selected td { background: #5d78ff !important; color: #fff; }

            .export-link,
            .filter-toggle { display: flex; align-items: center; font-size: 12px}

            .export-link span,
            .filter-toggle span { margin-left: 5px; color: #2e87b0; }

            .input-filter { height: 38px; border: 1px solid #e2e2e2; }

            .search { font-size: 0; padding-top: 0; }
            .search input { height 38px; font-size: 12px; border: 1px solid #e2e2e2; padding-left: 15px; border-radius: 2px;}
        `

        const Foot = styled.div`
            padding: 12px 20px;
             border-top: 1px solid #e2e2e2;
            // border-bottom: 1px solid ${({ theme }) => theme.colors.border};
        `

        const Content = styled.div`
            padding: ${({ hasContentGutter }) => hasContentGutter ? '25px' : 0};
        `

        const Body = styled.div`
            padding: ${({ hasContentGutter }) => hasContentGutter ? '25px' : 0};
        `

        const Box = styled.div`
            position: relative;
            box-shadow: 0 0 13px 0 rgba(82,63,105,.1);
            background: #fff;
            margin-bottom: ${({ hasMargin }) => hasMargin ? '20px' : 0};
        `

        const Head = styled.div`
            padding: 12px 20px;
            border-bottom: 1px solid ${({ theme }) => theme.colors.border};
        `
        // fix nested properties
        let  obj, o = obj = {};

    return (
            <TableWrapper>
                <Box >
                    {this.props.title && (
                      <Head>
                          <Headline disableMargin>{this.props.title}</Headline>
                      </Head>
                    )}

                    <Body>
                        <div className="d-flex aling-items-center justify-content-between p-3">
                            <div className="d-flex aling-items-center">
                                {!this.props.hideFilters && (
                                    <a href="" onClick={(e)=> this.setIsFilterShown(e)} className="filter-toggle">
                                        <i>
                                            <FilterIcon/>
                                        </i>

                                        <span>
                                            { this.state.isFilterShown ? 'hide filters' : 'show filters'

                                                }
                                        </span>
                                    </a>
                                    )}

                                {!this.props.hideExport && (
                                    <a href="" className="export-link ml-3" onClick={(e) => this.exportData(e)}>
                                        <i>
                                            <FileExport/>
                                        </i>

                                        <span>Export</span>
                                    </a>
                                )}

                                { this.state.showClearFilterLink && (
                                    <a href="" className="export-link ml-3" onClick={(e) => this.clearFilters(e)}>
                                        <i>
                                            <ClearFilter/>
                                        </i>

                                        <span>
                                            Clear Filters
                                        </span>
                                    </a>
                                )}
                            </div>

                            <div className="d-flex align-items-center">
                                {this.props.showMainAutocomplete && this.props.showMainAutocomplete === true && this.state.depots.length > 0 && (
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={this.state.depots}
                                        value={this.state.selectedDepotObj}
                                        getOptionLabel={(option) => option.depotName}
                                        style={{ width: 200 }}
                                        renderInput={(params) => <TextField {...params} label={i18next.t('depot')} variant="outlined" />}
                                        getOptionSelected={(option) => option.depotName === this.state.filters.depotName}
                                        onChange={this.handleMainAutocompleteChange.bind(
                                            this
                                        )}
                                    />

                                )}

                                 {!this.props.hideSearch && (
                                    <div className="search ml-3">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            name="filters.searchValue"
                                            defaultValue={this.props.filters.searchValue}
                                            onChange={(event) => this.handleFieldChange(event, 'searchValue', this.props.type)}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                      <div className="scrollable-table-wrapper">
                          <table>
                            <thead>
                              <tr>
                                  {
                                    this.props.tableSettings.tableColumns.map(item => (
                                        <th key={item.key} onClick={() => item.sortable ? this.handleColumnSort(item.key) : undefined }>
                                            {item.title}
                                        </th>
                                    ))
                                  }
                              </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    {this.state.isFilterShown &&
                                        (
                                            this.props.tableSettings.tableColumns.map(item => {
                                                if ( item.isFilterable ) {
                                                    if (item.key === 'depotName' && this.props.depotsLoaded === true && this.state.depots.length > 0 ) {
                                                        return (
                                                            <td>
                                                                <Autocomplete
                                                                    id="combo-box-demo2"
                                                                    options={this.state.depots}
                                                                    value={this.state.selectedDepotObj}
                                                                    getOptionLabel={(option) => option.depotName}
                                                                    style={{ width: 200 }}
                                                                    renderInput={(params) => <TextField {...params} label={i18next.t('depot')} variant="outlined" />}
                                                                    getOptionSelected={(option) => option.depotName === this.state.filters.depotName}
                                                                    onChange={this.handleAutocompleteChange.bind(
                                                                        this
                                                                    )}
                                                                />
                                                            </td>
                                                        )
                                                    } else {
                                                        return (
                                                            <td>
                                                                <input
                                                                    key={item.key}
                                                                    type="text"
                                                                    placeholder={item.title}
                                                                    name={`filters${item.key}`}
                                                                    style={{width: '100%'}}
                                                                    defaultValue={this.props.filters[item.key]}
                                                                    onChange={(event) => this.handleFieldChange(event, item.key, this.props.type)}
                                                                    className="input-filter"
                                                                />
                                                            </td>
                                                        )
                                                    }

                                                } else {
                                                    return (
                                                        <td></td>
                                                        )
                                                }
                                            }
                                            )

                                        )
                                    }
                              </tr>
                                {this.props.isLoading === false && this.props.data && this.props.data.length > 0 && this.props.data.map((row, index) => {

                                    return (
                                        <tr
                                            onClick={(event) => {
                                                if (event.shiftKey) {
                                                    this.rowClicked(event, row, index)

                                                } else {
                                                    let selection = window.getSelection();

                                                    if(selection.type !== "Range") {
                                                        this.rowClicked(event, row, index)
                                                    }
                                                }
                                            }}
                                            className={`
                                                ${this.state.selectedIds.includes(row.id) ? 'row-selected' : ''}
                                                ${row.rank && row.rank >= 30 && row.rank < 50 ? 'row-yellow': '' }
                                                ${row.rank && row.rank >= 50 ? 'row-green': '' }
                                            `}
                                            key={`row-${index}`}

                                        >
                                            {this.props.tableSettings.tableCells.map((item, index) => (
                                                <td
                                                    style={{
                                                        backgroundColor: this.props.tableSettings.tableColumns[index].background === 'blue' ? '#e8f4f8' : 'none',
                                                        color: row[`${item}`] === 'Missing mapping' ? 'red' : ''
                                                    }}
                                                >
                                                    {row[`${item}`]}
                                                </td>
                                                ))
                                            }

                                            {this.props.showActionCell &&
                                                <td>
                                                    <div className="d-flex align-center">
                                                        {row.history && (
                                                            <Button
                                                                icon={ViewIcon}
                                                                className='mr-2'
                                                                variant='outlined'
                                                                color='primary'
                                                                id="view-product-mapping-history"
                                                                onClick={() => this.props.toggleHistoryModal(row.id)}
                                                            >
                                                                {i18next.t('btn_history')}
                                                            </Button>
                                                        )}

                                                        {this.props.showCommentsBtn &&
                                                            <Link to={`${this.props.commentUrl}${row.id}`} >
                                                                <Button
                                                                    icon={AddIcon}
                                                                    variant='outlined'
                                                                >
                                                                    {i18next.t('btn_comments')}
                                                                </Button>
                                                            </Link>
                                                        }
                                                    </div>
                                                </td>
                                            }
                                        </tr>
                                    )
                                }
                               )}
                            </tbody>
                            </table>
                      </div>

                        {this.props.isLoading === false && !this.props.data &&
                            <div className="d-flex align-items-center justify-content-center" style={{minHeight: '300px'}}>
                                <p>{i18next.t('table_no_result')}</p>
                            </div>
                        }

                        {this.props.isLoading === false && this.props.data && this.props.data.length === 0 &&
                            <div className="d-flex align-items-center justify-content-center" style={{minHeight: '300px'}}>
                                <p>{i18next.t('table_no_result')}</p>
                            </div>
                        }

                        {this.props.isLoading === true && (
                          <div className="d-flex align-items-center justify-content-center" style={{minHeight: '300px'}}>
                              <img src={loader} alt="" />
                          </div>
                          )}
                    </Body>

                    {!this.props.hideFooter && (
                        <Foot>
                            <Pagination
                                totalItems={this.props.totalItems}
                                currentPage={this.props.currentPage}
                                itemsPerPage={this.props.itemsPerPage}
                                paginationUpdated={this.paginationUpdated}
                            />
                        </Foot>

                        )}

                </Box>
            </TableWrapper>

        )
    }
}
export default connect (

)(TableComponent);
