import * as React from "react";
import { connect } from "react-redux";
import FilterableTableContainer from "../Helpers/FilterableTableContainer";
import { ApplicationState } from "../../store";
import * as UsersStore from "../../store/UsersReducer";
import { RouteComponentProps } from "react-router";
import Popup from "reactjs-popup";
import ModalContent from "../Modal/ModalContent";
import Button from "../Button";
import { EditIcon, DeleteIcon } from "../../svg";
import i18next from "i18next";

type UsersProps = UsersStore.UsersState &
  typeof UsersStore.actionCreators &
  RouteComponentProps<{}>;

class AdminTenantAdminList extends React.PureComponent<UsersProps> {
  constructor(props) {
    super(props);
    this.props.getAction("adminTenantAdmin");
  }

  componentDidMount() {
    this.props.getAction("adminTenantAdmin");
  }

  deleteUser = (userId) => {
    let callback = () => {
      this.props.getAction("adminTenantAdmin");
    };
    this.props.deleteUser(userId, callback);
  };

  editUser = (props) => {
    let user = props.record,
      linkTo = "/edit-user/" + user.userId;

    return (
      <>
        <Button
          icon={EditIcon}
          variant="outlined"
          onClick={() => this.props.history.push(linkTo)}
        />
        <Popup
          modal
          trigger={
            <Button icon={DeleteIcon} color="danger" variant="outlined" />
          }
        >
          {(close) => (
            <ModalContent
              close={close}
              type="confirmDeletionModal"
              confirmDelete={() => this.deleteUser(user.userId)}
            />
          )}
        </Popup>
      </>
    );
  };

  public render() {
    const { isLoading, userList } = this.props;
    let props = {
      // Data for the table to display; can be anything
      data: userList,
      // Fields to show in the table, and what object properties in the data they bind to
      fields: [
        {
          name: "firstName",
          displayName: i18next.t("name"),
          inputFilterable: true,
          sortable: true,
        },
        {
          name: "username",
          displayName: i18next.t("username"),
          inputFilterable: true,
          sortable: true,
        },
        {
          name: "email",
          displayName: i18next.t("email"),
          inputFilterable: true,
          exactFilterable: true,
          sortable: true,
        },
        {
          name: "roleId",
          displayName: i18next.t("role"),
          inputFilterable: true,
          sortable: true,
        },
        {
          name: "edit",
          displayName: i18next.t("actions"),
          render: this.editUser,
        },
      ],
      namespace: "People",
      initialSort: "firstName",
      bsLength: 12,
      useEditAction: true,
      tableName: i18next.t("administrators_and_tenant_administrators"),
      useRoleFields: ["roleId"],
    };
    return (
      <FilterableTableContainer
        {...{ isLoading }}
        hasActions
        tableProps={props}
      />
    );
  }
}
export default connect(
  (state: ApplicationState) => state.users,
  UsersStore.actionCreators,
)(AdminTenantAdminList);
