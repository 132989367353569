import { AgGridReact } from "ag-grid-react";
import { Tabs } from "antd";
import styled from "styled-components";

export const StyledAgGrid = styled(AgGridReact)`
  font-size: 14px;
  color: #6d6d6d;

  .ag-root-wrapper {
    border: none;
  }
  .ag-center-cols-container .ag-row,
  .ag-header-container {
    background-color: #f9f9f9;
  }
  .ag-cell-inline-editing {
    padding: 15px 8px;
    input {
      border-radius: 8px;
    }
  }
  .ag-header {
    background: inherit;
    font-size: 12px;
  }
  .ordered-quantities-header {
    background-color: #f1f7ff;
    color: #181818;
  }
  .current-available-units-header {
    background-color: #feffd4;
    color: #181818;
  }
  .ag-cell {
    display: flex;
    align-items: center;
    color: #6d6d6d;
  }
`;
export const StyledTabs = styled(Tabs)`
  margin-top: 20px;
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #181818 !important;
    font-weight: 700;
  }
  .ant-tabs-ink-bar {
    background-color: #183f23;
  }
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-tab-btn {
    color: #5c5c5c;
    font-size: 14px;
    padding: 0 80px;
  }
`;
export const ProductLengthBadge = styled.span`
  padding: 5px 10px;
  margin-left: 12px;
  font-size: 12px;
  border-radius: 8px;
  color: white;
  background-color: #c2aa73;
`;
