import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, Redirect } from "react-router";
import { ApplicationState } from "../../store";
import * as AuthenticationStore from "../../store/AuthenticationReducer";
import * as roles from "components/Utils/actions";
import Form from "../Form";
import TextInput from "../TextInput";
import AuthenticationScreen from "../AuthenticationScreen";
import { USER_ROLES } from "components/Utils/actions";
import i18next from "i18next";

type AuthenticationProps = AuthenticationStore.AuthenticationState &
  typeof AuthenticationStore.actionCreators &
  RouteComponentProps<{}>;

class LoginForm extends React.PureComponent<AuthenticationProps> {
  constructor(props) {
    super(props);
  }

  state = {
    username: "",
    password: "",
    validated: false,
  };

  handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.props.attemptAuthentication(
      { username: this.state.username, password: this.state.password },
      this.props.getCurrentLoggedUser,
    );
    this.setState({
      validated: true,
    });
    event.preventDefault();
    event.stopPropagation();
  };

  onChange = (event) => {
    var validity = event.target.validity;
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  public render() {
    const { isAuthenticated, isLoading } = this.props;
    const role = roles.getUserRole();

    let redirectTo = "/dashboard";
    switch (role) {
      case USER_ROLES.ADMINISTRATOR:
        redirectTo = "/manage-tenants";
        break;
      case USER_ROLES.TENANT_SUPPORT:
        redirectTo = "/create-poc-mapping";
        break;
      case USER_ROLES.TENANT_SUPERVISOR:
        redirectTo = "/generating-sales-report";
        break;
      case USER_ROLES.CUSTOMER_SERVICE:
        redirectTo = "/manage-distributors";
        break;
      case USER_ROLES.DATA_LOADER:
        redirectTo = "/data-load";
        break;
      case USER_ROLES.DISTRIBUTOR:
        redirectTo = "/access-denied";
        break;
    }

    return isAuthenticated ? (
      <Redirect to={redirectTo} />
    ) : (
      <AuthenticationScreen
        additionalLink={{ to: "/passwordRecovery", label: "Forgot password?" }}
      >
        <Form
          {...{ isLoading }}
          onSubmit={this.handleSubmit}
          buttonText="Login"
          buttonSize="extraLarge"
          buttonJustify="center"
        >
          <TextInput
            label={i18next.t("username")}
            name="username"
            id="username"
            size="large"
            required
            //validator={(value) => value.trim().length !== 0} or e.g. use validator.isEmail
            //errorMessage={{ validator: "Please enter a valid email" }}
            value={this.state.username}
            onChange={this.onChange}
          />

          <TextInput
            label={i18next.t("password")}
            name="password"
            id="password"
            size="large"
            type="password"
            required
            value={this.state.password}
            onChange={this.onChange}
          />
        </Form>
      </AuthenticationScreen>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.authentication,
  AuthenticationStore.actionCreators,
)(LoginForm);
