import React, { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";
import Button from "./Button";
import { ValidationForm } from "../react-bootstrap4-form-validation-modified/lib";
import Loader from "./Loader";
import Box from "./Box";

const FormStyled = styled(ValidationForm)`
  position: relative;
  padding: ${({ hasBox }) => (hasBox ? "0" : "0 30px")};
`;

const BoxForm = styled.div`
  padding: 30px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: ${({ hasBox, dateForm }) =>
    hasBox ? "30px" : dateForm ? "0px" : "10px"};
  padding: ${({ dateForm }) => (dateForm ? "5px" : "0px")};
  justify-content: ${({ justifyContent }) => justifyContent};
`;
const DatePickersContainer = styled.div`
  right: 20% !important;
  position: absolute !important;
  margin: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;
const DateFormButtonWrapper = styled.div`
  button {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
  }
`;

interface Props {
  title?: string;
  onSubmit: any;
  children: ReactNode;
  isLoading?: boolean;
  buttonSize?: "small" | "large" | "extraLarge";
  buttonIcon?: any;
  buttonJustify?: "flex-start" | "center" | "flex-end" | "space-between";
  buttonText: string;
  hasBox?: boolean;
  dateForm?: boolean;
  additionalButton?: ReactNode;
  submitDisabled?: boolean;
}

const FormComponent: FunctionComponent<Props> = ({
  title,
  onSubmit,
  children,
  isLoading,
  buttonSize = "large",
  buttonJustify = "flex-start",
  buttonIcon,
  buttonText,
  hasBox = false,
  dateForm = false,
  additionalButton,
  submitDisabled,
}) => {
  const FormButtons = () => (
    <ButtonWrapper {...{ hasBox, dateForm }} justifyContent={buttonJustify}>
      {additionalButton}
      <Button
        size={buttonSize}
        icon={buttonIcon}
        disabled={submitDisabled}
        type="submit"
      >
        {buttonText}
      </Button>
    </ButtonWrapper>
  );

  return (
    <FormStyled {...{ onSubmit, hasBox, dateForm }}>
      {hasBox ? (
        <>
          <Box {...{ title }}>
            <BoxForm>
              {children}
              <Loader {...{ isLoading }} />
            </BoxForm>
          </Box>
          <FormButtons />
        </>
      ) : dateForm ? (
        <>
          <Box>
            <DatePickersContainer>{children}</DatePickersContainer>
            <Loader {...{ isLoading }} />
            <DateFormButtonWrapper>
              <FormButtons />
            </DateFormButtonWrapper>
          </Box>
        </>
      ) : (
        <>
          {children}
          <Loader {...{ isLoading }} />
          <FormButtons />
        </>
      )}
    </FormStyled>
  );
};

export default FormComponent;
