import React from "react";

export default () => (
  <svg
    width="10"
    height="11"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.90716 2.34751C3.47084 1.7328 4.22481 1.39739 5 1.39739C5.77519 1.39739 6.52916 1.7328 7.09284 2.34751C7.65797 2.96381 7.98269 3.80908 7.98269 4.69934C7.98269 4.99328 7.8877 5.37044 7.69627 5.81543C7.50759 6.25405 7.241 6.72268 6.93577 7.18924C6.32524 8.12245 5.58959 9.00296 5.10252 9.55359C5.07093 9.5893 5.03415 9.60261 5 9.60261C4.96585 9.60261 4.92907 9.5893 4.89748 9.55359C4.41041 9.00296 3.67476 8.12245 3.06423 7.18924C2.759 6.72268 2.49241 6.25405 2.30373 5.81543C2.1123 5.37044 2.01731 4.99328 2.01731 4.69934C2.01731 3.80908 2.34203 2.96381 2.90716 2.34751ZM5 0.25C3.89012 0.25 2.83622 0.731249 2.06694 1.57018C1.29912 2.40753 0.875 3.53383 0.875 4.69934C0.875 5.21499 1.03424 5.75707 1.25512 6.27054C1.47875 6.79039 1.78254 7.31944 2.10959 7.81935C2.76363 8.81907 3.54016 9.74662 4.04355 10.3157C4.55577 10.8948 5.44423 10.8948 5.95645 10.3157C6.45984 9.74662 7.23638 8.81907 7.89041 7.81935C8.21746 7.31944 8.52125 6.79039 8.74488 6.27054C8.96576 5.75707 9.125 5.21499 9.125 4.69934C9.125 3.53383 8.70088 2.40753 7.93306 1.57018C7.16378 0.731249 6.10988 0.25 5 0.25ZM4.55577 4.90332C4.55577 4.65689 4.75466 4.45711 5 4.45711C5.24534 4.45711 5.44423 4.65689 5.44423 4.90332C5.44423 5.14976 5.24534 5.34953 5 5.34953C4.75466 5.34953 4.55577 5.14976 4.55577 4.90332ZM5 3.30972C4.12378 3.30972 3.41346 4.0232 3.41346 4.90332C3.41346 5.78344 4.12378 6.49692 5 6.49692C5.87622 6.49692 6.58654 5.78344 6.58654 4.90332C6.58654 4.0232 5.87622 3.30972 5 3.30972Z"
      fill="#7C7B7B"
    />
  </svg>
);
