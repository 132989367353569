import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as CommentsStore from "../../store/CommentsReducer";
import { RouteComponentProps } from "react-router";
import { MessageList, Input, ChatList } from "react-chat-elements";
import Button from "../Button";
import Headline from "../Headline";
import styled from "styled-components";
import { AddIcon, BackIcon, EditIcon, DeleteIcon } from "../../svg";
import Loader from "../Loader";
import * as actions from "components/Utils/actions";
import "../../custom.css";

type CommentsProps = CommentsStore.CommentsState &
  typeof CommentsStore.actionCreators &
  RouteComponentProps<{ type: any; id: any }>;

class Comments extends React.PureComponent<CommentsProps> {
  private inputRef = React.createRef<HTMLInputElement>();
  private type;
  private id;
  private rendered;

  constructor(props) {
    super(props);
    this.type = this.props.match.params.type;
    this.id = this.props.match.params.id;
  }

  componentDidMount() {
    if (!this.rendered) {
      this.rendered = true;
      let callback = (data) => {
        var name = data["item1"],
          commentsList = data["item2"];
        for (let id in commentsList) {
          var comment = commentsList[id];
          //avatar: 'https://facebook.github.io/react/img/logo.svg'
          comment["date"] = new Date(comment["date"]);
          comment["alt"] = actions.rolesEnum[comment["authorType"]];
        }
        this.setState({
          commentsList: commentsList,
          name: name,
        });
      };
      this.props.loadAllCommentsForTypeAndId(this.type, this.id, callback);
    }
  }

  state = {
    commentsList: [],
    name: "",
    render: true,
  };

  addComment = () => {
    var commentList = this.state.commentsList ? this.state.commentsList : [],
      inputRef = this.inputRef,
      inputValue = inputRef
        ? inputRef["state"]
          ? inputRef["state"].value
          : ""
        : "",
      newComment = {
        avatar: "https://facebook.github.io/react/img/logo.svg",
        alt: "Reactjs",
        title: "Facebook",
        subtitle: inputValue,
        date: new Date(),
        unread: 0,
      },
      commentToSave =
        this.type === "poc"
          ? {
              userId: actions.getUserId(),
              distributorCustomerId: this.id,
              text: inputValue,
            }
          : {
              userId: actions.getUserId(),
              distributorProductId: this.id,
              text: inputValue,
            };
    let callback = (createdComment) => {
      createdComment["date"] = new Date(createdComment["date"]);
      createdComment["alt"] = actions.rolesEnum[createdComment["authorType"]];
      commentList.push(createdComment);
      this.setState({
        commentsList: commentList,
        render: !this.state.render,
      });
    };
    this.props.addNewComment(commentToSave, callback);
    inputRef["state"].value = "";
  };

  navigateBack = (event) => {
    var backUrl =
      this.type === "sku"
        ? "/create-sku-mapping"
        : this.type === "poc"
          ? "/create-poc-mapping"
          : "";
    this.props.history.push(backUrl);
  };

  public render() {
    const Head = styled.div`
      padding: 12px 20px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    `;

    const { isLoading } = this.props;
    var name = this.state.name ? this.state.name : "",
      title = " for " + this.type + " " + name;
    return (
      <div id="comments">
        <Button
          icon={BackIcon}
          size="large"
          variant="outlined"
          onClick={this.navigateBack}
        >
          Back
        </Button>
        <Head>
          <Headline disableMargin>Comments {title}</Headline>
        </Head>
        <Input
          ref={(el) => (this.inputRef = el)}
          placeholder="Въведи коментар..."
          rightButtons={
            <Button icon={AddIcon} variant="outlined" onClick={this.addComment}>
              Добави
            </Button>
          }
        />
        <Loader background="semiLight" isLoading={isLoading} />
        <ChatList className="chat-list" dataSource={this.state.commentsList} />
      </div>
    );
  }
}
export default connect(
  (state: ApplicationState) => state.comments,
  CommentsStore.actionCreators,
)(Comments);
