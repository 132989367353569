import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../store";
import * as AuthenticationStore from "../../store/AuthenticationReducer";
import Form from "../Form";
import TextInput from "../TextInput";
import AuthenticationScreen from "../AuthenticationScreen";
import i18next from "i18next";

type AuthenticationProps = AuthenticationStore.AuthenticationState &
  typeof AuthenticationStore.actionCreators &
  RouteComponentProps<{}>;

class PasswordRecoveryForm extends React.PureComponent<AuthenticationProps> {
  constructor(props) {
    super(props);
  }

  public state = {
    email: "",
    password: "",
    confirmPassword: "",
    verificationCode: "",
    validUser: false,
    validated: false,
    response: "",
  };

  handleSubmit = (event) => {
    // POST validated form and show response message
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      return false;
    }

    var userCredentials = {
      email: this.state.email,
      resetPasswordCode: this.state.verificationCode,
      newPassword: this.state.password,
    };
    this.props.attemptChangePassword(userCredentials);

    event.preventDefault();
    event.stopPropagation();
  };

  sendVerificationCode = (event) => {
    //email exists in database
    this.props.attemptSendVerificationCode({ email: this.state.email });
    event.preventDefault();
  };

  onChange = (event) => {
    var validity = event.target.validity;
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  matchPassword = (value) => {
    return value && value === this.state.password;
  };

  public render() {
    const { isValidEmail, isLoading } = this.props;
    return (
      <AuthenticationScreen additionalLink={{ to: "/", label: "Go to Login" }}>
        {!isValidEmail ? (
          <Form
            {...{ isLoading }}
            buttonText={i18next.t("send_verification_code")}
            buttonSize="extraLarge"
            buttonJustify="center"
            onSubmit={this.sendVerificationCode}
          >
            <TextInput
              label={i18next.t("email")}
              name="email"
              id="email"
              size="large"
              required
              //validator={(value) => value.trim().length !== 0} or e.g. use validator.isEmail
              //errorMessage={{ validator: "Please enter a valid email" }}
              value={this.state.email}
              onChange={this.onChange}
            />
          </Form>
        ) : (
          <Form
            {...{ isLoading }}
            buttonText={i18next.t("reset_password")}
            buttonSize="extraLarge"
            buttonJustify="center"
            onSubmit={this.handleSubmit}
          >
            <TextInput
              label={i18next.t("verification_code")}
              name="verificationCode"
              id="verificationCode"
              size="large"
              required
              minLength={6}
              maxLength={6}
              value={this.state.verificationCode}
              onChange={this.onChange}
            />

            <TextInput
              label={i18next.t("enter_new_password")}
              name="password"
              id="password"
              size="large"
              type="password"
              required
              pattern="(?=.*[A-Z]).{6,}"
              errorMessage={{
                required: "Password is required",
                pattern:
                  "Password should be at least 6 characters and contains at least one upper case letter",
              }}
              value={this.state.password}
              onChange={this.onChange}
            />

            <TextInput
              label={i18next.t("confirm_new_password")}
              name="confirmPassword"
              id="confirmPassword"
              size="large"
              type="password"
              required
              validator={this.matchPassword}
              errorMessage={{
                required: "Confirm password is required",
                validator: "Password does not match",
              }}
              value={this.state.confirmPassword}
              onChange={this.onChange}
            />
          </Form>
        )}
      </AuthenticationScreen>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.authentication,
  AuthenticationStore.actionCreators,
)(PasswordRecoveryForm);
