import Cookies from "universal-cookie";

const cookies = new Cookies();

export const DEFAULT_HEADERS = {
  Accept: "*/*",
  Authorization: `Bearer ${cookies.get("rawtoken")}`,
  "Content-Type": "application/json-patch+json",
};

export const rolesEnum = {
  1: "Administrator",
  2: "TenantAdmin",
  3: "TenantSupport",
  4: "TenantSupervisor",
  5: "Distributor",
  6: "DataLoader",
  7: "CustomerService",
};
export const USER_ROLES = {
  ADMINISTRATOR: "Administrator",
  CUSTOMER_SERVICE: "CustomerService",
  DATA_LOADER: "DataLoader",
  DISTRIBUTOR: "Distributor",
  TENANT_ADMIN: "TenantAdmin",
  TENANT_SUPERVISOR: "TenantSupervisor",
  TENANT_SUPPORT: "TenantSupport",
} as const;
type UserKeys = keyof typeof USER_ROLES;
export const getUserRole = (): (typeof USER_ROLES)[UserKeys] => {
  const token = cookies.get("token");
  if (token) {
    return token[
      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    ];
  }
};

export function getUserId() {
  const token = cookies.get("token");
  if (token) {
    return token["UserId"];
  }
}

export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error: any = new Error(response ? response.statusText : "Error");
    error.response = response;
    throw error;
  }
}

export function parseJSON(response) {
  try {
    return response.json();
  } catch (e) {
    return response;
  }
}

export function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    minute = d.getMinutes(),
    second = d.getSeconds();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
export const downloadBlob = (blob: Blob, filename: string): void => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};
