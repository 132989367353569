import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { toast } from "react-toastify";
import * as actions from "../components/Actions/depotRawDataActions";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface DepotRawDataState {
  isLoading: boolean;
  responseMessage: string;
  regionsList: any;
  depotsList: any;
  selectedRegion: any;
  selectedDepot: any;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface ReceiveRegions {
  type: "RECEIVE_REGIONS";
  regionsList: any;
}

interface ReceiveDepots {
  type: "RECEIVE_DEPOTS";
  depotsList: any;
  selectedRegion: any;
}

interface SetDepot {
  type: "SET_DEPOT";
  selectedDepot: any;
}

interface GetSalesDataForDepot {
  type: "GET_SALES_DATA_FOR_DEPOT";
}

interface GetStockDataForDepot {
  type: "GET_STOCK_DATA_FOR_DEPOT";
}

interface ClearValues {
  type: "CLEAR_VALUES";
}

interface AttemptAction {
  type: "ATTEMPT_ACTION";
}

interface FinishAction {
  type: "FINISH_ACTION";
}

interface ErrorAction {
  type: "ERROR_ACTION";
  responseMessage: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
  | ReceiveRegions
  | ReceiveDepots
  | SetDepot
  | GetSalesDataForDepot
  | GetStockDataForDepot
  | ClearValues
  | AttemptAction
  | FinishAction
  | ErrorAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  getRegions: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    dispatch({ type: "ATTEMPT_ACTION" });
    const appState = getState();
    if (appState) {
      let callback = (regionsList) => {
          dispatch({ type: "RECEIVE_REGIONS", regionsList: regionsList });
        },
        error = (error) => {
          dispatch({ type: "ERROR_ACTION", responseMessage: error });
          toast.error(error || "Error");
        };
      actions.getRegions(callback, error);
    } else {
      dispatch({ type: "FINISH_ACTION" });
    }
  },
  setRegion:
    (region): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState && region) {
        let callback = (depotsList) => {
            dispatch({
              type: "RECEIVE_DEPOTS",
              selectedRegion: region,
              depotsList: depotsList,
            });
          },
          error = (error) => {
            dispatch({
              type: "ERROR_ACTION",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response?.statusText : "Error");
          };
        actions.getDepotsForRegion(region, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  setDepot:
    (depot): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState && depot) {
        dispatch({ type: "SET_DEPOT", selectedDepot: depot });
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  getSalesDataForDepot:
    (depotData): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState && depotData) {
        let callback = (regionsList) => {
            dispatch({ type: "GET_SALES_DATA_FOR_DEPOT" });
          },
          error = (error) => {
            dispatch({ type: "ERROR_ACTION", responseMessage: error });
            toast.error(error || "Error");
          };
        actions.getSalesStockDataForDepot(depotData, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  getStockDataForDepot:
    (depotData): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState && depotData) {
        let callback = (regionsList) => {
            dispatch({ type: "GET_STOCK_DATA_FOR_DEPOT" });
          },
          error = (error) => {
            dispatch({ type: "ERROR_ACTION", responseMessage: error });
            toast.error(error || "Error");
          };
        actions.getSalesStockDataForDepot(depotData, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  clearValues: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    dispatch({ type: "ATTEMPT_ACTION" });
    const appState = getState();
    if (appState) {
      dispatch({ type: "CLEAR_VALUES" });
    } else {
      dispatch({ type: "FINISH_ACTION" });
    }
  },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: DepotRawDataState = {
  isLoading: false,
  responseMessage: "",
  regionsList: [],
  depotsList: [],
  selectedRegion: "-1",
  selectedDepot: "-1",
};

export const reducer: Reducer<DepotRawDataState> = (
  state: DepotRawDataState | undefined,
  incomingAction: Action,
): DepotRawDataState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "ATTEMPT_ACTION":
      return {
        ...state,
        isLoading: true,
        responseMessage: "",
      };
    case "FINISH_ACTION":
      return {
        ...state,
        isLoading: false,
      };
    case "GET_SALES_DATA_FOR_DEPOT":
      return {
        ...state,
        isLoading: false,
      };
    case "GET_STOCK_DATA_FOR_DEPOT":
      return {
        ...state,
        isLoading: false,
      };
    case "RECEIVE_DEPOTS":
      return {
        ...state,
        depotsList: action.depotsList,
        selectedRegion: action.selectedRegion,
        selectedDepot: "-1",
        isLoading: false,
      };
    case "SET_DEPOT":
      return {
        ...state,
        selectedDepot: action.selectedDepot,
        isLoading: false,
      };
    case "RECEIVE_REGIONS":
      return {
        ...state,
        regionsList: action.regionsList,
      };
    case "CLEAR_VALUES":
      return {
        ...state,
        responseMessage: "",
        regionsList: [],
        depotsList: [],
        selectedRegion: "-1",
        selectedDepot: "-1",
        isLoading: false,
      };
    case "ERROR_ACTION":
      return {
        ...state,
        responseMessage: action.responseMessage,
        isLoading: false,
      };
  }
  return state;
};
