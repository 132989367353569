import React, { useEffect, useState } from "react";

import i18next from "i18next";

import { useGetAllPackaging } from "api/reactQuery/queryComponents";
import { BSCoreServicesDtoMarketplacePackagingDto } from "api/reactQuery/querySchemas";
import Button from "components/Button";
import FilterableTableContainer from "components/Helpers/FilterableTableContainer";
import CreateEditPackagingModal from "components/Packaging/CreateEditPackagingModal";
import { MarketplacePackagingTypes } from "components/Utils/enums";
import { AddIcon, EditIcon } from "svg";

const Packaging: React.FC = () => {
  const { data: packaging, refetch, isLoading } = useGetAllPackaging({});
  const [modalOpen, setModalOpen] = useState(false);
  const [packagingToEdit, setPackagingToEdit] =
    useState<BSCoreServicesDtoMarketplacePackagingDto>();
  const toggleModalOpen = () => {
    modalOpen && packagingToEdit && setPackagingToEdit(undefined);
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    packagingToEdit && !modalOpen && setModalOpen(true);
  }, [modalOpen, packagingToEdit]);

  const tableProps = {
    bsLength: 12,
    data: packaging,
    fields: [
      {
        displayName: i18next.t("name"),
        inputFilterable: true,
        name: "name",
        sortable: true,
      },
      {
        displayName: i18next.t("price"),
        inputFilterable: true,
        name: "price",
        sortable: true,
      },
      {
        displayName: i18next.t("type"),
        inputFilterable: true,
        name: "type",
        render: (props) => MarketplacePackagingTypes[props?.value],
        sortable: true,
      },
      {
        displayName: i18next.t("actions"),
        name: "edit",
        render: (props) => (
          <Button
            icon={EditIcon}
            variant="outlined"
            onClick={() => setPackagingToEdit(props.record)}
          />
        ),
      },
    ],
    tableName: "Packaging",
  };

  return (
    <div style={{ paddingTop: 20 }}>
      <FilterableTableContainer
        {...{ isLoading: isLoading }}
        hasActions
        tableProps={tableProps}
        buttons={
          <Button size="large" icon={AddIcon} onClick={toggleModalOpen}>
            {i18next.t("create_packaging")}
          </Button>
        }
      />
      <CreateEditPackagingModal
        isOpen={modalOpen}
        toggleModalOpen={toggleModalOpen}
        onAfterSuccess={refetch}
        packaging={packagingToEdit}
      />
    </div>
  );
};
export default Packaging;
