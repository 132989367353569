import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { toast } from "react-toastify";
import * as actions from "../components/Actions/distributorsActions";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface DistributorsState {
  isLoading: boolean;
  distributorsList: any;
  selectedDistributor: any;
  suppliers: any;
  responseMessage: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface AttemptEditDistributorAction {
  type: "ATTEMPT_EDIT_DISTRIBUTOR_ACTION";
  selectedDistributor: any;
  suppliers: any;
  responseMessage: string;
}

interface GetDistributorsAction {
  type: "GET_DISTRIBUTOR_ACTION";
  distributorsList: any;
}

interface EditDistributorAction {
  type: "EDIT_DISTRIBUTOR_ACTION";
  responseMessage: string;
  selectedDistributor: any;
}

interface AddDistributorAction {
  type: "ADD_DISTRIBUTOR_ACTION";
  responseMessage: string;
}

interface AttemptAction {
  type: "ATTEMPT_ACTION";
}

interface FinishAction {
  type: "FINISH_ACTION";
}

interface ErrorAction {
  type: "ERROR_ACTION";
  responseMessage: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
  | AttemptEditDistributorAction
  | GetDistributorsAction
  | EditDistributorAction
  | AddDistributorAction
  | AttemptAction
  | FinishAction
  | ErrorAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  attemptEditDistributor:
    (distributorId): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState) {
        let callback = (data, errorMsg) => {
            console.log(data);
            data &&
              dispatch({
                type: "ATTEMPT_EDIT_DISTRIBUTOR_ACTION",
                selectedDistributor: data?.editedDistributor,
                suppliers: data?.suppliers,
                responseMessage: errorMsg,
              });
            // toast.error(errorMsg || "Error");
          },
          error = (error) => {
            dispatch({ type: "ERROR_ACTION", responseMessage: error });
            toast.error(error || "Error");
          };
        actions.attemptEdit(distributorId, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  getAllDistributors:
    (): AppThunkAction<KnownAction> => (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState) {
        let callback = (distributorsList) => {
            dispatch({
              type: "GET_DISTRIBUTOR_ACTION",
              distributorsList: distributorsList,
            });
          },
          error = (error) => {
            dispatch({
              type: "ERROR_ACTION",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response.statusText : "Error");
          };
        actions.getDistributorsAction(callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  editDistributor:
    (editedDistributor): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState && editedDistributor) {
        let callback = () => {
            dispatch({
              type: "EDIT_DISTRIBUTOR_ACTION",
              responseMessage: "Success",
              selectedDistributor: editedDistributor,
            });
            toast.success("Success");
          },
          error = (error) => {
            dispatch({
              type: "ERROR_ACTION",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response.statusText : "Error");
          };
        actions.editDistributor(editedDistributor, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  addDistributor:
    (newDistributor): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState && newDistributor) {
        let callback = () => {
            dispatch({
              type: "ADD_DISTRIBUTOR_ACTION",
              responseMessage: "Success",
            });
            toast.success("Success");
          },
          error = (error) => {
            dispatch({
              type: "ERROR_ACTION",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response?.statusText : "Error");
          };
        actions.addDistributor(newDistributor, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  deleteDistributor:
    (distributorId, cb): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState) {
        let callback = () => {
            dispatch({
              type: "ADD_DISTRIBUTOR_ACTION",
              responseMessage: "Success",
            });
            cb();
            toast.success("Success");
          },
          error = (error) => {
            dispatch({
              type: "ERROR_ACTION",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response?.statusText : "Error");
          };
        actions.deleteDistributor(distributorId, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
  deleteDepot:
    (depotId, cb): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      dispatch({ type: "ATTEMPT_ACTION" });
      const appState = getState();
      if (appState) {
        let callback = () => {
            dispatch({
              type: "ADD_DISTRIBUTOR_ACTION",
              responseMessage: "Success",
            });
            cb();
            toast.success("Success");
          },
          error = (error) => {
            dispatch({
              type: "ERROR_ACTION",
              responseMessage: error.response
                ? error.response.statusText
                : "Error",
            });
            toast.error(error.response ? error.response?.statusText : "Error");
          };
        actions.deleteDepot(depotId, callback, error);
      } else {
        dispatch({ type: "FINISH_ACTION" });
      }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: DistributorsState = {
  isLoading: false,
  distributorsList: [],
  selectedDistributor: {},
  suppliers: [],
  responseMessage: "",
};

export const reducer: Reducer<DistributorsState> = (
  state: DistributorsState | undefined,
  incomingAction: Action,
): DistributorsState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "ATTEMPT_ACTION":
      return {
        ...state,
        isLoading: true,
        responseMessage: "",
      };
    case "FINISH_ACTION":
      return {
        ...state,
        isLoading: false,
      };
    case "ATTEMPT_EDIT_DISTRIBUTOR_ACTION":
      return {
        ...state,
        selectedDistributor: action.selectedDistributor,
        suppliers: action.suppliers,
        isLoading: false,
        responseMessage: action.responseMessage,
      };
    case "GET_DISTRIBUTOR_ACTION":
      return {
        ...state,
        distributorsList: action.distributorsList,
        isLoading: false,
      };
    case "EDIT_DISTRIBUTOR_ACTION":
      return {
        ...state,
        isLoading: false,
        selectedDistributor: action.selectedDistributor,
        responseMessage: action.responseMessage,
      };
    case "ADD_DISTRIBUTOR_ACTION":
      return {
        ...state,
        isLoading: false,
        responseMessage: action.responseMessage,
      };
    case "ERROR_ACTION":
      return {
        ...state,
        responseMessage: action.responseMessage,
        isLoading: false,
      };
  }
  return state;
};
