import React, { FunctionComponent } from 'react'
import { TextInput } from '../react-bootstrap4-form-validation-modified/lib';
import Field from './Field'

interface Props {
    label?: string,
    id?: string,
    size?: 'small' | 'large',
    // All other props
    [x:string]: any;
  }

const TextInputComponent: FunctionComponent<Props> = ({ label, id, size = 'small', ...restProps }) => (
    <Field {... { id, label, size }}>
        <TextInput {... restProps} {... { id }} />
    </Field>
  )

export default TextInputComponent
