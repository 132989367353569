import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { ApplicationState } from "../../store";
import FilterableTableContainer from "../Helpers/FilterableTableContainer";
import * as OrdersStore from "../../store/OrdersReducer";
import { RouteComponentProps, Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import Button from "../Button";
import { DownloadIcon, ViewIcon } from "../../svg";
import Cookies from "universal-cookie";

import * as paginationEndpoints from "../constants/paginationEndpoints/paginationEndpoints";
import * as actions from "components/Utils/actions";
import DataTable from "../DataTable";
import i18next from "i18next";
const cookies = new Cookies();
const ordersApiLocation = paginationEndpoints.HERMES_ORDERS_PAGINATION_ENDPOINT;

const hermesOrdersActionFormatter = (cell, row, index) => (
  <div className="identifier order-tr" id={row.id}>
    {cell}
  </div>
);

const dateRender = (cell, row, index) => {
  return cell ? actions.formatDate(cell) : cell;
};
// At runtime, Redux will merge together...
type OrdersProps = OrdersStore.OrdersState & // ... state we've requested from the Redux store
  typeof OrdersStore.actionCreators & // ... plus action creators we've requested
  RouteComponentProps<{ location: any }>; // ... plus incoming routing parameters

class Orders extends React.Component<OrdersProps> {
  state = {
    closeModal: undefined,
    selectedRow: {},
    selectedRowIdx: {},
  };

  constructor(props) {
    super(props);
  }

  downloadOrder = (orderId) => {
    this.props.downloadOrder(orderId);
  };

  editOrder = (cell, row, index) => {
    let order = row,
      orderId = order ? order.id : "",
      editLinkTo = "/order/" + orderId;

    return (
      <div>
        <Button
          icon={ViewIcon}
          color="primary"
          variant="outlined"
          onClick={() => this.props.history.push(editLinkTo)}
        >
          {i18next.t("details")}
        </Button>
        <Button
          icon={DownloadIcon}
          variant="outlined"
          onClick={() => this.downloadOrder(orderId)}
        >
          {i18next.t("xml")}
        </Button>
      </div>
    );
  };

  getHermesOrdersTableSettings = () => {
    return {
      tableID: "HermesOrders",
      keyField: "hermesOrderId",
      wrapperType: "HermesOrders",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${cookies.get("rawtoken")}`,
      },
      tableColumns: [
        { key: "hermesOrderId", title: i18next.t("order_number") },
        { key: "hermesDepotName", title: i18next.t("depot") },
        { key: "hermesPocName", title: i18next.t("POC") },
        {
          key: "orderDate",
          title: i18next.t("date"),
          dataFormat: dateRender,
        },
        { key: "isSeen", title: i18next.t("seen") },
        { key: "missingMapping", title: i18next.t("missing_mapping") },
        { key: "isDownloaded", title: i18next.t("downloaded") },
        {
          key: "actions",
          title: i18next.t("actions"),
          dataFormat: this.editOrder,
          sortable: false,
        },
      ],
    };
  };

  resetBtnState() {
    let buttons = document.getElementsByTagName("button");
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].disabled = false;
    }
  }

  toggleMapBtnState = (state) => {
    var createDisabled = this.state.selectedRow ? false : true,
      createBtn = document.getElementById("create-map-btn");
    if (createBtn) {
      (createBtn as HTMLInputElement).disabled = state ? state : createDisabled;
    }
  };

  public render() {
    const { isLoading } = this.props;
    let selectedRow = this.state.selectedRow,
      hermesOrder = selectedRow["hermesOrder"]
        ? selectedRow["hermesOrder"]
        : "",
      hermesOrderDetails = hermesOrder["orderDetails"]
        ? hermesOrder["orderDetails"]
        : "";

    const TableWrapper = styled.div`
      table {
        thead tr th {
          &:nth-child(1) {
            width: 110px;
          }

          &:nth-child(2) {
            width: 200px;
          }

          &:nth-child(3) {
            width: 150px;
          }

          &:nth-child(4) {
            width: 150px;
          }

          &:nth-child(5) {
            width: 100px;
          }

          &:nth-child(6) {
            width: 100px;
          }

          &:nth-child(7) {
            width: 100px;
          }

          &:nth-child(8) {
            width: 180px;
          }
        }
      }
    `;

    return (
      <TableWrapper>
        <DataTable
          title={i18next.t("title_orders")}
          {...{ isLoading }}
          hasActions
          tableSettings={this.getHermesOrdersTableSettings()}
          apiLocation={ordersApiLocation}
        />
      </TableWrapper>
    );
  }
}
export default connect(
  (state: ApplicationState) => state.orders,
  OrdersStore.actionCreators,
)(Orders);
