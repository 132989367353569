import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as DepotRawDataStore from "../../store/DepotRawDataReducer";
import { RouteComponentProps } from "react-router";
import { Row, Col } from "react-grid-system";
import Box from "../Box";
import Select from "../Select";
import Button from "../Button";
import DateRangePicker from "../DateRangePicker";
import regions from "../../regions.json";
import * as endpoints from "../constants/endpoints";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { DeleteIcon } from "../../svg";
import i18next from "i18next";
import loader from "../../images/spinner.gif";
const cookies = new Cookies();

type DeleteDtrSalesProps = DepotRawDataStore.DepotRawDataState &
  typeof DepotRawDataStore.actionCreators &
  RouteComponentProps<{}>;

class DeleteDtrSales extends React.PureComponent<DeleteDtrSalesProps> {
  state = {
    shouldRender: false,
    selectedRegion: "",
    selectedDepot: "",
    date: [new Date(), new Date()],
    showOvarlayLoader: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.clearValues();
    this.toggleDeleteDtrSalesBtnState(true);
  }

  onDateChange = (date) => {
    this.setState({
      date: date,
    });
  };

  onChange = (event) => {
    event.preventDefault();
    if (event.target.name === "selectedRegion") {
      var region = event.target.value;
      this.props.setRegion(region);
      this.toggleDeleteDtrSalesBtnState(true);
    } else if (event.target.name === "selectedDepot") {
      var depotId = event.target.value;
      this.props.setDepot(depotId);
      if (
        this.props.selectedDepot &&
        this.props.selectedRegion &&
        this.state.date
      ) {
        this.toggleDeleteDtrSalesBtnState(false);
      }
    }
  };

  deleteDtrSales = () => {
    var selectedDepots = this.props.selectedDepot,
      depotData = {
        fromDate: this.state.date[0],
        toDate: this.state.date[1],
        depotCodes: selectedDepots,
        type: "excel",
      };

    const fromDate = JSON.stringify(depotData.fromDate);
    const toDate = JSON.stringify(depotData.toDate);

    const depotCodes = JSON.stringify(depotData.depotCodes);

    const url = `${endpoints.DISTRIBUTOR_DISTRIBUTOR_SALES}/${depotCodes.replace(/['"]+/g, "")}/${fromDate.replace(/['"]+/g, "")}/${toDate.replace(/['"]+/g, "")}`;

    if (window.confirm(i18next.t("dtr_deletion_confirmation"))) {
      this.setState({ showOvarlayLoader: true });
      fetch(url, {
        method: "delete",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${cookies.get("rawtoken")}`,
        },
      }).then((res) => {
        if (res.status === 200) {
          this.setState({ showOvarlayLoader: false });
          toast.success("Successful deletion");
        } else {
          this.setState({ showOvarlayLoader: false });
          toast.error("Error");
        }
      });
    }
  };

  toggleDeleteDtrSalesBtnState = (state) => {
    var deleteDtrSalesBtn = document.getElementById("deleteDtrSalesBtn");
    if (deleteDtrSalesBtn) {
      (deleteDtrSalesBtn as HTMLInputElement).disabled = state;
    }
  };

  public render() {
    const { depotsList } = this.props;
    var regs = regions
        ? regions.map((region) => {
            return {
              value: region.name,
              label: region.name,
            };
          })
        : [],
      depots = depotsList
        ? depotsList.map((depot) => {
            return {
              value: depot.depotCode,
              label: depot.depotName,
            };
          })
        : [];

    const BoxContent = styled.div`
      padding: 30px;
    `;
    return (
      <>
        <Box
          title={i18next.t("delete_dtr_sales")}
          buttons={
            <>
              <Button
                icon={DeleteIcon}
                id="deleteDtrSalesBtn"
                size="large"
                color="danger"
                onClick={this.deleteDtrSales}
              >
                Delete
              </Button>
            </>
          }
        >
          <BoxContent>
            <Row>
              <Col sm={4}>
                <DateRangePicker
                  label={i18next.t("select_date")}
                  onChange={this.onDateChange}
                  value={this.state.date}
                />
              </Col>

              <Col sm={4}>
                <Select
                  label={i18next.t("region")}
                  name="selectedRegion"
                  value={this.props.selectedRegion}
                  defaultValue={"-1"}
                  onChange={this.onChange}
                  options={regs}
                />
              </Col>

              <Col sm={4}>
                <Select
                  label={i18next.t("selected_depot")}
                  name="selectedDepot"
                  value={this.props.selectedDepot}
                  defaultValue={"-1"}
                  onChange={this.onChange}
                  options={depots}
                />
              </Col>
            </Row>
          </BoxContent>
        </Box>
        {this.state.showOvarlayLoader === true && (
          <div
            className="d-flex align-items-center justify-content-center ovarlay-loader"
            style={{ minHeight: "300px" }}
          >
            <div className="text-center">
              <img src={loader} alt="" />
            </div>
          </div>
        )}
      </>
    );
  }
}
export default connect(
  (state: ApplicationState) => state.depotRawDataReports,
  DepotRawDataStore.actionCreators,
)(DeleteDtrSales);
