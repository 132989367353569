import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as UploadedFilesReportsStore from "../../store/UploadedFilesReportsReducer";
import FilterableTableContainer from "../Helpers/FilterableTableContainer";
import { RouteComponentProps } from "react-router";
import $ from "jquery";
import regions from "../../regions.json";
import Button from "../Button";
import Select from "../Select";
import DateRangePicker from "../DateRangePicker";
import { Row, Col } from "react-grid-system";
import Box from "../Box";
import i18next from "i18next";
import * as actions from "components/Utils/actions";

type UploadedFilesReportsProps =
  UploadedFilesReportsStore.UploadedFilesReportsState &
    typeof UploadedFilesReportsStore.actionCreators &
    RouteComponentProps<{}>;

const dateRender = (props) => {
  var date = props.value;
  return date ? actions.formatDate(date) : date;
};

class UploadedFilesReport extends React.PureComponent<UploadedFilesReportsProps> {
  state = {
    shouldRender: false,
    selectedRegion: "",
    selectedDepotsList: [],
    date: [new Date(), new Date()],
    generateSalesReportForDepots: [],
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.clearValues();
    this.toggleMapBtnState(true);
  }

  componentDidUpdate() {
    if (this.props.selectedDepotsList.length === 0) {
      this.toggleMapBtnState(true);
    } else {
      this.toggleMapBtnState(false);
    }
  }

  downloadReport(reportId) {
    this.props.downloadReceivedSalesFileReport(reportId);
  }

  downloadReportAction = (props) => {
    let report = props.record;

    return (
      <div className="align-center">
        <Button size="large" onClick={() => this.downloadReport(report.id)}>
          Download
        </Button>
      </div>
    );
  };

  getGeneratedSalesTableProps = () => {
    return {
      // Data for the table to display; can be anything
      data: this.props.generatedReportsList
        ? this.props.generatedReportsList
        : [],
      // Fields to show in the table, and what object properties in the data they bind to
      fields: [
        {
          name: "fileType",
          displayName: i18next.t("file_type"),
          inputFilterable: true,
          sortable: true,
        },
        {
          name: "depotName",
          displayName: i18next.t("depot"),
          inputFilterable: true,
          sortable: true,
        },
        {
          name: "extractDate",
          displayName: i18next.t("date"),
          inputFilterable: true,
          sortable: true,
          render: dateRender,
        },
        {
          name: "dateCreated",
          displayName: i18next.t("date_received"),
          inputFilterable: true,
          exactFilterable: true,
          sortable: true,
          render: dateRender,
        },

        {
          name: "status",
          displayName: i18next.t("file_status"),
          inputFilterable: true,
          exactFilterable: true,
          sortable: true,
        },
        { name: "totalEntries", displayName: i18next.t("count_rows") },
        { name: "datesCount", displayName: i18next.t("count_dates") },
        { name: "datesList", displayName: i18next.t("list_dates") },
        { name: "fileName", displayName: i18next.t("file_name") },
        { name: "userName", displayName: i18next.t("user") },
        {
          name: "download",
          displayName: i18next.t("download"),
          render: this.downloadReportAction,
        },
      ],
      namespace: "GeneratedSalesReports",
      initialSort: "id",
      bsLength: 12,
      tableName: i18next.t("uploaded_files_reports"),
    };
  };

  onDateChange = (date) => {
    var selectedDepots = $("#selectedDepot").val();
    this.props.setSelectedDepots(selectedDepots);
    this.setState({
      date: date,
    });
  };

  onChange = (event) => {
    event.preventDefault();
    if (event.target.name === "selectedRegion") {
      var region = event.target.value;
      this.props.clearValues();
      this.props.setRegion(region);
    } else if (event.target.name === "selectedDepot") {
      if (this.props.selectedRegion && this.state.date) {
        this.toggleMapBtnState(false);
      }
    }
  };

  previewSalesReports = (event) => {
    var selectedDepots = $("#selectedDepot").val(),
      depotData = {
        fromDate: this.state.date[0],
        toDate: this.state.date[1],
        depotIds: selectedDepots,
      };
    this.props.generateSalesReportForDepots(depotData);
  };

  toggleMapBtnState = (state) => {
    //edit to set editability
    var generateSalesReport = document.getElementById("generateSalesReport"),
      refreshGeneratedSalesReports = document.getElementById(
        "previewSalesReports",
      );
    if (generateSalesReport && refreshGeneratedSalesReports) {
      (generateSalesReport as HTMLInputElement).disabled = state;
      (refreshGeneratedSalesReports as HTMLInputElement).disabled = state;
    }
  };

  resetBtnState() {
    let buttons = document.getElementsByTagName("button");
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].disabled = false;
    }
  }

  public render() {
    const TableHead = styled.div`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > * {
        margin: 0 8px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    `;

    TableHead.Field = styled.div`
      width: ${({ size }) => (size === "large" ? "280px" : "100%")};
    `;

    TableHead.Button = styled.div`
      position: relative;
      padding-top: 20px;
    `;

    const { depotsList, selectedDepotsList, isLoading } = this.props;
    var regs = regions
        ? regions.map((region, id) => {
            return {
              value: region.name,
              label: region.name,
            };
          })
        : [],
      depots = depotsList
        ? depotsList.map((depot, id) => {
            return {
              value: depot.id,
              label: depot.depotName,
            };
          })
        : [];
    return (
      <div>
        <Row>
          <Col md={3}>
            <Box hasContentGutter>
              <div className="box-content">
                <Row>
                  <Col md={12}>
                    <TableHead.Field>
                      <DateRangePicker
                        label={i18next.t("date")}
                        onChange={this.onDateChange}
                        value={this.state.date}
                      />
                    </TableHead.Field>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <TableHead.Field>
                      <Select
                        label={i18next.t("region")}
                        name="selectedRegion"
                        value={this.props.selectedRegion}
                        defaultValue={"-1"}
                        onChange={this.onChange}
                        options={regs}
                      />
                    </TableHead.Field>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <TableHead.Field>
                      <Select
                        className="custom-height-select"
                        multiple
                        label={i18next.t("selected_depot")}
                        id="selectedDepot"
                        name="selectedDepot"
                        defaultValue={selectedDepotsList}
                        onChange={this.onChange}
                        options={depots}
                      />
                    </TableHead.Field>
                  </Col>
                </Row>
              </div>
            </Box>
          </Col>
          <Col md={9}>
            <FilterableTableContainer
              {...{ isLoading }}
              tableProps={this.getGeneratedSalesTableProps()}
              buttons={
                <>
                  <Button
                    id="previewSalesReports"
                    size="large"
                    color="primary"
                    variant="outlined"
                    onClick={this.previewSalesReports}
                  >
                    {i18next.t("preview")}
                  </Button>
                </>
              }
            />
          </Col>
        </Row>
      </div>
    );
  }
}
export default connect(
  (state: ApplicationState) => state.uploadedFilesReports,
  UploadedFilesReportsStore.actionCreators,
)(UploadedFilesReport);
